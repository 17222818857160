import React, { useState } from "react";
import { useHistory } from "react-router";
import { SideMenuStyled } from "./SideMenu.styled";
import { ReactComponent as Dash } from "../../assets/images/Navbaricons/dashboard.svg";
import { ReactComponent as Book } from "../../assets/images/Navbaricons/book.svg";
import { ReactComponent as Cata } from "../../assets/images/Navbaricons/cata.svg";
import { ReactComponent as Mark } from "../../assets/images/Navbaricons/mark.svg";
import { ReactComponent as Rem } from "../../assets/images/Navbaricons/rem.svg";
import { ReactComponent as Task } from "../../assets/images/Navbaricons/task.svg";
import { ReactComponent as Reporticon } from "../../assets/images/Navbaricons/report.svg";
import { ReactComponent as Ordersicon } from "../../assets/images/Navbaricons/order.svg";
import { ReactComponent as Proficon } from "../../assets/images/Navbaricons/prof.svg";
import { ReactComponent as Clinicicon } from "../../assets/images/Navbaricons/clinic.svg";
import { ReactComponent as Blogicon } from "../../assets/images/Navbaricons/blog.svg";
import { ReactComponent as Revicon } from "../../assets/images/Navbaricons/revenue.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightarrow.svg";
import { MdOutlineLogout } from "react-icons/md";
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = () => {
  const history = useHistory();
  const selectedUrl = window.location.pathname;
  const { isRaphaPlus, user } = useSelector((routeH: any) => routeH?.auth);

  const menuLinks1 = [
    // { title: "Home", id: "focusNextHome", icon: <Dash />, link: "/home" },
    { title: "Dashboard", id: "Dashboard", icon: <Dash />, link: "/dashboard" },
    { title: "Bookings", id: "Bookings", icon: <Book />, link: "/Bookings" },
    {
      title: "Doctor Payouts",
      id: "DoctorPayouts",
      icon: <Book />,
      link: "/DoctorPayouts",
    },
    // { title: "Marketing", id: "Marketing", icon: <Mark />, link: "/Marketing" },
    { title: "Catalogue", id: "Catalogue", icon: <Cata />, link: "/catalogue" },
    // { title: "Reminder", id: "Reminder", icon: <Rem />, link: "/Reminder" },
    // { title: "Revenue", id: "Revenue", icon: <Revicon />, link: "/Revenue" },
    {
      title: "Task Management",
      id: "TaskManagement",
      icon: <Task />,
      link: "/TaskManagement",
    },
    { title: "Reports", id: "Reports", icon: <Reporticon />, link: "/Reports" },
    { title: "Orders", id: "Orders", icon: <Ordersicon />, link: "/Orders" },
    {
      title: "My Patients",
      id: "MyPatients",
      icon: <Proficon />,
      link: "/MyPatients",
    },
    {
      title: "My Profile",
      id: "MyProfile",
      icon: <Proficon />,
      link: "/MyProfile",
    },

    {
      title: "My Hyper Site",
      id: "CreateHyperSite",
      icon: <Blogicon />,
      link: "/createHyperSite/",
    },
    {
      title: "My Clinic",
      id: "MyClinic",
      icon: <Clinicicon />,
      link: "/MyClinic",
    },
    { title: "Blog", id: "Blog", icon: <Blogicon />, link: "/Blog" },
    {
      title: "Chat",
      id: "Chat",
      icon: <MdOutlineMarkUnreadChatAlt />,
      link: "/chat",
    },
  ];

  const menuLinks2 = [
    // { title: "Home", id: "focusNextHome", icon: <Dash />, link: "/home" },
    { title: "Dashboard", id: "Dashboard", icon: <Dash />, link: "/dashboard" },
    { title: "Bookings", id: "Bookings", icon: <Book />, link: "/Bookings" },
    // { title: "Marketing", id: "Marketing", icon: <Mark />, link: "/Marketing" },
    // { title: "Catalogue", id: "Catalogue", icon: <Cata />, link: "/catalogue" },
    // { title: "Reminder", id: "Reminder", icon: <Rem />, link: "/Reminder" },
    // { title: "Revenue", id: "Revenue", icon: <Revicon />, link: "/Revenue" },
    // {
    //   title: "Task Management",
    //   id: "TaskManagement",
    //   icon: <Task />,
    //   link: "/TaskManagement",
    // },
    { title: "Reports", id: "Reports", icon: <Reporticon />, link: "/Reports" },
    { title: "Orders", id: "Orders", icon: <Ordersicon />, link: "/Orders" },
    {
      title: "My Patients",
      id: "MyPatients",
      icon: <Proficon />,
      link: "/MyPatients",
    },
    {
      title: "My Profile",
      id: "MyProfile",
      icon: <Proficon />,
      link: "/MyProfile",
    },

    {
      title: "My Hyper Site",
      id: "CreateHyperSite",
      icon: <Blogicon />,
      link: "/createHyperSite/",
    },
    { title: "Blog", id: "Blog", icon: <Blogicon />, link: "/Blog" },
    {
      title: "Chat",
      id: "Chat",
      icon: <MdOutlineMarkUnreadChatAlt />,
      link: "/chat",
    },
  ];
  const menuLinks = isRaphaPlus ? menuLinks2 : menuLinks1;
  const gotoNewPage = (url: string) => {
    if (url) {
      history.push(url);
    }
  };

  const gotoLogoutPage = () => {
    localStorage.clear();
    window.location.href = "/signin";
  };

  const [activeCataloguePage, setActiveCataloguePage] = useState<string>("");

  const handleCatalogueClick = (page: string, event: React.MouseEvent) => {
    event.stopPropagation(); // Stop the click event from bubbling up
    setActiveCataloguePage(page); // Set the active page
    history.push(`/catalogue?show=${page}`);
  };

  return (
    <SideMenuStyled>
      <div className="sidebar-menu">
        {menuLinks.map((item) => (
          <div
            key={item.id}
            className={selectedUrl.startsWith(item.link) ? "active" : ""}
            onClick={() => gotoNewPage(item.link)}
          >
            <h4 style={{ alignItems: "baseline" }}>
              <span className="sidebar-icon">
                {selectedUrl === item.link ? (
                  <RightArrow style={{ width: "23px" }} />
                ) : (
                  item.icon
                )}
              </span>
              <div className="d-flex flex-column align-items-baseline">
                {item.title}
                {selectedUrl === item.link && item.title === "Catalogue" && (
                  <div className="d-flex flex-column align-items-baseline">
                    <p
                      className={
                        activeCataloguePage === "DoctorConsultation"
                          ? "active"
                          : ""
                      }
                      style={{
                        textWrap: "nowrap",
                        marginBottom: "0",
                        fontSize: "12px",
                        color:
                          activeCataloguePage === "DoctorConsultation"
                            ? "inherit"
                            : "#808080", // default color when active
                      }}
                      onClick={(event) =>
                        handleCatalogueClick("DoctorConsultation", event)
                      }
                    >
                      Doctor Consultation
                    </p>
                    <p
                      className={
                        activeCataloguePage === "LabTest" ? "active" : ""
                      }
                      style={{
                        marginBottom: "0",
                        fontSize: "12px",
                        color:
                          activeCataloguePage === "LabTest"
                            ? "inherit"
                            : "#808080", // default color when active
                      }}
                      onClick={(event) =>
                        handleCatalogueClick("LabTest", event)
                      }
                    >
                      LabTest
                    </p>
                    <p
                      className={
                        activeCataloguePage === "Radiology" ? "active" : ""
                      }
                      style={{
                        marginBottom: "0",
                        fontSize: "12px",
                        color:
                          activeCataloguePage === "Radiology"
                            ? "inherit"
                            : "#808080", // default color when active
                      }}
                      onClick={(event) =>
                        handleCatalogueClick("Radiology", event)
                      }
                    >
                      Radiology
                    </p>
                  </div>
                )}
              </div>
            </h4>
          </div>
        ))}
        <div onClick={gotoLogoutPage}>
          <h4>
            {/* <span className="sidebar-icon"></span> */}
            <MdOutlineLogout className="logoutIcon" />
            Logout
          </h4>
        </div>
      </div>
    </SideMenuStyled>
  );
};

export default Sidebar;
