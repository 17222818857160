import { useLocation } from "react-router-dom";
import { CatalogueStyled } from "./Catalogue.styled";
import DoctorConsultation from "./DoctorConsultation";
import Radiology from "./Radiology";
import LabTest from "./LabTest";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const Catalogue = () => {
  const location = useLocation();

  // Extract the "show" param from the URL search params
  const searchParams = new URLSearchParams(location.search);
  const show = searchParams.get("show");

  // Function to render the component based on "show" param
  const renderComponent = () => {
    switch (show) {
      case "DoctorConsultation":
        return <DoctorConsultation />;
      case "Radiology":
        return <Radiology />;
      case "LabTest":
        return <LabTest />;
      default:
        return <DoctorConsultation />; // Default component
    }
  };

  return (
    <>
      <CatalogueStyled>{renderComponent()}</CatalogueStyled>
    </>
  );
};

export default Catalogue;
