import styled from "styled-components";

export const CreateHyperSiteStyled = styled.div`
  .createHyperSite-sec {
    display: flex;
    gap: 20px;
    @media screen and (max-width: 768px) {
      width: 100%;
      border-right: none;
      flex-direction: column;
    }
  }

  .left-side-mesu-sec {
    width: 200px;
    background: #f9f9f9;
    padding: 10px;
    border-right: 1px solid #ddd;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .right-side-mesu-sec {
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }

  .per-row-sec-list {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
    font-weight: 500;
    color: #555;
  }

  .per-row-sec-list:hover {
    background-color: #eaeaea;
    color: #111;
  }

  .per-row-sec-list.active {
    background-color: #000;
    color: #fff;
  }

  .per-row-sec-list p {
    margin: 0;
  }

  .add-child-content-sec {
    margin-top: 20px;
  }

  .add-child-content-sec h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: 50px;
  }

  .add-child-content-sec .content-item {
    margin-bottom: 15px;
  }

  .add-child-content-sec .content-item a {
    font-size: 16px;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s;
  }

  .add-child-content-sec .content-item a:hover {
    color: #0056b3;
  }

  .form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .form-title {
    color: var(--Shade-100, #000);
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px; /* 135% */
    margin-bottom: 24px;
  }
  .upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d9d9d9;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
  }
  .submit-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .main_thm_wrapper {
    .required {
      color: red;
    }
    label {
      color: var(--Neutral-700, #7f8b93);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .heading {
      color: var(--Shade-100, #000);
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      margin-bottom: 24px;
    }
    .theme_card {
      border-radius: 16px;
      border: 1px solid #cfd3d7;
      background: #fff;
      padding: 24px;
      margin-bottom: 25px;
      .card_head {
        color: var(--Shade-100, #000);
        font-family: "Poppins";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 177.778% */
        margin-bottom: 16px;
      }

      .ant-input {
        border-radius: 18px;
        border: 1px solid #cfd3d7;
        background: #fff;
        color: var(--Shade-100, #000);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        height: 50px;
      }
    }

    .logo_card {
      .main_img_wrp {
        width: 546px;
        height: 76px;
        border-radius: 5px;
        border: 1px solid var(--Neutral-100, #eceeef);
        background: var(--Neutral-50, #f8f7fa);
        margin-bottom: 16px;
        position: relative;

        .pre_img_sec {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          p {
            margin-bottom: 0px;
            color: var(--Shade-100, #000);
            text-align: center;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
          }
        }
        .uploaded_logo {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .small_logo_card {
      .main_img_wrp {
        width: 200px;
        height: 200px;
        border-radius: 5px;
        border: 1px solid var(--Neutral-100, #eceeef);
        background: var(--Neutral-50, #f8f7fa);
        margin-bottom: 16px;
        position: relative;

        .pre_img_sec {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          p {
            margin-bottom: 0px;
            color: var(--Shade-100, #000);
            text-align: center;
            font-family: "Poppins";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */
          }
        }
      }
    }
    .card_body {
      display: flex;
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
      .row {
        display: flex;
        width: 100%;
        .right_col {
          display: flex;
          gap: 15px;
          .ant-input {
            max-width: 80%;
            height: 40px;
          }
        }
      }
      .body_title {
        color: #000;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 187.5% */
      }
      .color_display {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
      }

      .edit_wrap {
        span {
          cursor: pointer;
        }
      }
    }
    .btn_sec {
      display: flex;
      justify-content: end;
      align-items: center;

      .add_btn {
        width: 277px;
        height: 56px;
        border-radius: 18px;
        background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
        color: var(--Shade-0, #fff);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: 0.032px;
        text-transform: uppercase;
      }
    }
    .uploaded_icon {
      width: 100%;
      height: 100%;
    }
    .delete_btn_wrap {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 10px;
      top: 10px;
      border-radius: 4px;
      border: 1px solid var(--Neutral-100, #eceeef);
      background: #fff;
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(4.5px);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .favIcon_card {
    .main_img_wrp {
      width: 80px !important;
      height: 80px !important;
      background: #a6a3aa !important;
      .delete_btn_wrap {
        right: -40px !important;
        top: 0px !important;
      }
    }
  }
  .main_home_banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .banner_header {
      color: var(--Shade-100, #000);
      font-family: "Poppins";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px; /* 135% */
      margin-bottom: 0px;
    }
    button {
      color: var(--Shade-0, #fff);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
      border-radius: 18px;
      background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
      height: 52px;
      img {
        margin-right: 10px;
      }
    }
    .pre_view_btn:disabled {
      opacity: 0.5;
    }
  }
  .buttons_sec {
    display: flex;
    justify-content: end;
    gap: 24px;

    .ant-btn {
      display: flex;
      width: 277px;
      height: 45px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 18px;
      background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
      color: var(--Shade-0, #fff);
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      letter-spacing: 0.032px;
      text-transform: uppercase;
    }
    .add_btn {
      width: 192px;
    }
    .add_btn:disabled {
      opacity: 0.5;
    }
  }

  .Main_card_wrapper {
    position: relative;
    .del_sec {
      cursor: pointer;
      position: absolute;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--Neutral-100, #eceeef);
      background: #fff;
      right: 40px;
      top: 80px;
      box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.08);
      backdrop-filter: blur(4.5px);
    }

    .main_delete_sec {
      cursor: pointer;
      position: absolute;
      width: 110px;
      display: flex;
      background: #36b7b7;
      height: 30px;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 8px;
      right: 20px;
      top: 20px;
      box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.3);
      p {
        margin: 0px;
        text-transform: uppercase;
      }
    }
  }

  // bannerImage CSS

  .offers-container {
    .main_home_banner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      .banner_header {
        color: var(--Shade-100, #000);
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px; /* 135% */
        margin-bottom: 0px;
      }
      button {
        color: var(--Shade-0, #fff);
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        letter-spacing: 0.032px;
        text-transform: uppercase;
        border-radius: 18px;
        background: var(--FARFETCH-COLORS-Mine-Shaft, #222);
        height: 52px;
        img {
          margin-right: 10px;
        }
      }
      .pre_view_btn:disabled {
        opacity: 0.5;
      }
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .offer-card {
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 0 0 calc(50% - 20px);
    position: relative;
    background-color: #fff;

    

    .delete-button {
      cursor: pointer;
      position: absolute;
      width: 110px;
      display: flex;
      background: #36b7b7;
      height: 30px;
      justify-content: space-evenly;
      align-items: center;
      border-radius: 8px;
      right: 40px;
      top: 40px;
      box-shadow: 1px 3px 8px 0px rgba(0, 0, 0, 0.3);
      color: #0a0a0a;
    }

    .delete-button:hover {
      background-color: #0097a7; /* Darker teal on hover */
    }
  }

  .banner-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
  }

  .image-upload {
    text-align: center;
    margin-bottom: 12px;
  }

  .placeholder {
    width: 100%;
    height: 150px;
    border: 1px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    flex-direction: column; /* For icon and text */
    border-radius: 8px; /* Rounded edges */
  }

  .uploaded-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .offer-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 16px;
    font-size: 14px;
  }

  .delete-button {
    /* position: absolute; */
    top: 10px;
    right: 10px;
    background-color: #00bcd4; /* Matching the teal color */
    color: #0a0a0a;
    border: none;
    padding: 15px 25px;
    border-radius: 12px;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .delete-button:hover {
    background-color: #0097a7; /* Darker teal on hover */
  }

  .controls {
    display: flex;
    justify-content: end;
    gap: 24px;

    .add-button,
    .submit-button {
      margin-top: 20px;
      background-color: #000;
      color: #fff;
      font-size: 14px;
      border-radius: 20px;
      padding: 12px 20px;
      text-transform: uppercase;
    }

    .add-button:hover {
      background-color: #6e6968;
    }

    .submit-button:hover {
      background-color: #6e6968;
    }
  }

  // Service page styling

  /* General container styling */
  .brands-container {
    font-family: Arial, sans-serif;
    padding: 20px;
  }

  .brands-list-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  .all-brands,
  .selected-brands {
    flex: 1;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
  }

  h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  /* List styles */
  ul {
    list-style-type: none;
    padding: 0;
  }

  .brand-item,
  .selected-brand-item {
    display: flex;
    justify-content: space-between; /* Space between text/image and checkbox */
    align-items: center; /* Vertically align items */
    gap: 10px; /* Optional: reduce spacing between elements */
    color: var(--Shade-100, #000);
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    font-weight: 400;
    line-height: normal;
    padding: 8px 12px;

    .right_side {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        cursor: pointer;
      }
    }
  }

  .no_selected_brands {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    text-align: center;

    .inner {
      p {
        margin-bottom: 4px;
      }
      .top {
        color: var(--Shade-100, #000);
        text-align: center;
        font-family: "Poppins";
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
      }
      .bottom {
        color: var(--Neutral-800, #606e79);
        text-align: center;
        font-family: "Poppins";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }

  // .brand-logo {
  //   width: 60px;
  //   height: 60px;
  //   margin-right: 10px;
  // }

  /* Checkbox */
  input[type="checkbox"] {
    margin-left: 10px;
    cursor: pointer;
  }

  /* Remove button */
  button {
    background-color: transparent;
    border: none;
    color: red;
    font-size: 18px;
    cursor: pointer;
  }

  /* Update button */
  .update-button {
    display: block;
    margin: 20px auto;
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .update-button:hover {
    background-color: #333;
  }

  @media (max-width: 768px) {
    .brands-list-container {
      flex-direction: column; /* Stack the brands containers vertically */
    }

    .all-brands,
    .selected-brands {
      width: 100%;
      margin-bottom: 20px; /* Add space between the containers */
    }

    h3 {
      font-size: 18px; /* Slightly smaller heading font */
    }

    .brand-item,
    .selected-brand-item {
      font-size: 16px; /* Reduce font size for smaller screens */
      padding: 6px 10px; /* Reduce padding */
    }

    .update-button {
      width: 100%; /* Make the update button full-width */
      padding: 12px 0; /* Add more padding for larger click area */
    }

    /* No selected brands message */
    .no_selected_brands {
      margin-top: 50px; /* Reduce top margin */
    }
  }

  /* For screens smaller than 480px (mobile devices) */
  @media (max-width: 480px) {
    .brands-container {
      padding: 15px; /* Reduce padding */
    }

    .brand-item,
    .selected-brand-item {
      font-size: 14px; /* Further reduce font size */
    }

    .update-button {
      padding: 10px 0; /* Adjust padding for small screens */
    }

    .no_selected_brands {
      margin-top: 30px; /* Reduce top margin */
    }

    .inner .top {
      font-size: 14px; /* Adjust font size for small screens */
    }

    .inner .bottom {
      font-size: 15px; /* Adjust font size for small screens */
    }
  }

  // About Us design -->

  .create-article-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .create-article-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .upload-box {
    position: relative;
    width: 100%;
    height: 100%;
    border: 2px dashed #ccc;
    border-radius: 8px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column; /* Aligns items vertically */
    gap: 12px; /* Adds spacing between image and button */

    .upload-placeholder {
      text-align: center;
      font-size: 16px;
      color: #888;
      cursor: pointer;
    }
  }

  #file-input {
    display: none;
  }

  .upload-placeholder span {
    font-size: 28px;
    display: block;
    margin-bottom: 10px;
  }

  .quill-editor {
    height: 200px;
  }

  .create-article-button {
    background-color: #333;
    color: #fff;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    display: block;
    width: 50%;
    margin-top: 70px;
  }

  .create-article-button:hover {
    background-color: #555;
  }

  .image-preview {
    max-width: 100%;
    height: auto;
  }

  .upload-button {
    margin-top: 10px; /* Adds spacing between the image and button */
  }
`;
