import React, { useState, useEffect } from "react";
import { Button } from "antd";
import "./CreateHyperSite.styled";
import { CreateHyperSiteStyled } from "./CreateHyperSite.styled";
import ImageUploadModel from "../../components/ImageUploadModel/ImageUploadModel";
import TestimonialCard from "../../components/TestimonialCards";
import { useDispatch } from "react-redux";
import {
  getAllHyperSites,
  updateHyperSite,
} from "../../redux/slices/hypersite/hypersiteService";
import { toast } from "react-toastify";

interface Testimonial {
  id: number;
  title: string;
  text: string;
  date: string;
  role: string;
  image: string;
  description: string;
  isNew?: boolean; // Flag to identify newly added testimonials
}

const TestimonialsComponent = ({ selectedHypersite }: any) => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const dispatch = useDispatch();

  // Prefill testimonials if data exists
  useEffect(() => {
    if (selectedHypersite?.testimonials) {
      const prefilledTestimonials = selectedHypersite.testimonials.map(
        (testimonial: any, index: number) => ({
          id: index + 1,
          title: testimonial.tile || "",
          text: testimonial.text || "",
          date: testimonial.date || "",
          image: testimonial.imageURL || "",
          role: testimonial.role || "patient",
          description: testimonial.description || "",
          isNew: false,
        })
      );
      setTestimonials(prefilledTestimonials);
    }
  }, [selectedHypersite]);

  const handleAddTestimonial = () => {
    const newTestimonial: Testimonial = {
      id: Date.now(), // Unique ID based on timestamp
      title: "",
      text: "",
      date: "",
      role: "patient",
      image: "",
      description: "",
      isNew: true,
    };
    setTestimonials([...testimonials, newTestimonial]);
  };

  const handleDeleteTestimonial = (id: number) => {
    setTestimonials(
      testimonials.filter((testimonial) => testimonial.id !== id)
    );
  };

  // Function to update a single testimonial
  const handleUpdateTestimonial = (
    index: number,
    updatedTestimonial: Testimonial
  ) => {
    setTestimonials((prevTestimonials) =>
      prevTestimonials.map((testimonial, i) =>
        i === index ? updatedTestimonial : testimonial
      )
    );
  };

  const handleSubmit = async () => {
    const newTestimonials = testimonials.map((testimonial) => ({
      imageURL: testimonial.image,
      tile: testimonial.title,
      role: testimonial.role, // No change to role
      text: testimonial.text,
      date: testimonial.date,
      description: testimonial.description, // Keep the description as HTML string
    }));

    console.log("newTestimonials : ", newTestimonials);

    const body = {
      id: selectedHypersite?.id,
      payload: {
        user_id: selectedHypersite?.user_id,
        site_name: selectedHypersite?.site_name,
        testimonials: newTestimonials,
      },
    };
    console.log("body : ", body);

    const res = (await dispatch(updateHyperSite(body))) as any;
    console.log("res : ", res);

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    } else {
      toast.success("HyperSite Updated Successfully");
      dispatch(getAllHyperSites());
    }
  };

  return (
    <CreateHyperSiteStyled>
      <div className="testimonials-container">
        {selectedHypersite ? (
          <>
            <div className="main_home_banner">
              <p className="banner_header">Testimonials</p>
              <Button
                className="pre_view_btn"
                onClick={() =>
                  window.open(
                    "https://www.raphaplus.in/hypersite/testsite",
                    "_blank"
                  )
                }
              >
                Preview in New Tab
              </Button>
            </div>
            {testimonials.map((testimonial, index) => (
              <div key={testimonial.id} className="testimonial-card">
                <TestimonialCard
                  item={testimonial}
                  updateTestimonial={(updatedTestimonial: Testimonial) =>
                    handleUpdateTestimonial(index, updatedTestimonial)
                  } // Pass index in the update function
                  index={index}
                />
                <button
                  className="delete-button"
                  onClick={() => handleDeleteTestimonial(testimonial.id)}
                >
                  DELETE
                </button>
              </div>
            ))}
            <div className="controls">
              <button className="add-button" onClick={handleAddTestimonial}>
                + ADD NEW
              </button>
              <button className="submit-button" onClick={handleSubmit}>
                SAVE
              </button>
            </div>
          </>
        ) : (
          <p>Please Select a HyperSite First</p>
        )}
      </div>
    </CreateHyperSiteStyled>
  );
};

export default TestimonialsComponent;
