import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Signin from "../pages/Signin";
import MyProfile from "../pages/MyProfile";
import { useLocation } from "react-router-dom";
import PrivacyPolicy from "../pages/policy/PrivacyPolicy/PrivacyPolicy";
import Terms from "../pages/policy/Terms/Terms";
import ReturnRefund from "../pages/policy/ReturnRefund/ReturnRefund";
import ShippingPolicies from "../pages/policy/ShippingPolicies/ShippingPolicies";
import SecurityPolicies from "../pages/policy/SecurityPolicies/SecurityPolicies";
import Disclaimer from "../pages/policy/Disclaimer/Disclaimer";
import CityDialogList from "../pages/CityDialog/CityDialogList";
import AboutUs from "../pages/AboutUs/AboutUs";
import Doctordashboard from "../pages/Doctordashboard";
import ContactUs from "../pages/ContactUs/ContactUs";
import CreateHyperSite from "../pages/CreateHyperSite/CreateHyperSite";
import Bookings from "../pages/Bookings/Bookings";
import Reports from "../pages/Reports/Reports";
import Login from "../pages/Login/Login";
import MyPatients from "../pages/MyPatients/Patients";
import Orders from "../pages/Orders/Order";
import CreateOrder from "../pages/Orders/CreateOrder/CreateOrder";
import OrderAgain from "../pages/Orders/Orderagain/OrderAgain";
import Clinic from "../pages/Clinics/Clinic";
import ProfileDetails from "../pages/Login/ProfileDetails";
import Catalogue from "../pages/Catalogue/Catalogue";
import Marketing from "../pages/Marketing/Marketing";
import Remainder from "../pages/Remainder/Remainder";
import Blog from "../pages/Blog/Blog";
import TaskManagement from "../pages/TaskManagement/TaskManagement";
import VideoCall from "../pages/VideoCall/VideoCall";
import NewOrder from "../pages/Orders/NewOrder/NewOrder";
import ComingSoon from "../components/ComingSoon/ComingSoon";
import ChatComponent from "../pages/Chat/v1/Chat";
import Prescription from "../pages/Prescription/Prescription";
import ChatContextProvider from "../pages/Chat/context/ChatConext";
import Chat from "../pages/Chat/Chat";

import MealCategory from "../components/BMI/MealCategory";
const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/myProfile" component={MyProfile} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/return-refund" component={ReturnRefund} />
      <Route exact path="/shipping-policy" component={ShippingPolicies} />
      <Route exact path="/security-policy" component={SecurityPolicies} />
      <Route exact path="/disclaimer" component={Disclaimer} />
      <Route exact path="/citydialoglist" component={CityDialogList} />
      <Route exact path="/aboutus" component={AboutUs} />
      <Route exact path="/contactus" component={ContactUs} />
      <Route exact path="/catalogue" component={Catalogue} />
      <Route exact path="/" component={Doctordashboard} />
      <Route exact path="/dashboard" component={Doctordashboard} />
      <Route exact path="/bookings" component={Bookings} />
      <Route exact path="/DoctorPayouts" component={Bookings} />

      <Route exact path="/reports" component={Reports} />
      <Route exact path="/register/" component={ProfileDetails} />
      <Route exact path="/register/:component" component={Login} />
      <Route exact path="/MyClinic" component={Clinic} />
      <Route exact path="/mypatients" component={MyPatients} />
      <Route exact path="/orders" component={Orders} />
      <Route exact path="/orders/create-order" component={NewOrder} />
      <Route exact path="/create-order" component={CreateOrder} />
      <Route exact path="/Orderagain" component={OrderAgain} />
      <Route exact path="/Marketing" component={Marketing} />
      <Route exact path="/Remainder" component={Remainder} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/TaskManagement" component={TaskManagement} />
      <Route exact path="/VideoCall" component={VideoCall} />
      <Route exact path="/comingsoon" component={ComingSoon} />
      <Route exact path="/chat" component={ChatComponent} />
      <Route
        exact
        path="/chat2"
        component={() => (
          <ChatContextProvider>
            <Chat />
          </ChatContextProvider>
        )}
      />
      <Route exact path="/CreateHyperSite" component={CreateHyperSite} />
      <Route exact path="/dietReport" component={MealCategory} />
      <Route
        exact
        path="/CreateHyperSite/:sectionName"
        component={CreateHyperSite}
      />

      <Route exact path="/prescription/:id" component={Prescription} />
    </Switch>
  );
};

export default MainRoutes;
