import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as Action } from "../../assets/icons/action1.svg";
import { ReactComponent as Share } from "../../assets/icons/share.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/whats-app.svg";
import { ReactComponent as Gmail } from "../../assets/icons/gmail.svg";
import { BookingStyled } from "./Bookings.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBookingListAPI,
  updateBookingStatusAPI,
} from "../../redux/slices/dashboard/dashboardService";
import Pagination from "react-bootstrap/Pagination";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import PatientDetailModal from "../../components/PatientDetailModal/Patientmodal";
import {
  getPatientDetailsAPI,
  getPrescriptionAPI,
} from "../../redux/slices/bookingScreen/bookingScreenService";
import { toast } from "react-toastify";
import { Select } from "antd";
import { addBookingAPI } from "../../redux/slices/doctor/doctorService";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";
import { addPatientAPI } from "../../redux/slices/myPatients/myPatientsService";
import { stateList } from "../../utils/constants";
import { BsThreeDotsVertical } from "react-icons/bs"; // Import Three Dots icon
import { BsShare, BsDownload, BsFillTelephoneFill } from "react-icons/bs"; // Import Share, Download, and Call icons
import Loader from "../../components/Common/Loader";
import { MdOutlineChat } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { getPrescriptionDataAPI } from "../../redux/slices/prescription/prescriptionService";
import { htmlTemplate } from "../../Scenes/common";
import axios from "axios";

const { Option } = Select;

const Bookings = (props: any) => {
  const dispatch = useDispatch();
  const { bookingsList } = useSelector(
    (ReduxState: any) => ReduxState.dashboard
  );
  const history = useHistory();
  const isDoctorPayouts =
    props?.location?.pathname?.toLowerCase() === "/doctorpayouts"
      ? true
      : false;

  useEffect(() => {
    console.log("isDoctorPayouts : ", isDoctorPayouts);
  }, [isDoctorPayouts]);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const itemsPerPage = 20;
  const [showLoader, setShowLoader] = useState(false);
  const allOrder = bookingsList?.bookings || [];
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [lgShow, setLgShow] = useState(false);
  const [mobileError, setMobileError] = useState(""); // State to track error message
  const [pinCodeError, setPinCodeError] = useState(""); // State for error message
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const handleModalOpen = (patient: any, bkId: any) => {
    const patientObj = {
      ...patient,
      ...patient?.user,
    };
    const selectPatient = {
      ...patientObj,
      bkId: bkId,
    };
    setSelectedPatient(selectPatient);
    setLgShow(true);
  };

  const handleModalClose = () => {
    setLgShow(false);
    setSelectedPatient(null);
  };

  const formatDate = (timestamp: any) => {
    const date = new Date(parseInt(timestamp, 10));
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  };

  useEffect(() => {
    dispatch(
      getAllBookingListAPI({
        count: 100,
        status: isDoctorPayouts
          ? "prescription_sent_successfully,completed,report_delivered"
          : "",
      })
    );
  }, [dispatch, isDoctorPayouts]);

  useEffect(() => {
    console.log("bookingsList : ", bookingsList);
  }, [bookingsList]);

  const filteredOrders = allOrder.filter((item: any) => {
    const fullName = `${item?.user?.first_name} ${item?.user?.last_name}`;
    return (
      fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item?.user?.id?.toString().includes(searchQuery)
    );
  });

  // Sorting logic
  const sortedOrders = filteredOrders.sort((a: any, b: any) => {
    if (!sortConfig.key) return 0;

    const key = sortConfig.key;
    const order = sortConfig.direction === "asc" ? 1 : -1;

    const valueA =
      key === "name" ? `${a.user.first_name} ${a.user.last_name}` : a[key];
    const valueB =
      key === "name" ? `${b.user.first_name} ${b.user.last_name}` : b[key];

    if (valueA < valueB) return -1 * order;
    if (valueA > valueB) return 1 * order;
    return 0;
  });

  // Calculate the current page's data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = sortedOrders.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedOrders.length / itemsPerPage);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSortChange = (key: string) => {
    setSortConfig((prevSort) => ({
      key,
      direction:
        prevSort.key === key && prevSort.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleShare = (platform: string, patient: any) => {
    const url = window.location.href; // Current URL
    const message = `Check out this appointment details for ${patient?.first_name}:\n${url}`;

    switch (platform) {
      case "whatsapp":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`,
          "_blank"
        );
        break;
      case "email":
        window.open(
          `mailto:?subject=Appointment Details&body=${encodeURIComponent(
            message
          )}`,
          "_self"
        );
        break;
      default:
        break;
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("https://website.com/file.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "SamplePDF.pdf";
        alink.click();
      });
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    mobileNo: "",
    source: "Instagram",
    date: "",
    time: "",
    userId: "",
  });

  const [patients, setPatients] = useState<any>();
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [doctorId, setDoctorId] = useState<any>();

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        console.log("user.roles[0].linkable_id : ", user.roles[0].linkable_id);
        setDoctorId(user.roles[0].linkable_id);
        fetchPatients(user.roles[0].linkable_id);
        return;
      }
    }
    toast.error("Doctor Id Not Found");
  }, []);

  function convertTo12HourFormat(time24: any) {
    // Split the time into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Determine AM or PM suffix
    const period = hours >= 12 ? "pm" : "am";

    // Convert hours from 24-hour to 12-hour format
    const hours12 = hours % 12 || 12; // convert 0 hour to 12

    // Format and return the 12-hour time string
    return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`;
  }

  const handleSave = async () => {
    console.log("Booking Details:", formData);
    const res = await dispatch(
      addBookingAPI({
        collection_1_date: formData?.date,
        collection_1_slot: convertTo12HourFormat(formData?.time),
        userid: parseInt(formData?.userId),
        doctor_id: parseInt(doctorId),
        source: formData?.source,
      })
    );
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("resJson : ", resJson);
    if (resJson?.error?.message) {
      toast.error(resJson?.error?.message);
      return;
    }
    if (resJson?.payload?.data) {
      toast.success("Booking Added successfully");
      handleClose();
    }
  };

  const fetchPatients = async (docId: any) => {
    const res = await dispatch(getPatientDetailsAPI(docId));
    const resJson = JSON.parse(JSON.stringify(res));
    setPatients(resJson?.payload?.data);
    console.log("resJson?.payload?.data : ", resJson?.payload?.data);
  };

  const [showPatientModal, setShowPatientModal] = useState(false);

  // Function to open and close the "Add Patient" modal
  const handlePatientModalOpen = () => {
    setShowPatientModal(true);
  };

  const handlePatientModalClose = () => {
    setShowPatientModal(false);
  };

  const [patientDetails, setPatientDetails] = useState({
    first_name: "",
    last_name: "",
    name: "",
    mobile: "",
    email: "",
    // employeeId: "",
    age: "",
    gender: "",
    address: "",
    state: "",
    city: "",
    pinCode: "",
    userId: "",
  });

  const handlePatientInputChange = (e: any) => {
    const { name, value } = e.target;
    setPatientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSavePatient = async () => {
    console.log("Patient Details:", patientDetails);
    const body = {
      patient: {
        first_name: patientDetails.first_name,
        last_name: patientDetails.last_name,
        email: patientDetails.email,
        secondary_email: "",
        phone: patientDetails.mobile,
        secondary_phone: "",
        gender: patientDetails.gender,
        age: parseInt(patientDetails.age),
        address: {
          name: "",
          address: patientDetails.address,
          state: patientDetails.state,
          city: patientDetails.city,
          zip: patientDetails.pinCode,
          latitude: 0,
          longitude: 0,
        },
        employee_id: "",
      },
    };

    const res = await dispatch(addPatientAPI(body));
    const resJson = JSON.parse(JSON.stringify(res));
    console.log("resJson : ", resJson);
    if (resJson?.error) {
      toast.error(resJson?.error?.message || "Some Error Occured");
      return;
    } else {
      toast.success("Patient Added Successfully");
      handlePatientModalClose();
    }
  };

  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [cities, setCities] = useState<any>(configInfo?.cities || []);
  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);

  useEffect(() => {
    if (configInfo?.cities) {
      setCities(configInfo.cities);
    }
  }, [configInfo]);

  useEffect(() => {
    console.log("cities : ", cities);
  }, [cities]);
  const joinVideoCall = (iid: any) => {
    // window.location.href = `/VideoCall?roomID=${item?.id}`;
    window.open(`/VideoCall?roomID=${iid}`, "_blank");
  };

  const handleBkApprove = async (item: any) => {
    const updateObj = {
      id: item?.id,
      bookingObj: {
        status: "booking_scheduled",
      },
    };
    const res = await dispatch(updateBookingStatusAPI(updateObj));
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error) {
      toast.error(resJson?.error?.message);
      return;
    } else {
      toast.success("Status updated successfully");
      dispatch(
        getAllBookingListAPI({
          count: 100,
          status: isDoctorPayouts
            ? "prescription_sent_successfully,completed,report_delivered"
            : "",
        })
      );
    }
  };

  const handleBkReject = async (item: any) => {
    const updateObj = {
      id: item?.id,
      bookingObj: {
        status: "cancelled",
      },
    };
    const res = await dispatch(updateBookingStatusAPI(updateObj));
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error) {
      toast.error(resJson?.error?.message);
      return;
    } else {
      toast.success("Status updated successfully");
      dispatch(
        getAllBookingListAPI({
          count: 100,
          status: isDoctorPayouts
            ? "prescription_sent_successfully,completed,report_delivered"
            : "",
        })
      );
    }
  };

  const handlePrescriptionClick = async (bkId: any) => {
    history.push(`/prescription/${bkId}`);
  };

  return (
    <BookingStyled>
      {showLoader && <Loader />}
      <div className="appoint-order">
        <div className="searcharfilter">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search Patient Name or Serial No"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="filterplace">
            <div className="sort-select">
              <select onChange={(e) => handleSortChange(e.target.value)}>
                <option value="">Sort By</option>
                <option value="name">Name</option>
                <option value="collection_1_date">Date & Time</option>
                <option value="user.id">Serial No</option>
                {/* Add more sort options as needed */}
              </select>
            </div>
            {/* <button className="bk-btn" onClick={handleShow}>
              Add Booking
            </button> */}
          </div>
        </div>

        <div className="ordertable">
          <Table responsive="sm">
            <thead>
              <tr>
                <th onClick={() => handleSortChange("user.id")}>Serial No</th>
                <th onClick={() => handleSortChange("name")}>Name</th>
                <th onClick={() => handleSortChange("user.age")}>Age</th>
                <th onClick={() => handleSortChange("collection_1_date")}>
                  Date & Time
                </th>
                <th>Status</th>
                {!isDoctorPayouts && (
                  <>
                    <th>Visit Purpose</th>
                    <th>Source</th>
                    <th className="action-report">Report</th>
                    <th className="action-report">Actions</th>
                  </>
                )}
                {isDoctorPayouts && (
                  <>
                    <th>Total</th>
                    <th>Commision</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((item: any, index: any) => {
                const isLastThree = index >= currentOrders.length - 3;
                const commissionValue =
                  0.1 * item?.attachments[0]?.total_price || 0;

                return (
                  <tr key={index}>
                    <td>{item?.id}</td>
                    <td
                      onClick={() => handleModalOpen(item, item?.id)}
                      style={{ color: "purple", cursor: "pointer" }}
                    >
                      {item?.user?.first_name} {item?.user?.last_name}
                    </td>
                    <td>{item?.user?.age}</td>
                    <td>{`${formatDate(item.collection_1_date)} ${
                      item?.collection_1_slot
                    }`}</td>
                    <td>{item.status}</td>
                    {!isDoctorPayouts && (
                      <>
                        <td>{item.notes}</td>
                        <td>{item.source}</td>
                        <td
                          className="action-report"
                          onClick={() => {
                            console.log("selected item : ", item);
                          }}
                        >
                          <Report />
                          {item?.type === "virtual_consultation" &&
                            item?.show_virtual_call && (
                              <div className="book-video-call-sec">
                                <button
                                  onClick={() => {
                                    joinVideoCall(item?.id);
                                  }}
                                >
                                  Join Video Call
                                </button>
                              </div>
                            )}
                        </td>
                        <td className="actions">
                          <Action
                            onClick={() => handlePrescriptionClick(item?.id)}
                          />
                          <span className="icon-space"></span>
                          <Dropdown>
                            <Dropdown.Toggle
                              as={BsShare}
                              id="dropdown-custom-components"
                            />
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() =>
                                  handleShare("whatsapp", item?.user)
                                }
                              >
                                <Whatsapp /> WhatsApp
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleShare("email", item?.user)}
                              >
                                <Gmail /> Email
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <span className="icon-space"></span>
                          <a href={`tel:${item?.user?.phone_number}`}>
                            <BsFillTelephoneFill />
                          </a>
                          <span className="icon-space"></span>
                          <a href={`/chat?userId=${item?.user?.id}`}>
                            <MdOutlineChat />
                          </a>
                          <span className="icon-space"></span>
                          <Dropdown>
                            <Dropdown.Toggle
                              as={BsThreeDotsVertical}
                              id="dropdown-custom-components"
                            />
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  handleBkApprove(item);
                                }}
                              >
                                Approve
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  handleBkReject(item);
                                }}
                              >
                                Reject
                              </Dropdown.Item>
                              {(item?.status === "booking_scheduled" ||
                                item?.status === "cancelled") && (
                                <Dropdown.Item onClick={() => {}}>
                                  Reschedule
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </>
                    )}
                    {isDoctorPayouts && (
                      <>
                        <td>
                          {item?.attachments?.length > 0
                            ? item?.attachments[0]?.total_price
                            : "N/A"}
                        </td>
                        <td>{commissionValue}</td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="pagination-container">
            {totalPages > 0 ? (
              <div className="pagination">
                <Pagination className="custom-pagination">
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      handlePageChange(Math.max(1, currentPage - 1))
                    }
                  />
                  {[...Array(totalPages)].map((_, i) => (
                    <Pagination.Item
                      key={i + 1}
                      active={i + 1 === currentPage}
                      onClick={() => handlePageChange(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      handlePageChange(Math.min(totalPages, currentPage + 1))
                    }
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(totalPages)}
                  />
                </Pagination>
              </div>
            ) : (
              <div className="no-data text-center">
                <p>No Bookings available currently</p>
                <img
                  src="https://img.freepik.com/premium-vector/no-data-found-empty-file-folder-concept-design-vector-illustration_620585-1698.jpg?semt=ais_hybrid"
                  alt="No Data Available"
                  className="img-fluid"
                  style={{ width: "150px", height: "150px" }}
                />
              </div>
            )}
          </div>
        </div>

        <PatientDetailModal
          show={lgShow}
          onHide={handleModalClose}
          patient={selectedPatient}
        />

        <Modal show={show} onHide={handleClose} className="bookings-modal">
          <Modal.Header closeButton>
            <Modal.Title>Add New Booking Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Form.Group
                controlId="formName"
                className="mt-3 d-flex flex-column"
              >
                <Form.Label>Patient Name</Form.Label>
                <Select
                  showSearch
                  value={formData.name}
                  placeholder="Select a patient"
                  optionFilterProp="children"
                  onChange={(value) => {
                    const selectedPatient = patients.find(
                      (patient: any) => `${patient?.first_name}` === value
                    );
                    setFormData({
                      ...formData,
                      name: value,
                      userId: selectedPatient?.user_id,
                    });
                  }}
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  dropdownStyle={{ zIndex: 9999 }}
                >
                  {patients?.map((patient: any) => (
                    <Option key={patient?.id} value={`${patient?.first_name}`}>
                      {patient?.first_name}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              <Form.Group controlId="patientMobile">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Please enter Phone number"
                  name="mobile"
                  value={formData.mobileNo}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 10); // Limit to 10 digits
                    setFormData({ ...formData, mobileNo: value });

                    if (value.length < 10) {
                      setMobileError(
                        "Mobile number must be exactly 10 digits."
                      );
                    } else {
                      setMobileError(""); // Clear error if valid
                    }
                  }}
                />
                {mobileError && (
                  <div style={{ color: "red", fontSize: "0.8rem" }}>
                    {mobileError}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="formSource" className="mt-3">
                <Form.Label>Source</Form.Label>
                <Form.Select
                  name="source"
                  value={formData.source}
                  onChange={handleInputChange}
                >
                  <option>Instagram</option>
                  <option>Facebook</option>
                  <option>Youtube</option>
                  <option>Whatsapp</option>
                  <option>Email</option>
                </Form.Select>
              </Form.Group>

              <Form.Group controlId="formDate" className="mt-3">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formTime" className="mt-3">
                <Form.Label>Time</Form.Label>
                <Form.Control
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "space-evenly" }}>
            <Button
              style={{
                border: "1px solid #9747FF",
                color: "#9747FF",
                background: "#fff",
              }}
              onClick={() => {
                handleClose(); // Close current modal
                handlePatientModalOpen(); // Open Add Patient modal
              }}
            >
              Add Patient
            </Button>

            <Button
              style={{
                border: "1px solid black",
                color: "#9747FF",
                background: "#fff",
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button style={{ background: "#9747FF" }} onClick={handleSave}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showPatientModal} onHide={handlePatientModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Patient Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* Patient Name */}
              <Form.Group controlId="patientFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter First Name"
                  name="first_name"
                  value={patientDetails.first_name}
                  onChange={handlePatientInputChange}
                />
              </Form.Group>

              <Form.Group controlId="patientLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter Last Name"
                  name="last_name"
                  value={patientDetails.last_name}
                  onChange={handlePatientInputChange}
                />
              </Form.Group>

              <Form.Group controlId="patientMobile">
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Please enter Phone number"
                  name="mobile"
                  value={patientDetails.mobile}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 10); // Limit to 10 digits
                    setPatientDetails({ ...patientDetails, mobile: value });

                    // Validate if less than 10 digits
                    if (value.length < 10) {
                      setMobileError(
                        "Mobile number must be exactly 10 digits."
                      );
                    } else {
                      setMobileError(""); // Clear error if valid
                    }
                  }}
                />
                {mobileError && (
                  <div style={{ color: "red", fontSize: "0.8rem" }}>
                    {mobileError}
                  </div>
                )}
              </Form.Group>

              {/* Email ID */}
              <Form.Group controlId="patientEmail">
                <Form.Label>Email ID</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Please enter Email ID"
                  name="email"
                  value={patientDetails.email}
                  onChange={handlePatientInputChange}
                />
              </Form.Group>

              {/* Employee ID */}
              {/* <Form.Group controlId="patientEmployeeId">
                <Form.Label>Employee ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter Employee ID"
                  name="employeeId"
                  value={patientDetails.employeeId}
                  onChange={handlePatientInputChange}
                />
              </Form.Group> */}

              {/* Age */}
              <Form.Group controlId="patientAge">
                <Form.Label>Age</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Age"
                  name="age"
                  value={patientDetails.age}
                  onChange={handlePatientInputChange}
                />
              </Form.Group>

              {/* Gender */}
              <Form.Group controlId="patientGender">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={patientDetails.gender}
                  onChange={handlePatientInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Form.Control>
              </Form.Group>

              {/* Address */}
              <Form.Group controlId="patientAddress">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please type & select address"
                  name="address"
                  value={patientDetails.address}
                  onChange={handlePatientInputChange}
                />
              </Form.Group>

              {/* State */}
              <Form.Group controlId="patientState">
                <Form.Label>State</Form.Label>
                <Select
                  showSearch
                  value={patientDetails.state ?? null}
                  style={{ width: "100%" }}
                  placeholder="Select a state"
                  optionFilterProp="children"
                  onChange={(value) => {
                    setPatientDetails((prevDetails) => ({
                      ...prevDetails,
                      state: value,
                    }));
                  }}
                  filterOption={(input, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  dropdownStyle={{ zIndex: 9999 }}
                >
                  {stateList.map((state) => (
                    <Option key={state.value} value={state.value}>
                      {state.label}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              {/* City */}
              <Form.Group controlId="patientCity">
                <Form.Label>City</Form.Label>
                <Select
                  showSearch
                  value={patientDetails.city}
                  style={{ width: "100%" }}
                  placeholder="Select a city"
                  optionFilterProp="children"
                  onChange={(value) => {
                    const selectedCity = cities.find(
                      (city: any) => city.id === value
                    );
                    setPatientDetails({
                      ...patientDetails,
                      city: selectedCity ? selectedCity.id : value,
                    });
                  }}
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  dropdownStyle={{ zIndex: 9999 }}
                >
                  {cities?.map((city: any) => (
                    <Option key={city.id} value={city.id}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Group>

              {/* Pin Code */}
              <Form.Group controlId="patientPinCode">
                <Form.Label>Pin Code</Form.Label>
                <Form.Control
                  type="text" // Use text to control input
                  inputMode="numeric" // Ensure numeric keyboard on mobile
                  pattern="\d*" // Allow only digits
                  maxLength={6} // Limit input to 6 digits
                  placeholder="Enter Pin Code"
                  name="pinCode"
                  value={patientDetails.pinCode}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ""); // Filter out non-numeric input
                    handlePatientInputChange({
                      target: { name: "pinCode", value },
                    }); // Update state
                  }}
                />
                {patientDetails.pinCode.length < 6 && (
                  <div style={{ color: "red", fontSize: "0.8rem" }}>
                    Pin Code must be exactly 6 digits.
                  </div>
                )}
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlePatientModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSavePatient}>
              Save Patient
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </BookingStyled>
  );
};

export default Bookings;
