import React, { useEffect, useState } from "react";
import { Input, Button, Typography, Form, Avatar, Space } from "antd";
import { PlusOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  TaskManagementStyled,
  BoardHeader,
  BoardContent,
  Column,
  TaskCard,
} from "./TaskManagement.styled";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createCommentAPI,
  createTaskAPI,
  createTaskBoardAPI,
  getAllTaskBoardsAPI,
  getAllTasksAPI,
  updateTaskAPI,
} from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import { RxCheck, RxCross2 } from "react-icons/rx";

const { Title, Text } = Typography;
const { TextArea } = Input;

interface Task {
  id: number;
  content: string;
  assignee: string;
  dueDate: string;
  priority: string;
  status: string;
  description: string;
  comments: { author: string; text: string; createdAt: string }[];
}

interface Board {
  id: number;
  name: string;
  columns: {
    todo: Task[];
    inProgress: Task[];
    done: Task[];
  };
}

export default function TaskManagement() {
  const [boards, setBoards] = useState<any>([]);
  const [currentBoard, setCurrentBoard] = useState<number | null>(
    boards.length > 0 ? boards[0]?.id : null
  );
  const [isNewBoardModalOpen, setIsNewBoardModalOpen] = useState(false);
  const [isTaskDetailsModalOpen, setIsTaskDetailsModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState<any>(null);
  const [newTaskContent, setNewTaskContent] = useState<{
    [key: string]: string;
  }>({});
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [doctorId, setDoctorId] = useState<any>();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [draftTasks, setDraftTasks] = useState<Record<string, string[]>>({});

  const getAllTaskBoards = async (docId: any) => {
    if (!docId) {
      toast.error("Doctor Id Not Found");
      return;
    }
    const res = await dispatch(getAllTaskBoardsAPI(docId));
    const resJson = JSON.parse(JSON.stringify(res));
    const taskboards = resJson?.payload?.data?.taskboards;
    taskboards.forEach((taskboard: any) => {
      addBoard(taskboard?.name, true, taskboard?.id);
    });
  };

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setDoctorId(user.roles[0].linkable_id);
        getAllTaskBoards(user.roles[0].linkable_id);
      }
    }
  }, []);

  useEffect(() => {
    const getAllTasksByBoardId = async () => {
      if (!currentBoard) {
        // toast.error("currentBoard not set");
        return;
      }
      const res = await dispatch(getAllTasksAPI(`${currentBoard}`));
      const resJson = JSON.parse(JSON.stringify(res));
      const allTasks = resJson?.payload || [];
      allTasks.forEach((task: any) => {
        prefillTasks(task);
      });
    };
    getAllTasksByBoardId();
  }, [currentBoard]);

  const addBoard = async (name: string, existing: boolean = false, id = 0) => {
    if (!existing) {
      const res = await dispatch(
        createTaskBoardAPI({
          doctor_id: doctorId,
          name,
        })
      );
      const resJson = JSON.parse(JSON.stringify(res));
      id = resJson?.payload?.data?.taskBoard?.id;
      if (resJson.error) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (resJson.payload.data) {
        toast.success("Board Created Successfully");
      }
    }

    // Check if the board with the same id already exists
    setBoards((prevBoards: any) => {
      const boardExists = prevBoards.some((board: any) => board.id === id);

      if (boardExists) {
        return prevBoards;
      }

      // If board doesn't exist, add the new one
      const newBoard = {
        id,
        name,
        columns: { todo: [], inProgress: [], done: [] },
      };

      return [...prevBoards, newBoard]; // Add the new board to the state
    });

    setCurrentBoard(id);
    setIsNewBoardModalOpen(false);
  };

  const getFormattedDate = (daysAhead: number = 0) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + daysAhead); // Increment date by 'daysAhead'
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
    }).format(currentDate);
  };

  const addTask = async (columnKey: keyof Board["columns"]) => {
    if (!currentBoard) return;
    const content = newTaskContent[columnKey];
    if (!content) return;

    const res = await dispatch(
      createTaskAPI({
        board_id: currentBoard,
        title: content,
        status:
          columnKey === "todo"
            ? "To Do"
            : columnKey === "inProgress"
            ? "In Progress"
            : "Done",
      })
    );

    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson.error) {
      toast.error(resJson?.error?.message);
      return;
    }
    if (resJson.payload.data) {
      toast.success("Task Added Successfully");
    }

    const newTask: Task = {
      id: Date.now(),
      content,
      assignee: `${user?.first_name} ${user?.last_name}`,
      dueDate: getFormattedDate(),
      priority: "High",
      status: "On Track",
      description: "",
      comments: [],
    };

    setBoards(
      boards.map((board: any) => {
        if (board.id === currentBoard) {
          return {
            ...board,
            columns: {
              ...board.columns,
              [columnKey]: [...board.columns[columnKey], newTask],
            },
          };
        }
        return board;
      })
    );
    setNewTaskContent({ ...newTaskContent, [columnKey]: "" });
  };

  const updateTask = async () => {
    if (!currentTask) return;

    const updatedTask = {
      id: currentTask.id,
      body: {
        title: currentTask.content,
        description: currentTask.description,
        status: currentTask.status,
        priority: currentTask.priority,
        due_date: currentTask.dueDate,
        comments: currentTask.comments,
        assignee_id: user?.id,
      },
    };

    const res = await dispatch(updateTaskAPI(updatedTask));
    const resJson = JSON.parse(JSON.stringify(res));

    if (resJson.error) {
      toast.error(resJson?.error?.message);
      return;
    }

    if (resJson.payload.data) {
      toast.success("Task Updated Successfully");

      // Update task in local state to reflect changes
      setBoards(
        boards.map((board: any) => ({
          ...board,
          columns: Object.fromEntries(
            Object.entries(board.columns).map(([columnKey, tasks]: any) => [
              columnKey,
              tasks.map((task: any) =>
                task.id === currentTask.id ? currentTask : task
              ),
            ])
          ),
        }))
      );

      setIsTaskDetailsModalOpen(false);
    }
  };

  const addComment = async () => {
    if (!commentText.trim()) {
      toast.error("Comment cannot be empty");
      return;
    }

    const commentObj = {
      task_id: currentTask?.id,
      user_id: user?.id,
      comments: commentText,
      date: new Date().toLocaleDateString(),
      time: new Date().toLocaleTimeString(),
    };

    const res = await dispatch(createCommentAPI(commentObj));
    const resJson = JSON.parse(JSON.stringify(res));

    if (resJson.error) {
      toast.error(resJson?.error?.message);
      return;
    }

    if (resJson.payload.data) {
      toast.success("Comment Added");

      // Add the new comment to the task locally
      setCurrentTask({
        ...currentTask,
        comments: [
          ...currentTask.comments,
          {
            author: `${user.first_name} ${user.last_name}`,
            text: commentText,
            createdAt: `${commentObj.date} ${commentObj.time}`,
          },
        ],
      });

      // Clear the input field
      setCommentText("");
    }
  };

  const prefillTasks = (task: any) => {
    const statusToColumnMap: { [key: string]: keyof Board["columns"] } = {
      "To Do": "todo",
      "In Progress": "inProgress",
      Done: "done",
    };

    const columnKey = statusToColumnMap[task.status] || "todo";

    const newTask: Task = {
      id: task.id,
      content: task.title || task.description,
      assignee: "Unknown", // Can map from assignee_id if needed
      dueDate: task.due_date || "No Due Date",
      priority: task.priority || "Medium",
      status: task.status || "To Do",
      description: task.description || "",
      comments: task.comments || [],
    };

    // Check if the board matches the task's board_id
    setBoards((prevBoards: any) =>
      prevBoards.map((board: any) => {
        if (board.id === task.board_id) {
          // Check if the task already exists in any of the columns
          const taskAlreadyExists = Object.values(board.columns)
            .flat()
            .some((existingTask: any) => existingTask.id === newTask.id);

          if (taskAlreadyExists) {
            return board; // Task already exists, return board without modifications
          }

          // If task does not exist, add it to the corresponding column
          return {
            ...board,
            columns: {
              ...board.columns,
              [columnKey]: [...board.columns[columnKey], newTask], // Append the new task
            },
          };
        }
        return board; // Return boards that don't match task.board_id unchanged
      })
    );
  };

  useEffect(() => {
    console.log("boards : ", boards);
  }, [boards]);

  const openTaskDetails = (task: Task) => {
    setCurrentTask(task);
    setIsTaskDetailsModalOpen(true);
  };

  const updateTaskField = (field: keyof Task, value: string) => {
    if (currentTask) {
      setCurrentTask({ ...currentTask, [field]: value });

      // Update the task in the boards state
      setBoards(
        boards.map((board: any) => ({
          ...board,
          columns: Object.fromEntries(
            Object.entries(board.columns).map(([columnKey, tasks]: any) => [
              columnKey,
              tasks.map((task: any) =>
                task.id === currentTask.id ? { ...task, [field]: value } : task
              ),
            ])
          ),
        }))
      );
    }
  };

  const currentBoardData = boards.find(
    (board: any) => board.id === currentBoard
  );

  const [commentText, setCommentText] = useState("");

  return (
    <>
      <TaskManagementStyled>
        <BoardHeader>
          <div className="d-flex align-items-center gap-3">
            <Title level={4} className="boardTitle m-0 mr-5">
              {currentBoardData ? currentBoardData.name : "Select a Board"}
            </Title>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="d-flex align-items-center justify-content-center"
              onClick={() => setIsNewBoardModalOpen(true)}
            />
          </div>
          {boards.length > 0 && (
            <select
              value={currentBoard || undefined}
              onChange={(e) => setCurrentBoard(Number(e.target.value))}
              style={{ minWidth: "7.2rem" }}
            >
              <option value={undefined}>Select a board</option>
              {boards.map((board: any, index: any) => (
                <option key={index} value={board.id}>
                  {board.name}
                </option>
              ))}
            </select>
          )}
        </BoardHeader>
        {currentBoardData && (
          <BoardContent>
            {(
              Object.keys(currentBoardData.columns) as Array<
                keyof Board["columns"]
              >
            ).map((key, idx) => (
              <Column
                key={idx}
                title={key.charAt(0).toUpperCase() + key.slice(1)}
                extra={
                  // <Button type="link" onClick={() => addTask(key)}>
                  //   + Create
                  // </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setDraftTasks((prev) => ({
                        ...prev,
                        [key]: [...(prev[key] || []), ""],
                      }));
                    }}
                  >
                    + Create
                  </Button>
                }
              >
                {currentBoardData.columns[key].map((task: any, idx: any) => (
                  <TaskCard
                    key={idx}
                    size="small"
                    onClick={() => openTaskDetails(task)}
                  >
                    {task.content}
                  </TaskCard>
                ))}
                {draftTasks[key]?.map((draft, draftIdx) => (
                  <div
                    key={draftIdx}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      marginBottom: "10px",
                    }}
                  >
                    <Input
                      value={draft}
                      placeholder="New Task"
                      onChange={(e) => {
                        const updatedDrafts = [...draftTasks[key]];
                        updatedDrafts[draftIdx] = e.target.value;
                        setDraftTasks({ ...draftTasks, [key]: updatedDrafts });
                        setNewTaskContent({
                          ...newTaskContent,
                          [key]: e.target.value,
                        });
                      }}
                      onPressEnter={() => addTask(key)}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        if (draft.trim()) {
                          addTask(key);
                          setDraftTasks((prev) => ({
                            ...prev,
                            [key]: prev[key].filter(
                              (_, idx) => idx !== draftIdx
                            ),
                          }));
                        }
                      }}
                    >
                      <RxCheck />
                    </Button>
                    <Button
                      type="default"
                      danger
                      onClick={() =>
                        setDraftTasks((prev) => ({
                          ...prev,
                          [key]: prev[key].filter((_, idx) => idx !== draftIdx),
                        }))
                      }
                    >
                      <RxCross2 />
                    </Button>
                  </div>
                ))}

                {/* <Input
                  placeholder="New task"
                  value={newTaskContent[key] || ""}
                  style={{ height: "100px" }}
                  onChange={(e) =>
                    setNewTaskContent({
                      ...newTaskContent,
                      [key]: e.target.value,
                    })
                  }
                  onPressEnter={() => addTask(key)}
                /> */}
              </Column>
            ))}
          </BoardContent>
        )}
        <Modal
          show={isNewBoardModalOpen}
          onHide={() => setIsNewBoardModalOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Create New Board</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form form={form}>
              <Form.Item
                name="boardName"
                rules={[
                  { required: true, message: "Please input the board name!" },
                ]}
              >
                <Input placeholder="Board Name" />
              </Form.Item>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setIsNewBoardModalOpen(false)}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.validateFields().then((values) => {
                  addBoard(values.boardName);
                  form.resetFields();
                });
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isTaskDetailsModalOpen}
          onHide={() => setIsTaskDetailsModalOpen(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {currentTask && (
              <div className="modalBodyWrapper">
                <div
                  className="mb-4 content"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text strong>Assignee</Text>
                  <div>
                    <Avatar src="/placeholder.svg?height=32&width=32" />{" "}
                    {currentTask.assignee}
                  </div>
                </div>
                <div
                  className="mb-4 content"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text strong>Due date</Text>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <CalendarOutlined /> {currentTask.dueDate}
                  </div>
                </div>
                <div
                  className="mb-4 content"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text strong>Fields</Text>
                  <div style={{ width: "50%" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Text>Priority</Text>
                        <select
                          value={currentTask.priority}
                          onChange={(e) =>
                            updateTaskField("priority", e.target.value)
                          }
                        >
                          <option value="High">High</option>
                          <option value="Medium">Medium</option>
                          <option value="Low">Low</option>
                        </select>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <Text>Status</Text>
                        <select
                          value={currentTask.status}
                          onChange={(e) =>
                            updateTaskField("status", e.target.value)
                          }
                        >
                          <option value="On Track">On Track</option>
                          <option value="Off Track">Off Track</option>
                          <option value="At Risk">At Risk</option>
                        </select>
                      </div>
                    </Space>
                  </div>
                </div>
                <div
                  className="mb-4 content"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Text strong>Description</Text>
                  <TextArea
                    rows={4}
                    placeholder="What is this task about?"
                    value={currentTask.description}
                    onChange={(e) =>
                      updateTaskField("description", e.target.value)
                    }
                  />
                </div>
                <div>
                  <Text strong>Comments</Text>
                  {currentTask &&
                    currentTask?.comments &&
                    currentTask?.comments?.map((comment: any, index: any) => (
                      <div key={index} className="mb-2">
                        <Avatar src="/placeholder.svg?height=32&width=32" />{" "}
                        {comment.author} - {comment.text}
                        <div>
                          <small>{comment.createdAt}</small>
                        </div>
                      </div>
                    ))}
                  <div className="mt-3">
                    <TextArea
                      rows={2}
                      placeholder="Add a Comment"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                    />
                    <div className="d-flex justify-content-between">
                      <Button
                        type="primary"
                        className="mt-2"
                        onClick={addComment}
                      >
                        Comment
                      </Button>
                      <Button
                        type="primary"
                        className="mt-2"
                        onClick={updateTask}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </TaskManagementStyled>
    </>
  );
}
