import React, { useContext, useEffect, useRef } from "react";
import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { LuSearch } from "react-icons/lu";
import styled from "styled-components";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCirclePlus } from "react-icons/fa6";
import { RiSendPlaneFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import ChatRoomHeaderTitle from "../../components/chat/ChatRoomHeaderTitle";
import { ChatContext } from "./context/ChatConext";
import { chatTypes, messageDto } from "./type";
import Message from "../../components/chat/Message/Message";
import UserProfileImage from "../../components/chat/UserProfileImage";
import ChatInput from "../../components/chat/ChatInput";
import { SocketContext } from "../../context/SocketProvider";

const Chat = () => {
  const { socket, userChatId }: any = useContext(SocketContext);
  const {
    allDirectChatLists,
    currentChat,
    setCurrentChat,
    handleOpenChat,
    onlineUsers,
    typingStatus,
    fetchMessages,
    messages,
    sendMessage,
    getRelativeTimeDifference,
  } = useContext(ChatContext);
  const scrollRef: any = useRef(null);

  const { user } = useSelector((ReduxState: any) => ReduxState.auth);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
  }, [messages, scrollRef]);

  let prevDate = "";

  return (
    <ChatStyle>
      <div className="chatMainDiv">
        <div className="leftSideBar">
          {/* input header */}
          <ul className="d-flex justify-content-between align-items-center header p-0">
            <li className="inputFame d-flex align-items-center">
              <LuSearch className="mt-1" size={20} />
              <input type="text" name="" placeholder="Search name" id="" />
            </li>
            <li></li>
          </ul>

          {/* /options */}
          <ul className="d-flex align-items-center options">
            <li className={`${"active"}`}>Direct Chat</li>
          </ul>

          {/* list */}
          <div className="userCardListFrame">
            {allDirectChatLists?.map((item: any, i: number) => {
              let image = "";
              let name = "";
              let opponentId = "";
              image = item?.opponent?.[0]?.avatarImage;
              name = item?.opponent?.[0]?.name || item?.opponent?.[0]?.username;
              opponentId = item?.opponent?.[0]?._id;

              const textMessage = item?.latestMessage?.message?.content;
              const isAttachment =
                Array.isArray(item?.latestMessage?.message?.attachments) &&
                item?.latestMessage?.message?.attachments?.length > 0;
              const isEvent =
                item?.latestMessage?.event &&
                Object.keys(item?.latestMessage?.event)?.length > 0;

              return (
                <>
                  <ul
                    className={`userCardList ${
                      currentChat?.chatId == opponentId && "active_user"
                    } `}
                    onClick={() => {
                      handleOpenChat({
                        opponent: {
                          chatType: "INDIVIDUAL_CHAT",
                          chatId: opponentId,
                          id: opponentId,
                          name: name,
                          photo: image,
                          unReadMessageCount: item?.unreadCount,
                          tag: item?.tag,
                        },
                        type: "INDIVIDUAL_CHAT",
                      });
                    }}
                  >
                    <li className="userProfileImg">
                      <UserProfileImage
                        name={name}
                        url={image}
                        className={image ? "profileImg" : "avatar-sidebar"}
                      />
                      {onlineUsers?.includes(opponentId ?? "") && (
                        <div className="online-user-indicatore"></div>
                      )}
                    </li>
                    <li className="middle">
                      <div className="name">{name ?? ""}</div>
                      {typingStatus?.[opponentId] ? (
                        <div className="typing">Typing....</div>
                      ) : (
                        <div className="latestMessage">
                          {userChatId == item?.latestMessage?.sender
                            ? "You: "
                            : `${item?.opponent?.[0]?.name ?? ""}: `}
                          {textMessage ||
                            (isAttachment && "File Shared") ||
                            (isEvent &&
                              item?.latestMessage?.event?.type
                                ?.replace("_", " ")
                                ?.toLowerCase())}
                        </div>
                      )}
                    </li>
                    {Number(item?.unreadCount) ? (
                      <li className="unreadMessageCount">
                        {item?.unreadCount}
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  <p className="time">
                    {getRelativeTimeDifference(item?.latestMessage?.createdAt)}
                  </p>
                  <div className="userCardListBorderBottom"></div>
                </>
              );
            })}
          </div>
        </div>

        <div className="rightSideBar">
          {currentChat ? (
            <>
              {/* /top/ */}
              <div className="headerNav">
                <UserProfileImage
                  name={currentChat?.name}
                  url={currentChat?.photo}
                  className={
                    currentChat?.photo ? "profileImg" : "avatar-sidebar"
                  }
                />
                <ChatStyle>
                  <ChatRoomHeaderTitle />
                </ChatStyle>
              </div>

              {/* middle messages */}
              <div className="messageFrame">
                {/* message */}
                {messages?.map((msg: messageDto) => {
                  let date: any = new Date(msg?.createdAt);
                  date = date.toDateString();
                  const dateDiv =
                    prevDate == date ? (
                      <></>
                    ) : (
                      <div className="dateDividerFrame d-flex justify-content-center align-items-center">
                        <div className="line"></div>
                        <div className="date">
                          {date === new Date()?.toDateString() ? "Today" : date}
                        </div>
                        <div className="line"></div>
                      </div>
                    );
                  prevDate = date;
                  if (msg?.event && Object.keys(msg?.event)?.length != 0) {
                    return (
                      <>
                        {dateDiv}
                        {msg?.event?.type == "CHAT_INITIATED" ? (
                          <div className="eventMessageFrame">
                            {userChatId == msg?.event?.created_by
                              ? `${msg?.senderName} (Me)`
                              : msg?.senderName}{" "}
                            initiated chat.
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    );
                  }
                  return (
                    <>
                      {dateDiv}
                      <div ref={scrollRef} key={msg?._id}>
                        <Message msg={msg} />
                      </div>
                    </>
                  );
                })}
              </div>
              {/* input */}
              <ChatInput />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </ChatStyle>
  );
};

export default Chat;

const ChatStyle = styled.div`
  .chatMainDiv {
    display: flex;
    background: #fff;
    width: 100%;
    height: calc(100vh - 100px);
    padding: 10px 20px;
    // overflow: hidden;
    // margin-top: -16px;
    // padding-top: 0px;
  }
  // display: grid;
  // grid-template-columns: minmax(250px, 300px) 1fr;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
  }
  .leftSideBar {
    padding: 0px 10px;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e7e7e7;

    .header {
      gap: 15px;

      .menu {
        color: #3b4171;
        font-size: 40px;
        cursor: pointer;
      }
      .inputFame {
        gap: clamp(5px, 0.5em, 20px);
        border: 1px solid #c5c5c5;
        padding: 9px 11px;
        border-radius: 25px;
        background: #fff;
        width: 100%;
            max-height: 46px;

        input {
          outline: none;
          border: 0px;

          &:placeholder {
            font-size: 14px;
          }
        }
      }
    }

    .options {
      width: 100%;
      padding: 0px;
      gap: clamp(10px, 0.8em, 20px);
      margin-top: 13px;
      margin-bottom: 13px;

      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

      cursor: pointer;

      .active {
        color: #000000;
        font-weight: 600;
      }
    }

    .active_user {
      //   background: #f8f8ff;
      background: #ececff;
    }

    .userCardListFrame {
      height: 100%;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
    }

    .userProfileImg {
        position: relative;
    }

    .online-user-indicatore {
      width: 10px;
      height: 10px;
      aspect-ratio: 1 / 1;
      border-radius: 100%;
      // background-color: #16a34a;
      background-color: white;
      box-shadow: 0px 1px 5px #575252;
      position: absolute;
      right: 0px;
    bottom: 3px;
  }

    .userCardListBorderBottom {
      width: 100%;
      border: 0.5px solid #e5e5e5;
      height: 0px;
      margin: 10px 0px 10px 0px;
    }

    .time {
      margin: 3px 0px 0px 0px;
      text-align: end;
      color: #343434;
      opacity: 0.8;
      font-size: 10px;
      font-weight: 400;
    }

    .userCardList {
      display: flex;
      gap: 5px;
      //   border: 1px solid red;
      padding: 6px;
      border-radius: 25px;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: #ececff;
      }

      .profileImg {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        aspect-ratio: 1 / 1;
        object-fit: contain;
      }

      .middle {
        flex: 1;
        font-weight: 500;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        .name {
          font-size: 16px;
          color: #001e3a;
        }

        .typing {
          font-size: 14px;
          line-height: 14.52px;
          color: #6c71c9;
        }
        .latestMessage {
          font-size: 14px;
          line-height: 14.52px;
          color: #27B3A4;
          display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
        }
      }

      .unreadMessageCount {
        width: 25px;
        height: 25px;
        flex-shrink: 0;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        color: white;
        background: #252b61;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .rightSideBar {
    width: 100%;
        display: flex
;
    flex-direction: column;
}
  }

  .headerNav {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 0px 10px 20px;
    border-bottom: 1px solid #e7e7e7;

    .profileImg {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      aspect-ratio: 1 / 1;
        object-fit: contain;
  }

    .userName {
      color: #343434;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .indicator {
    p {
      color: #343434;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .eventMessageFrame {
      background: #F8F8FF;
      border-radius: clamp(5px, 1rem, 2rem);
      color: #343434;
      padding: 5px;
      text-align: center;
      margin: 2rem 0;
      }
      
      .dateDividerFrame {
        width: 100%;
        margin: 2rem 0;

        .line {
          width: 100%;
          background: #D3D3D3;
          height: 1px;
        }
        .date {
            width: 100%;
            text-align: center;
        }
  }

  .messageFrame{
  flex: 1;
  overflow-y: auto;
  padding: 10px 20px;
  }

  .bottom_input{
    
  }

  .inputFrame {
    border: 1px solid #e7e7e7;
    background: #F8F8FF;
    border-left: 0;
    width: 100%;
    padding: 20px 10px;
    display: flex;
    align-items: center;

  }

  .inputSendMsg {
  width: 100%;
      max-height: 50px;
      border: 0px;
    outline: none;
    padding: 10px;
    border-radius: 9px;
    border: 1px solid #F0F0F0;
  }

  .attachmentBtn {
    font-size: 25px;
    color: #252B61;
  }

  .sendBtn {
  border: 1px solid #E7E7E7;
  padding: 0px 10px;

  display: flex;
    justify-content: center;
    align-items: center;

  border-left: 0;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
    background: #252B61;
    border: 0px;
    color: white;
    width: 64px;
height: 43px;
font-size:30px;
border-radius: 20px;

  }
  }

  .msgContainer {
  width: 100%;
    display: flex;
        margin-bottom: 2rem;

      .msg {
      // width: 50%;
      min-width: 150px;
      max-width: 50%;
      flex-shrink: 0;
      }
      .textMsg {
        background: #F8F8FF;
        padding: 2rem;
        // border-radius: 5rem;
        border-radius: clamp(5px, 1rem, 2rem);
        color: #343434;
      }

      .msgProfileImg {
        width: 25px !important;
        height: 25px !important;
        font-size: 14px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
            object-fit: contain;
      }

      .sentBy {
        color: #343434;
        font-size: 14px;
        margin-left: 1rem;
        }
        
        .sendByMe {
          margin-left: 1rem;
      }
      .sendByOthers {
        margin-right: 1rem;
        text-align: end;
      }
  }
  .fs-14px {
    font-size: 14px;
  }

  .attachmentsList{
    gap: 1rem;
    margin-top: 1rem;
  }

  .attachmentBox {
    width: auto;
    height: 60px;
    flex-shrink: 0;
    border-radius: 5px;
  }

`;
