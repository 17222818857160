import React, { useContext } from "react";
import FileClamp from "../File/FileClamp";
import { ChatContext } from "../../../pages/Chat/context/ChatConext";
import { messageDto } from "../../../pages/Chat/type";
import UserProfileImage from "../UserProfileImage";

const Message = ({ msg }: { msg: messageDto }) => {
  const { getRelativeTimeDifference } = useContext(ChatContext);

  console.log(msg);

  return (
    <div
      key={msg?._id}
      className={`msgContainer ${
        msg?.fromSelf ? "justify-content-end" : "justify-content-start"
      }`}
    >
      <div className="msg">
        <div
          className={`d-flex align-items-end justify-content-end ${
            msg?.fromSelf ? "" : "justify-content-end flex-row-reverse"
          }`}
        >
          {msg?.message && <div className="textMsg">{msg?.message}</div>}
          <UserProfileImage
            name={msg?.senderName}
            url={msg?.senderImage ?? null}
            className="msgProfileImg"
          />
        </div>
        {msg?.type == "ATTACHMENT" && (
          <FileClamp
            fileCardClassname="attachmentBox"
            frameClassname="attachmentsList"
            files={msg?.attachments ?? []}
          />
        )}
        <div
          className={`sentBy ${msg?.fromSelf ? "sendByMe" : "sendByOthers"} `}
        >
          {msg?.fromSelf ? "ME" : msg?.senderName}
          {"  "}
          {getRelativeTimeDifference(msg?.createdAt)}
        </div>
      </div>
    </div>
  );
};

export default Message;
