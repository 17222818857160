import React, { useEffect, useState } from "react";
import { Form, Input, Button, Image, Upload } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "antd/dist/antd.css";
import { CreateHyperSiteStyled } from "./CreateHyperSite.styled";
import { useDispatch, useSelector } from "react-redux";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";
import { createBlogAPI } from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { FaCamera } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import {
  createHyperSite,
  getAllHyperSites,
  updateHyperSite,
} from "../../redux/slices/hypersite/hypersiteService";

const ClinicDetails = ({ selectedHypersite }: any) => {
  const [siteName, setSiteName] = useState<string>("");
  const [dealsIconTitle, setDealsIconTitle] = useState<string>("");
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [doctorId, setDoctorId] = useState<any>();
  const history = useHistory();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const dispatch = useDispatch();

  const [showUploadImage, setShowUploadImage] = useState(false);
  const [selectedImg, setSelectedImg] = useState("");
  const [loader, setLoader] = useState(false);
  //   const { themeDetails } = useSelector((state: any) => state?.admin);

  const handleLogoUpload = (file: File) => {
    setLogoFile(file);
    return false; // Prevent upload as we're only storing the file locally
  };

  useEffect(() => {
    console.log("selectedHypersite : ", selectedHypersite);
    setSiteName(selectedHypersite?.site_name);
    setImageUrl(selectedHypersite?.logo_image);
    setDealsIconTitle(selectedHypersite?.logo_title);
  }, [selectedHypersite]);

  const handleSubmit = async () => {
    // Validate required fields
    if (!siteName || !dealsIconTitle || !imageUrl) {
      toast.error("Please fill all required fields!");
      return;
    }

    // Prepare the common payload
    const payload = {
      user_id: user?.id,
      site_name: siteName,
      logo_title: dealsIconTitle,
      logo_image: imageUrl,
    };

    // Determine whether to create or update
    const isUpdate = Boolean(selectedHypersite?.id);
    const action = isUpdate ? updateHyperSite : createHyperSite;

    // Prepare the request body for update
    const requestBody = isUpdate
      ? { id: selectedHypersite.id, payload }
      : payload;

    try {
      // Dispatch the appropriate action
      const response = (await dispatch(action(requestBody))) as any;

      if (response?.error) {
        throw new Error(response.error.message || "Unknown error occurred");
      }

      // Show success message
      toast.success(
        `Hypersite ${isUpdate ? "Updated" : "Created"} Successfully`
      );

      dispatch(getAllHyperSites());
    } catch (error: any) {
      // Handle errors
      toast.error(error.message);
    }
  };

  useEffect(() => {
    //   dispatch(
    //     // getThemeDetailsApi({
    //     //   websiteName: siteName,
    //     // })
    //   );
  }, []);

  const siteNameChange = (value: any) => {
    //   dispatch(updateTheme({ ...themeDetails, siteName: value }));
  };

  const handleCloseImage = () => {
    setShowUploadImage(false);
  };

  const handleImageUploadSuccess = async (imgName: any) => {
    handleCloseImage();

    //   const prevImage1 = `https://${S3_BUCKET}.s3.amazonaws.com/${imgName}`;
    // const payload1 = {
    //   base64Image: prevImage1,
    //   mime: "image/png",
    // };
    // const res: any = await dispatch(ImageUploadAPi(payload1));
    //   dispatch(updateTheme({ ...themeDetails, [selectedImg]: prevImage1 }));
  };

  const logoTitleChange = (val: any, type: any) => {
    //   dispatch(updateTheme({ ...themeDetails, [type]: val }));
  };

  const upadteThemeCall = async () => {
    setLoader(true);
    console.log("themeDetails : ", themeDetails);
    setLoader(false);
  };

  const deleteImages = (place: any) => {
    //   dispatch(updateTheme({ ...themeDetails, [place]: "" }));
  };

  const handleImgFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const frontUrl = await uploadImageToS3(file);
        setImageUrl(frontUrl);
        console.log("Front image uploaded:", frontUrl);
      } catch (error) {
        toast.error("Error uploading front image. Please try again.");
      }
    }
  };
  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${user?.id}`,
        ext: ".png",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
      console.log("presignedUrlResp", presignedUrlResp);

      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;

      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": "image/png", // Ensure the correct content type is set
        },
      });
      console.log("Uploaded Image URL:", publicUrl);
      return publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };
  const themeDetails = {} as any;
  return (
    <CreateHyperSiteStyled>
      <div className="main_thm_wrapper">
        <div className="heading">Clinic Details</div>
        <div className="theme_card">
          <div className="card_head">Site Name</div>

          <Input
            placeholder="Enter Site Name"
            onChange={(e: any) => setSiteName(e.target.value)}
            value={siteName}
          />
        </div>
        <div className="theme_card logo_card">
          <div className="card_head">Logo Image</div>
          <div className="main_img_wrp">
            {!themeDetails?.logo && (
              <Upload
                accept="image/png"
                showUploadList={false}
                onChange={handleImgFileChange}
              >
                <Button style={{ height: "100%" }} icon={<UploadOutlined />}>
                  Upload Front Image
                </Button>
              </Upload>
            )}
            {themeDetails?.logo && (
              <div
                className="delete_btn_wrap"
                onClick={() => deleteImages("logo")}
              >
                {/* <img src={deleteIcon} alt="tetst" /> */}
              </div>
            )}
            {themeDetails?.logo && (
              <img
                src={themeDetails?.logo}
                alt="logo"
                className="uploaded_logo"
              />
            )}
          </div>
          {imageUrl && <Image src={imageUrl} />}
          <div className="input_sec">
            <label>
              Logo Image Title<span className="required">*</span>
            </label>
            <Input
              placeholder="Enter Logo Image Title"
              onChange={(e: any) => setDealsIconTitle(e.target.value)}
              value={dealsIconTitle}
            />
          </div>
        </div>

        <div className="btn_sec">
          <Button
            loading={loader}
            className="add_btn"
            onClick={() => handleSubmit()}
          >
            {selectedHypersite?.id ? "Update" : "Add"}
          </Button>
        </div>
      </div>
    </CreateHyperSiteStyled>
  );
};

export default ClinicDetails;
