import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaUser, FaPaperPlane } from "react-icons/fa";
import { ChatComponentStyled } from "./ChatComponent.styled";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientsAPI } from "../../../redux/slices/myPatients/myPatientsService";
import {
  addMessage,
  getMessages,
  getUserChatId,
  updateReadMessagStatus,
} from "../../../redux/slices/chat/chatService";
import { toast } from "react-toastify";
import { CHAT_API } from "../../../config";
import { io } from "socket.io-client"; // Import socket.io-client
import ChatContainer from "./ChatContainer";
import useSendBrowserNotification from "./useSendBrowserNotification";
import { IoMdClose, IoMdSearch } from "react-icons/io";

// Updated User type with chatId
type User = {
  id: string;
  name: string;
  email: string;
  image: string;
  phone: string;
  chatId?: string;
  unReadMessageCount: number;
};

type Message = {
  fromSelf: boolean;
  message: string;
};

const ChatComponent: React.FC = () => {
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [typingStatus, setTypingStatus] = useState<{
    [key: string]: string[];
  }>({});

  const [users, setUsers] = useState<User[]>([]);
  const [currentUserChatId, setCurrentUserChatId] = useState<string | null>(
    null
  );
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [currentUserId, setCurrentUserId] = useState();
  const { patientsList } = useSelector(
    (ReduxState: any) => ReduxState.myPatients
  );
  const dispatch = useDispatch();
  // Parse userId from URL
  const userIdFromUrl = new URLSearchParams(window.location.search).get(
    "userId"
  );
  const [currentUser, setCurrentUser] = useState<any>(undefined);
  const socket = useRef<any>(null); // Use ref to store socket instance

  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  const { sendNotification, toggleTitle } = useSendBrowserNotification();

  const fetchCurrentUserChatId = useCallback(async () => {
    try {
      const res = (await dispatch(getUserChatId({ rcId: user.id }))) as any;
      setCurrentUserChatId(res.payload?.user?._id);
    } catch (error) {
      console.error("Error fetching current user chat ID:", error);
      toast.error("Failed to fetch current user chat ID");
    }
  }, [dispatch, getUserChatId]);

  useEffect(() => {
    fetchCurrentUserChatId();
  }, [currentUserId]);

  const fetchPatients = useCallback(async () => {
    try {
      await dispatch(fetchPatientsAPI());
    } catch (error) {
      console.error("Error fetching patients:", error);
      toast.error("Failed to fetch patients");
    }
  }, [dispatch, fetchPatientsAPI]);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  useEffect(() => {
    if (patientsList?.patients) {
      fetchUsersChatIds();
    }
  }, [patientsList]);

  useEffect(() => {
    if (currentUserChatId) {
      socket.current = io(CHAT_API);
      socket.current.emit("add-user", currentUserChatId);
    }
  }, [currentUserChatId]);

  // get all online users status
  const onOnlineUsersChange = useCallback((data: any) => {
    setOnlineUsers(data);
  }, []);

  // get typing status
  const onTypingStatusChange = useCallback((data: any) => {
    setTypingStatus((prev) => {
      const newTypingStatus: any = { ...prev };
      const isStartedTyping = data?.typingStatus === "STARTED_TYPING";
      const from = data?.isGroupChat ? data?.to : data?.from;
      const usersList = newTypingStatus[from];

      if (isStartedTyping) {
        const uniqueUsers = new Set(
          Array.isArray(usersList)
            ? [...usersList, data.fromUser]
            : [data.fromUser]
        );

        newTypingStatus[from] = Array.from(uniqueUsers);
      } else {
        if (Array.isArray(usersList) && usersList.length <= 1) {
          delete newTypingStatus[from];
        } else {
          newTypingStatus[from] = usersList?.filter(
            (user: string) => user !== data?.fromUser
          );
        }
      }
      return newTypingStatus;
    });
  }, []);

  useEffect(() => {
    if (currentUserChatId) {
      socket.current = io(CHAT_API);
      socket.current.emit("add-user", currentUserChatId);
      socket.current.on("update-online-users", onOnlineUsersChange);
      socket.current.on("typing-status", onTypingStatusChange);

      return () => {
        if (socket.current) {
          socket.current.off("update-online-users", onOnlineUsersChange);
          socket.current.off("typing-status", onTypingStatusChange);
        }
      };
    }
  }, [currentUserChatId, onOnlineUsersChange, onTypingStatusChange]);

  const updateReadMessagStatusApiCall = useCallback(
    async ({ chat }: { chat: any }) => {
      await dispatch(
        updateReadMessagStatus({
          from: currentUserChatId,
          to: chat?.from,
        })
      );

      fetchPatients();
    },
    [currentUserChatId, dispatch, updateReadMessagStatus, fetchPatients]
  );

  const onIndividualChatNotificationHandler = useCallback(
    async (d: any) => {
      const data = JSON.parse(d);
      console.log(data, "notification12");

      // if i am not the sender and the message is not read by me event though I open a chat room
      if (
        data?.from !== currentUserChatId &&
        data?.from === currentUser?.chatId
      ) {
        await updateReadMessagStatusApiCall({
          chat: data,
        });
      } else {
        fetchPatients();
      }
      sendNotification(`${data?.name}`, data?.message);

      // Start toggling the title when a new message is received
      toggleTitle({ title: "New Message Arrived" });
    },
    [
      currentUserChatId,
      currentUser?.chatId,
      sendNotification,
      toggleTitle,
      updateReadMessagStatusApiCall,
      patientsList,
      fetchPatients,
    ]
  );

  useEffect(() => {
    if (socket.current) {
      socket.current.on(
        `msg-notification`,
        onIndividualChatNotificationHandler
      );
    }

    return () => {
      if (socket.current) {
        socket.current.off(
          `msg-notification`,
          onIndividualChatNotificationHandler
        );
      }
    };
  }, [socket, onIndividualChatNotificationHandler]);

  // useEffect(() => {
  //   if (socket.current) {
  //     // Listen for incoming messages from the server
  //     socket.current.on("msg-receive", (msg: any) => {
  //       if (msg.from === selectedUser?.chatId) {
  //         setMessages((prevMessages) => [
  //           ...prevMessages,
  //           { fromSelf: false, message: msg.message },
  //         ]);
  //       }
  //     });
  //   }
  // }, [selectedUser]);

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setCurrentUserId(user.roles[0].linkable_id);
      }
    }
  }, [user]);

  // const getAllMessages = async (fromChatId: string, toChatId: string) => {
  //   try {
  //     const msgObj = { from: fromChatId, to: toChatId };
  //     const res = (await dispatch(getMessages(msgObj))) as any;
  //     if (res?.payload) {
  //       setMessages(res.payload);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching messages:", error);
  //     toast.error("Failed to fetch messages");
  //   }
  // };

  // useEffect(() => {
  //   // Auto-select user when both users list and currentUserChatId are available
  //   if (users.length > 0 && currentUserChatId && userIdFromUrl) {
  //     const matchedUser = users.find((user) => user.id === userIdFromUrl);
  //     if (matchedUser) {
  //       handleUserSelect(matchedUser);
  //     }
  //   }
  // }, [users, currentUserChatId, userIdFromUrl]);

  const fetchUsersChatIds = useCallback(async () => {
    try {
      const updatedUsers = await Promise.all(
        patientsList.patients.map(async (patient: any) => {
          const res = (await dispatch(
            getUserChatId({ rcId: patient.id, userId: currentUserChatId })
          )) as any;
          return {
            ...patient,
            name: `${patient?.first_name || ""} ${patient?.last_name || ""}`,
            id: patient.id.toString(),
            unReadMessageCount: res?.payload?.user?.unReadMessageCount,
            image: patient?.image,
            chatId: res.payload?.user?._id,
          };
        })
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error fetching users chat IDs:", error);
      toast.error("Failed to fetch users chat IDs");
    }
  }, [dispatch, currentUserChatId, patientsList]);

  const handleUserSelect = async (user: any) => {
    setCurrentUser({
      _id: user?.chatId,
      chatId: user?.chatId,
      name: `${user?.first_name || ""} ${user?.last_name || ""}`,
      unReadMessageCount: user?.unReadMessageCount,
      email: user?.email,
      image: user?.image,
    });
    // if (currentUserChatId && user.chatId) {
    //   getAllMessages(currentUserChatId, user.chatId);
    // }

    if (user?.unReadMessageCount > 0) {
      await dispatch(
        updateReadMessagStatus({
          from: currentUserChatId,
          to: user?.chatId,
        })
      );
      fetchUsersChatIds();
    }
  };

  // const handleSendMessage = async (e: React.FormEvent) => {
  //   e.preventDefault();
  //   if (newMessage.trim() && selectedUser && currentUserChatId) {
  //     const msgObj = {
  //       from: currentUserChatId,
  //       to: selectedUser.chatId,
  //       message: newMessage.trim(),
  //     };

  //     try {
  //       const res = (await dispatch(addMessage(msgObj))) as any;
  //       if (res?.error) {
  //         toast.error(res.error.message);
  //         return;
  //       }

  //       // Emit message to server
  //       socket.current.emit("send-msg", {
  //         from: currentUserChatId,
  //         to: selectedUser.chatId,
  //         msg: newMessage.trim(),
  //       });

  //       // Add the new message to the local state
  //       setMessages([
  //         ...messages,
  //         { fromSelf: true, message: newMessage.trim() },
  //       ]);
  //       setNewMessage("");
  //     } catch (error) {
  //       console.error("Error sending message:", error);
  //       toast.error("Failed to send message");
  //     }
  //   }
  // };

  const handleSearch = (e: any) => {
    const value = e?.target?.value || "";
    setSearch(value);
  };

  return (
    <ChatComponentStyled>
      <div className="chat-container">
        <div className="sidebar">
          <div className="d-flex justify-content-between align-items-center p-2">
            {!showSearch && (
              <>
                <h2>Patients</h2>
                <IoMdSearch
                  size={18}
                  className=""
                  onClick={() => {
                    setShowSearch(true);
                  }}
                />
              </>
            )}
            {showSearch && (
              <>
                <input
                  type="text"
                  className="search"
                  placeholder="Search user..."
                  name=""
                  autoFocus
                  value={search}
                  onChange={handleSearch}
                  id=""
                />
                <IoMdClose
                  size={18}
                  onClick={() => {
                    setShowSearch(false);
                    setSearch("");
                  }}
                />
              </>
            )}
          </div>{" "}
          <ul className="memberListContainer">
            {users.map((user) => {
              const regex = new RegExp(search, "i");
              if (regex.test(user.name)) {
                return (
                  <li
                    key={user.id}
                    onClick={() => handleUserSelect(user)}
                    className={
                      currentUser?._id === user.chatId ? "selected" : ""
                    }
                  >
                    <div className="user-icon">
                      {user?.image ? (
                        <img
                          className="avatar-sidebar"
                          src={user?.image}
                          alt=""
                        />
                      ) : (
                        <span className="no-image-sec-char">
                          {user?.name?.charAt(0)}
                        </span>
                      )}
                      {onlineUsers?.includes(user?.chatId ?? "") && (
                        <div className="online-user-indicatore"></div>
                      )}
                    </div>{" "}
                    <div className="space-between flex-grow-1">
                      <div>
                        {user?.name || ""}
                        {typingStatus?.[user?.chatId as string] && (
                          <div className="typing">typing...</div>
                        )}
                      </div>
                      {user?.unReadMessageCount > 0 && (
                        <span className="unread_message_circle">
                          {user?.unReadMessageCount}
                        </span>
                      )}
                    </div>{" "}
                  </li>
                );
              }
              return <></>;
            })}
          </ul>
        </div>
        <div className="chat-area">
          {currentUser === undefined ? (
            <p className="no-chat">Select a patient to start chatting</p>
          ) : (
            <ChatContainer
              currentUserChatId={currentUserChatId}
              currentChat={currentUser}
              socket={socket}
              typingUsers={typingStatus?.[currentUser?.chatId] ?? []}
            />
          )}
        </div>
      </div>
    </ChatComponentStyled>
  );
};

export default ChatComponent;
