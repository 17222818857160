import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  AutoComplete,
  Select,
  Dropdown,
  Menu,
} from "antd";
import { FiMoreVertical } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import {
  doctorByClinicAPI,
  getAllDoctorClinicsAPI,
  unlinkDocFromClinicAPI,
} from "../../redux/slices/doctor/doctorService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const { Search } = Input;
const { Option } = Select;

const DoctorConsultation = () => {
  const specializationOptions = [
    { label: "General Physician", value: "General Physician" },
    { label: "Pediatrics", value: "Pediatrics" },
    { label: "Opthalmology", value: "Opthalmology" },
    { label: "Urology", value: "Urology" },
    { label: "Neurology", value: "Neurology" },
    { label: "Cardiology", value: "Cardiology" },
    { label: "Dental Surgery", value: "Dental Surgery" },
    { label: "General Surgery", value: "General Surgery" },
    { label: "Psychiatry", value: "Psychiatry" },
    { label: "Orthopedics", value: "Orthopedics" },
    { label: "Anesthesiology", value: "Anesthesiology" },
  ];

  const history = useHistory();
  const [clinicOptions, setClinicOptions] = useState([]);
  const [clinicData, setClinicData] = useState([]); // To store full clinic details
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]); // For checkbox selection
  const [filteredSpecializationOptions, setFilteredSpecializationOptions] =
    useState(specializationOptions);
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [docId, setDocId] = useState();
  const [selectedClinic, setSelectedClinic] = useState<any>();
  const [data, setData] = useState([]); // Initialize with empty data

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setDocId(user.roles[0].linkable_id);
      }
    }
  }, [user]);

  const handleSearch = (value: any) => {
    setFilteredSpecializationOptions(
      specializationOptions.filter((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleMenuClick = async (e: any, record: any) => {
    if (e.key === "edit") {
      console.log("Edit clicked for doctor id:", record.id);
      // Handle edit action
    } else if (e.key === "delete") {
      console.log("Delete clicked for doctor id:", record.id);
      const res = (await dispatch(unlinkDocFromClinicAPI(record?.id))) as any;
      if (res?.error) {
        toast.error(res?.error?.message);
      } else {
        toast.success("Doctor unlinked from clinic successfully");
      }
      // Handle delete action
    }
  };

  const menu = (record: any) => (
    <Menu onClick={(e) => handleMenuClick(e, record)}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <div className="doctor-name-cell">
          <img
            src={record.image || "/path/to/your/default-image.png"}
            alt="Doctor Avatar"
            className="doctor-avatar"
          />
          <div>
            <div className="doctor-name">{record.name}</div>
            <div className="doctor-id">{record.id}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Consult Fee",
      dataIndex: "consultFee",
      key: "consultFee",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        // @ts-ignore comment
        <Dropdown overlay={menu(record)} trigger={["click"]}>
          <Button icon={<FiMoreVertical />} />
        </Dropdown>
      ),
    },
  ];

  const doctorsByClinic = async (clinicId: any) => {
    const res = await dispatch(
      doctorByClinicAPI({
        searchText: "",
        count: 30,
        page: 0,
        clinicId: clinicId,
      })
    );
    const resjson = JSON.parse(JSON.stringify(res));

    // Transforming the doctors data for the table
    if (resjson?.payload?.data?.doctors) {
      const doctorsData = resjson.payload.data.doctors.map((doctor: any) => ({
        key: doctor.id,
        name: doctor.name,
        id: doctor.id,
        specialization: doctor.specialization,
        consultFee: doctor.chat_consultation_cost
          ? `₹${doctor.chat_consultation_cost}`
          : "N/A",
        image: doctor.image || null, // Use image if available
      }));

      setData(doctorsData); // Update the table data
    }
  };

  const getClinics = async () => {
    if (!docId) {
      return;
    }
    const res = await dispatch(getAllDoctorClinicsAPI(docId));
    const resJson = JSON.parse(JSON.stringify(res));

    if (resJson.payload) {
      const filteredClinics = resJson.payload.doctorClinics.filter(
        (clinic: any) => clinic.status !== "deleted"
      );
      const sortedClinics = filteredClinics.sort(
        (a: any, b: any) => a.id - b.id
      );
      setClinicData(sortedClinics); // Store full clinic details

      const clinicOptions = sortedClinics.map((clinic: any) => ({
        value: clinic.id,
        label: clinic.name,
      }));
      setClinicOptions(clinicOptions); // Set clinicOptions with id and name

      // Set the first clinic as the default selected clinic
      if (sortedClinics.length > 0) {
        const defaultClinic = sortedClinics[0];
        setSelectedClinic(defaultClinic);
        doctorsByClinic(defaultClinic.id); // Load doctors for the first clinic
      }
    }
  };

  useEffect(() => {
    getClinics();
  }, [dispatch, docId]);

  useEffect(() => {
    if (selectedClinic?.id) {
      doctorsByClinic(selectedClinic?.id); // Fetch doctors whenever the clinic changes
    }
  }, [selectedClinic]);

  return (
    <div>
      <div className="topBanner">
        <h1>Doctors</h1>
        <button onClick={() => history.push(`/register?catalogue=true`)}>
          Add Doctor
        </button>
      </div>
      <div className="catalogue-container">
        <div className="catalogue-header">
          <Search
            placeholder="Search by Name"
            className="search-bar"
            onSearch={handleSearch}
          />
          <AutoComplete
            options={filteredSpecializationOptions}
            placeholder="Specialization"
            className="specialization-dropdown"
            filterOption={(inputValue: any, option: any) =>
              option.value.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
          <Select
            placeholder="Sort By"
            className="sort-dropdown"
            style={{ width: 150 }}
          >
            <Option value="name">Name</Option>
            <Option value="specialization">Specialization</Option>
            <Option value="fee">Consult Fee</Option>
          </Select>
          <Select
            showSearch
            placeholder="Select Clinic"
            className="clinic-dropdown"
            style={{ width: 200 }}
            options={clinicOptions} // Use populated clinicOptions
            value={selectedClinic?.id} // Set value to selectedClinic's id
            onSelect={(value: any) => {
              const selectClinic = clinicData.find(
                (clinic: any) => clinic.id === value
              );
              setSelectedClinic(selectClinic);
            }}
            filterOption={(input: any, option: any) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />

          <span className="clear-all">Clear All</span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default DoctorConsultation;
