import React, { useRef, useEffect } from "react";
import { StyledCompoent } from "./index.styled";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { Input } from "antd";
import CopyButton from "../../components/CopyButton/CopyButton";
import { FaCopy } from "react-icons/fa";
import { startRecordingAPI } from "./../../redux/slices/dashboard/dashboardService";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

function getUrlParams(url = window.location.href) {
  let urlStr = url.split("?")[1];
  return new URLSearchParams(urlStr);
}
function randomID(len: any) {
  let result = "";
  if (result) return result;
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

const VideoCall: React.FC = () => {
  const roomID = getUrlParams().get("roomID") || randomID(5);
  const history = useHistory();
  const dispatch = useDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const startRecordCall = async () => {
    dispatch(startRecordingAPI({ roomID }));
  };

  useEffect(() => {
    const initializeCall = async () => {
      if (!containerRef.current) return;

      const appID = 1432998644;
      const serverSecret = "5946ad8ca535e7848f6cc939d450a702";
      const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
        appID,
        serverSecret,
        roomID,
        randomID(5),
        randomID(5)
      );

      const zp = ZegoUIKitPrebuilt.create(kitToken);
      zp.joinRoom({
        container: containerRef.current,
        sharedLinks: [
          {
            name: "Personal link",
            url: `${window.location.protocol}//${window.location.host}${window.location.pathname}?roomID=${roomID}`,
          },
        ],
        scenario: {
          mode: ZegoUIKitPrebuilt.GroupCall,
        },
        onInRoomTextMessageReceived: (values: any) => {
          console.log("Message received:", values);
        },
        onJoinRoom: () => {
          console.log("Joined room");
          startRecordCall();
        },
        onLeaveRoom: () => {
          console.log("Left room");
        },
      });
    };

    initializeCall();
  }, [roomID]);

  const winUrl = window.location.href;

  return (
    <StyledCompoent>
      <div className="coming-soon-container">
        <div className="d-flex flex-column gap-3">
          <div className="info-text-sec">
            <p>
              If you want to join your family members, Please send below link to
              join
            </p>
          </div>
          <Input
            style={{ width: "100%", maxWidth: "500px" }}
            value={winUrl}
            readOnly
            addonAfter={
              <CopyButton text={winUrl}>
                <FaCopy
                  style={{ verticalAlign: "-.125em", cursor: "pointer" }}
                />
              </CopyButton>
            }
          />
        </div>
        <div
          className="myCallContainer"
          ref={containerRef}
          style={{ width: "100vw", height: "100vh", paddingBottom: "100px" }}
        ></div>
      </div>
    </StyledCompoent>
  );
};

export default VideoCall;
