import React, { useContext, useEffect, useRef, useState } from "react";
import { FaCirclePlus } from "react-icons/fa6";
import { RiSendPlaneFill } from "react-icons/ri";
import { chatTypes } from "../../pages/Chat/type";
import FileInput from "./File/FileInput";
import { ChatContext } from "../../pages/Chat/context/ChatConext";

const ChatInput = () => {
  const inputRef = useRef(null);
  const [msg, setMsg] = useState("");
  const [isShiftKeyPressing, setIsShiftKeyPressing] = useState(false);
  const { sendMessage, typingStatusChangeHandler, currentChat } =
    useContext(ChatContext);

  useEffect(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current?.focus();
    }
    typingStatusChangeHandler(false);
  }, [inputRef, typingStatusChangeHandler]);

  const handleSendMessage = () => {
    if (msg) {
      if (msg && msg?.trim()) {
        sendMessage({
          msg: msg,
          type: "TEXT",
          chatType: currentChat?.chatType as chatTypes,
        });
      }

      setMsg("");
    }
  };

  return (
    <div className="d-flex bottom_input">
      <div className="inputFrame">
        <FileInput />
        <textarea
          onKeyDown={(e) => {
            if (!isShiftKeyPressing && e?.key === "Enter") {
              handleSendMessage();
            }
            if (e?.key == "Shift") {
              setIsShiftKeyPressing(true);
            }
          }}
          onKeyUp={(e) => {
            if (e?.key == "Shift") {
              setIsShiftKeyPressing(false);
            }
          }}
          onChange={(e) => {
            const { value } = e?.target;
            if (value.trim()) {
              typingStatusChangeHandler(true);
            } else {
              typingStatusChangeHandler(false);
            }
            setMsg(value);
          }}
          autoFocus={true}
          value={msg}
          ref={inputRef}
          className="inputSendMsg"
          placeholder="Start typing here..."
          name=""
          id=""
        />
      </div>
      <div className="sendBtn">
        <button onClick={handleSendMessage}>
          <RiSendPlaneFill />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
