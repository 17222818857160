import React, { useState, useEffect } from "react";
import { Input, Select, DatePicker, Button, Upload, Image } from "antd";
import type { DatePickerProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment, { Moment } from "moment";
import { TestimonialCardStyled } from "./TestimonialCards.styled";
import { toast } from "react-toastify";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const { Option } = Select;

const TestimonialCard = ({ item, updateTestimonial, index }: any) => {
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const dispatch = useDispatch();

  const [localTestimonial, setLocalTestimonial] = useState({ ...item });
  const [imageUrl, setImageUrl] = useState(localTestimonial.image || "");

  useEffect(() => {
    setLocalTestimonial({ ...item });
    setImageUrl(item.image || ""); // Sync image state when props change
  }, [item]);

  const handleFieldChange = (field: string, value: string | null) => {
    const updatedTestimonial = { ...localTestimonial, [field]: value };
    setLocalTestimonial(updatedTestimonial);
    updateTestimonial(updatedTestimonial); // Notify parent with changes
  };

  const handleDateChange: DatePickerProps["onChange"] = (
    date: Moment | null,
    dateString: string
  ) => {
    handleFieldChange("date", dateString);
  };

  const handleImgFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const uploadedUrl = await uploadImageToS3(file); // Upload to S3 and get the public URL
        setImageUrl(uploadedUrl); // Update local state
        handleFieldChange("image", uploadedUrl); // Update parent state
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Failed to upload image. Please try again.");
      }
    }
  };

  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${user?.id}`,
        ext: ".png",
      };
      const presignedRes = (await dispatch(
        getS3PresignedUrl(presignBody)
      )) as any;
      const presignedUrlResp = presignedRes?.payload?.signedUrL;

      if (!presignedUrlResp) {
        throw new Error("Failed to get presigned URL");
      }

      const { signedUrL: uploadUrl, publicUrl } = presignedUrlResp;

      await axios.put(uploadUrl, image, {
        headers: {
          "Content-Type": "image/png", // Set correct content type
        },
      });

      return publicUrl; // Return the public URL of the uploaded image
    } catch (error) {
      console.error("Error uploading to S3:", error);
      throw error;
    }
  };

  return (
    <TestimonialCardStyled>
      <div className={`main_offer_wrapper`}>
        <p className="name_sec">Testimonial {index + 1}</p>

        <div className="title_sec">
          <label>Title</label>
          <Input
            placeholder="Enter Testimonial Title"
            value={localTestimonial.title}
            onChange={(e) => handleFieldChange("title", e.target.value)}
          />
        </div>

        <div className="entities_dropdown">
          <label>
            Role<span className="required"></span>
          </label>
          {/* <Select
            value={localTestimonial.role}
            onChange={(value) => handleFieldChange("role", value)}
            style={{ width: "100%" }}
          >
            <Option value="doctor">Doctor</Option>
            <Option value="patient">Patient</Option>
          </Select> */}
          <Input
            value={localTestimonial.role}
            onChange={(e) => handleFieldChange("role", e.target.value)}
            placeholder="Enter role"
          />
        </div>

        <div className="link_sec">
          <label>
            Text<span className="required"></span>
          </label>
          <Input
            placeholder="Enter Testimonial Text"
            value={localTestimonial.text}
            onChange={(e) => handleFieldChange("text", e.target.value)}
          />
        </div>

        <div className="date_sec">
          <label>Date</label>
          <DatePicker
            placeholder="Select Date"
            onChange={handleDateChange}
            value={localTestimonial.date ? moment(localTestimonial.date) : null}
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <ReactQuill
            value={localTestimonial.description}
            onChange={(value) => handleFieldChange("description", value)}
          />
        </div>

        <div>
          <label>Image</label>
          {!imageUrl ? (
            <Upload
              accept="image/png"
              showUploadList={false}
              onChange={handleImgFileChange}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          ) : (
            <div className="img_wrapper">
              <Image src={imageUrl} alt={`Testimonial ${index + 1}`} />
              <Upload
                accept="image/png"
                showUploadList={false}
                onChange={handleImgFileChange}
              >
                <Button icon={<UploadOutlined />}>Change Image</Button>
              </Upload>
            </div>
          )}
        </div>
      </div>
    </TestimonialCardStyled>
  );
};

export default TestimonialCard;
