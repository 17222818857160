import { BannerCardStyled } from "./BannerCards.styled";
import { Button, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";

const BannerCard = ({ image, updateBanner, index, from }: any) => {
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(image || ""); // Initialize with existing image

  const handleImgFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const uploadedUrl = await uploadImageToS3(file); // Upload to S3 and get the public URL
        setImageUrl(uploadedUrl); // Update local state
        updateBanner(index, uploadedUrl); // Update the parent state
        // toast.success("Image uploaded successfully!");
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Failed to upload image. Please try again.");
      }
    }
  };

  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${user?.id}`,
        ext: ".png",
      };
      const presignedRes = (await dispatch(
        getS3PresignedUrl(presignBody)
      )) as any;
      const presignedUrlResp = presignedRes?.payload?.signedUrL;

      if (!presignedUrlResp) {
        throw new Error("Failed to get presigned URL");
      }

      const { signedUrL: uploadUrl, publicUrl } = presignedUrlResp;

      // Upload the image to the S3 URL
      await axios.put(uploadUrl, image, {
        headers: {
          "Content-Type": "image/png", // Set correct content type
        },
      });

      return publicUrl; // Return the public URL of the uploaded image
    } catch (error) {
      console.error("Error uploading to S3:", error);
      throw error;
    }
  };

  return (
    <BannerCardStyled>
      <div className="main_offer_wrapper">
        <p className="name_sec">
          {from} {index + 1}
        </p>

        {!imageUrl ? (
          <div className="upload_sec">
            <div className="pre_upload_sec">
              <Upload
                accept="image/png"
                showUploadList={false}
                onChange={handleImgFileChange}
              >
                <Button style={{ height: "100%" }} icon={<UploadOutlined />}>
                  Upload {from} Image
                </Button>
              </Upload>
            </div>
          </div>
        ) : (
          <div className="img_wrapper">
            <Image
              src={imageUrl}
              alt={`Banner ${index + 1}`}
              className="banner-image"
            />
            <div className="upload-button">
              <Upload
                accept="image/png"
                showUploadList={false}
                onChange={handleImgFileChange}
              >
                <Button icon={<UploadOutlined />}>Change {from} Image</Button>
              </Upload>
            </div>
          </div>
        )}
      </div>
    </BannerCardStyled>
  );
};

export default BannerCard;
