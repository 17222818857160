import React, { Component, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import { UniversalStyled } from "./UniveralSearch.styled";
import { Row, Col, Button, CardDeck, CardColumns } from "react-bootstrap";
import "./styles.css";
import { updateUserDetails } from "../../redux/slices/auth/authSlice";
import { getConfigInfoAPI } from "./../../redux/slices/config/configService";
import LoginModel from "./../../components/LoginModel/LoginModel";
import { updateShowLoginModel } from "../../redux/slices/auth/authSlice";
import { updateNitifySuccessMessage } from "../../redux/slices/checkout/checkoutSlice";
import cart_icon from "../../assets/icons8-cart-32.png";
import search_icon from "../../assets/icons8-search-30.png";
import logo_design from "../../assets/logo_design.png";
import { getUniversalSearchData } from "../../redux/slices/generic/genericService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import arrow from "../../assets/images/Icons/Down.png";
import { checkIsMobile } from "../../Scenes/common";
import CityDialogList from "../../pages/CityDialog/CityDialogList";
import {
  getUserWithDependentsAPI,
  getMyAddressQueryAPI,
} from "../../redux/slices/Profile/ProfileService";
import "./styles.css";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";

import { updateUniversalSearchResults } from "../../redux/slices/generic/genericSlice";

const finalList = [
  { name: "Doctor Consultation", to: "/doctor" },
  { name: "Lab Test", to: "/labtest" },
  { name: "Radiology", to: "/radiology" },
  { name: "Pharmacy", to: "/pharmacy" },
  { name: "Ayurveda", to: "/ayurveda" },
  { name: "Fitness", to: "/Gymlist" },
  { name: "Eye Care", to: "/eyecare" },
  { name: "Dental Care", to: "/dentalcare" },
  { name: "Ambulance", to: "/ambulance" },

  { name: "Blood Bank", to: "/bloodbank" },
];

const Header = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const ref = useRef();

  const { error, loading, subDomainDetails, user, userCity, showLoginModel } =
    useSelector(({ auth }) => auth);
  const { cartItems, nitifySuccessMessage } = useSelector(
    (startR) => startR?.checkout
  );
  const { configInfo } = useSelector(({ config }) => config);
  const { universalSearchResults } = useSelector(({ generic }) => generic);
  const [isOpen, setIsOpen] = useState(false);

  const [showSearchPopupModel, setShowSearchPopupModel] = useState(false);
  const [showLoginPopupModel, setShowLoginPopupModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [show, setShow] = useState(false);
  const [headerList, setHeaderList] = useState(
    checkIsMobile() ? finalList?.slice(0, 40) : finalList?.slice(0, 2)
  );
  const [headerSelectList, setHeaderSelectList] = useState(
    finalList?.slice(2, 20)
  );
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [shows, setShows] = useState(false);

  const handleCloses = () => setShows(false);
  const handleShows = () => setShows(true);

  const type = "md";

  console.log("configInfo", configInfo);
  console.log("useruseruser", user);
  console.log("cartItems", cartItems);
  useEffect(() => {
    // dispatch(getConfigInfoAPI());
    // dispatch(getUserWithDependentsAPI());
    // dispatch(getMyAddressQueryAPI());
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showSearchPopupModel &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowSearchPopupModel(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSearchPopupModel]);

  useEffect(() => {
    if (subDomainDetails?.id) {
      updateMenuLinks();
    }
  }, [subDomainDetails?.id]);

  console.log("showLoginModel", showLoginModel);
  useEffect(() => {
    if (showLoginModel) {
      dispatch(updateShowLoginModel(false));
      handleLogin();
    }
  }, [showLoginModel]);
  useEffect(() => {
    if (nitifySuccessMessage) {
      dispatch(updateNitifySuccessMessage(null));
      showNitiMessage(nitifySuccessMessage);
    }
  }, [nitifySuccessMessage]);
  const updateMenuLinks = () => {
    const sLinkns = subDomainDetails?.agreed_services?.services;
    const mainLinks = checkIsMobile()
      ? sLinkns?.slice(1, 40)
      : sLinkns?.slice(1, 2);
    const subLinks = sLinkns?.slice(2, 20);
    if (mainLinks) {
      setHeaderList(mainLinks);
    }
    if (subLinks) {
      setHeaderSelectList(subLinks);
    }
  };
  const showNitiMessage = (msg) => {
    toast(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // transition: Bounce,
    });
  };
  const gotoCartPage = (url) => {
    setIsOpen(false);
    history.push(url);
  };
  const getShortName = () => {
    var shortname = "";
    try {
      var firstName = user?.data?.firstName
        ? user?.data?.firstName.charAt(0).toUpperCase()
        : "";
      var lastName = user?.data?.lastName
        ? user?.data?.lastName.charAt(0).toUpperCase()
        : "";
      shortname = firstName + lastName;
    } catch (error) {}
    return shortname;
  };
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };
  const handleLogout = () => {
    localStorage.clear();
    // history.push("/");
    window.location.href = "/";
  };
  const navigateToPage = (pageu) => {
    if (pageu) {
      navigateTo(pageu);
    }
  };
  const handleSearchBox = (e) => {
    console.log("handleSearchBox", e.target.value);
    dispatch(getUniversalSearchData({ searchText: e.target.value }));
  };
  const navigateToHome = (url) => {
    navigateTo("/");
  };

  const navigateToDashboard = (url) => {
    navigateTo("/dashboard");
    handleCloses();
  };
  const gotoCart = (url) => {
    navigateTo("/cart");
    handleCloses();
  };
  const handleSearchGoTo = (searR) => {
    console.log("searR", searR);
    setShowSearchPopupModel(false);
    dispatch(updateUniversalSearchResults([]));
    if (searR?.to_link == "/pharmacy") {
      navigateTo(`/item/generic/${searR?.linkable_id}`);
    } else if (searR?.linkable_type == "packages") {
      navigateTo(`/labPackageDetils/${searR?.linkable_id}`);
    } else if (searR?.linkable_type == "tests") {
      navigateTo(`/labTestDetils/${searR?.linkable_id}`);
    } else if (searR?.type == "hospital") {
      navigateTo(`/hospital/${searR?.linkable_id}`);
    } else {
      navigateTo(`/doctor`);
    }
  };
  const navigateTo = (url) => {
    history.push(url);
  };
  const getImageUrl = () => {
    return null;
  };
  const handleLogin = () => {
    history.push("/signin");
    // setShowLoginPopupModel(true);
  };
  const handleClose = () => {
    setShowLoginPopupModel(false);
  };
  const fullName = `${user?.first_name} ${user?.last_name}`;
  const [uName, setUName] = useState(window.location.pathname.toLowerCase());

  useEffect(() => {
    setUName(location.pathname.toLowerCase());
  }, [location.pathname]);

  useEffect(() => {
    console.log("uName : ", uName);
  }, [uName]);

  const isSubPage =
    uName?.includes("/checkout") ||
    uName?.includes("/cart") ||
    uName?.includes("/dashboard")
      ? true
      : true;

  const handleSelectChange = (event) => {
    const selectedLink = event.target.value;
    if (selectedLink) {
      history.push(selectedLink);
    }
  };

  const handleHeaderClick = (link, name) => {
    setSelectedItem(null);
    navigateTo(link);
    setSelectedNavItem(name);
  };

  const handleMoreClick = (name, link) => {
    setSelectedItem(name);
    navigateTo(link);
    setSelectedNavItem(name);
  };

  const handleBook = (e) => {
    e.preventDefault();
    setShow((prevShow) => !prevShow);
  };

  const handleMenuItemClick = (itemTo, itemName) => {
    handleHeaderClick(itemTo, itemName);
    setIsNavbarCollapsed(true);
    handleCloses();
  };

  const handleMoreItemClick = (itemName, itemTo) => {
    handleMoreClick(itemName, itemTo);
    setIsNavbarCollapsed(true);
    handleCloses();
  };

  return (
    <>
      <div>
        <LoginModel show={showLoginPopupModel} handleClose={handleClose} />
        <ToastContainer />
        <Navbar
          expand={type}
          className="header-page-sec-top-full bg-body-tertiary mb-0 nav_bar"
        >
          <Container fluid>
            <Navbar.Brand href="#">
              <a className="navbar-brand left-image-sec-logo" href="#">
                <img
                  onClick={navigateToHome}
                  src={logo_design}
                  className="brandImg"
                  alt="RaphaCure"
                  width="160"
                  height="60"
                />
                {subDomainDetails?.logo_url && (
                  <img
                    onClick={navigateToHome}
                    src={subDomainDetails?.logo_url}
                    alt={subDomainDetails?.name}
                    width="140"
                    height="50"
                  />
                )}
              </a>
            </Navbar.Brand>
            {user &&
              user?.roles &&
              user?.roles.length > 0 &&
              user?.roles[0]?.linkable_id === null && (
                <div className="pendingOnboarding d-flex align-items-center gap-3 justify-content-center">
                  Please complete the mandatory details to start receiving
                  bookings from Patient.
                  <button
                    className="btn btn-btn btn-outline-danger btn-custom-city me-3"
                    onClick={() => navigateTo("/register")}
                  >
                    Fill Details
                  </button>
                </div>
              )}
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${type}`}
              onClick={handleShows}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${type}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${type}`}
              show={shows}
              onHide={handleCloses}
              // placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${type}`}>
                  <a className="navbar-brand left-image-sec-logo" href="#">
                    <img
                      onClick={navigateToHome}
                      src={logo_design}
                      className="brandImg"
                      alt="RaphaCure"
                      width="160"
                      height="60"
                    />
                    {subDomainDetails?.logo_url && (
                      <img
                        onClick={navigateToHome}
                        src={subDomainDetails?.logo_url}
                        alt={subDomainDetails?.name}
                        width="140"
                        height="50"
                      />
                    )}
                  </a>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-5">
                  <UniversalStyled>
                    <div className="search-component-sec" ref={ref}></div>
                  </UniversalStyled>
                </Nav>

                <Form className="d-flex">
                  <Button
                    variant="btn btn-custom-city me-3 w-max"
                    onClick={handleBook}
                  >
                    {" "}
                    {userCity?.name ? userCity?.name : "Select City"}
                  </Button>

                  {user?.id ? (
                    <div
                      className="d-flex align-items-center me-2 cursor-pointer"
                      onClick={navigateToDashboard}
                    >
                      <a className="navbar-brand me-3">
                        <img
                          src={getImageUrl()}
                          className="object-cover object-center w-8 h-8 rounded-full user-img"
                          alt="User"
                        />
                      </a>
                      <span className="login-user-fnhidden me-2">
                        {user?.first_name
                          ? user?.first_name?.split(" ")[0]
                          : "Dashboard"}
                      </span>
                    </div>
                  ) : (
                    <Button
                      variant="outline"
                      className="btn-custom-city-1 me-2"
                      onClick={handleLogin}
                    >
                      Login
                    </Button>
                  )}
                  {/* <a className="navbar-brand" onClick={gotoCart}>
                    <img src={cart_icon} alt="Cart" width="30" height="24" />
                    <span className="mt-2">{cartItems?.length}</span>
                  </a> */}
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>

      {show && (
        <CityDialogList
          handleClose={() => {
            setShow(false);
          }}
          setShow={setShow}
          element={show}
        />
      )}
    </>
  );
};

export default Header;
