import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Rightimg from "../../assets/images/Login/mobile.png";
import { LoginStyled } from "./Login.Styled";
import { Select, Form as AntdForm } from "antd"; // Import Ant Design Select
import { useDispatch, useSelector } from "react-redux";
import {
  doctorRegistration,
  getDoctorProfileDetails,
  getDoctorSpecializationAPI,
} from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { getConfigInfoAPI } from "../../redux/slices/config/configService";
import { updateUserDetails } from "../../redux/slices/auth/authSlice";

const { Option } = Select;

const ProfileDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { configInfo } = useSelector((ReduxState: any) => ReduxState.config);
  const [specializations, setSpecializations] = useState([]);
  const [formError, setFormError] = useState<string | null>(null);
  const [existingUser, setExistingUser] = useState(false);
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [docLinkableId, setDocLinkableId] = useState<any>(null);
  const [secondDocLinkableId, setSecondDocLinkableId] = useState<any>(null);
  const [catalogue, setCatalogue] = useState(false);
  const location = useLocation();
  const [selectedLanguages, setSelectedLanguages] = useState<any>([]);

  const languages = [
    "English",
    "Gujarati",
    "Hindi",
    "Kannada",
    "Marathi",
    "Odia",
    "Punjabi",
    "Tamil",
    "Telugu",
    "Malayalam",
    "Bangla",
  ];

  const [profileDetails, setProfileDetails] = useState<any>({
    name: "",
    specialization: null,
    gender: "",
    city: null,
    phone: user.phone,
  });

  const [errorData, setErrorData] = useState({
    name: "",
    specialization: "",
    gender: "",
    city: "",
    phone: "",
  });

  // Reset form fields when catalogue becomes true
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isCatalogue = searchParams.get("catalogue") === "true";
    setCatalogue(isCatalogue);

    if (isCatalogue) {
      setProfileDetails({
        name: "",
        specialization: null,
        gender: "",
        city: null,
        phone: "",
      });
    }
  }, [location, catalogue]);

  useEffect(() => {
    const updateLinkableId = async () => {
      if (!docLinkableId) return;
      const user = localStorage.getItem("user") as any;
      let localUser = JSON.parse(user);

      if (localUser?.roles?.length > 0 && docLinkableId) {
        localUser.roles[0].linkable_id = docLinkableId;
        dispatch(updateUserDetails(localUser));
      }
    };
    updateLinkableId();
  }, [docLinkableId]);

  useEffect(() => {
    const getDocDetails = async () => {
      if (!user || catalogue) return; // Skip this block if `catalogue=true`
      let doctorId = user?.roles?.[0]?.linkable_id;
      setDocLinkableId(doctorId);

      const res = await dispatch(getDoctorProfileDetails(doctorId));
      const resJson = JSON.parse(JSON.stringify(res));
      prefillData(resJson);
      if (resJson?.error?.message) {
        toast.error(resJson?.error?.message);
        return;
      }
    };
    getDocDetails();
  }, [catalogue]); // Add catalogue dependency

  const citiesList = configInfo?.cities?.map((city: any) => ({
    label: city.name,
    value: city.name,
  }));

  const prefillData = (data: any) => {
    console.log("languages : ", data?.payload?.data?.doctor.languages);

    const searchParams = new URLSearchParams(location.search);
    const isCatalogue = searchParams.get("catalogue") === "true";
    if (!isCatalogue) {
      const languagesArray = data.payload.data.doctor.languages
        ? data.payload.data.doctor.languages
            .split(", ")
            .map((lang: any) => lang.trim())
        : []; // Convert string to array
      setSelectedLanguages(languagesArray); // Update state

      setProfileDetails({
        name:
          data.payload.data.doctor.name ||
          `${user.first_name} ${user.last_name}`,
        specialization: data.payload.data.doctor.specialization,
        gender: data.payload.data.doctor.gender,
        city: data.payload.data.doctor.city,
        phone: user.phone,
        languages: data.payload.data.doctor.languages || "", // Keep as a string
      });
    }
  };

  useEffect(() => {
    dispatch(getConfigInfoAPI());
  }, [dispatch]);

  useEffect(() => {
    const getDocSpecInfo = async () => {
      const res = await dispatch(getDoctorSpecializationAPI());
      const resJson = JSON.parse(JSON.stringify(res));
      if (resJson?.error) {
        toast.error(resJson?.error?.message);
        return;
      }
      if (
        resJson?.payload?.data &&
        resJson?.payload?.data?.specializations?.length > 0
      ) {
        setSpecializations(resJson?.payload?.data?.specializations || []);
      }
    };
    getDocSpecInfo();
  }, [dispatch]);

  const handlePhoneInputChange = (e: any) => {
    const { name, value } = e.target;
    if (/^\d{0,10}$/.test(value)) {
      setProfileDetails((prevDetails: any) => ({
        ...prevDetails,
        [name]: value,
      }));
      if (value.trim() !== "") {
        setFormError(null);
        setErrorData((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setProfileDetails((prevDetails: any) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setFormError(null);
      setErrorData((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleCityChange = (value: any) => {
    setProfileDetails((prevDetails: any) => ({
      ...prevDetails,
      city: value,
    }));
    if (value) {
      setFormError(null);
      setErrorData((prevErrors) => ({
        ...prevErrors,
        city: "",
      }));
    }
  };

  const handleChange = (value: any) => {
    setSelectedLanguages(value); // Update selectedLanguages state
    const languagesString = value.join(", "); // Convert array to string
    setProfileDetails((prevDetails: any) => ({
      ...prevDetails,
      languages: languagesString, // Update languages in profileDetails
    }));

    // Clear form errors if any
    if (languagesString) {
      setFormError(null);
      setErrorData((prevErrors) => ({
        ...prevErrors,
        languages: "",
      }));
    }
  };

  const handleSpecializationChange = (selectedValues: string[]) => {
    const specializationString = selectedValues.join(", ");
    setProfileDetails((prevDetails: any) => ({
      ...prevDetails,
      specialization: specializationString,
    }));
    if (specializationString) {
      setFormError(null);
      setErrorData((prevErrors) => ({
        ...prevErrors,
        specialization: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {} as any;
    if (!profileDetails?.name?.trim()) errors.name = "Please enter your Name";
    if (!profileDetails?.specialization?.trim()) {
      errors.specialization = "Please select at least one Specialization";
    }
    if (!profileDetails?.gender?.trim()) errors.gender = "Please select Gender";
    if (!profileDetails?.city) errors.city = "Please select City";
    if (!profileDetails?.phone?.trim())
      errors.phone = "Please enter your Phone number";

    setErrorData(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    console.log("selectedLanguages : ", selectedLanguages);
    console.log("specializations : ", specializations);
  }, [selectedLanguages, specializations]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (validateForm()) {
      var initialChildLinkId = null;
      if (catalogue) {
        const childDocRegisterBody = {
          body: {
            name: profileDetails?.name,
            specialization: profileDetails?.specialization,
            languages: selectedLanguages.join(", "),
            gender: profileDetails?.gender,
            city: profileDetails?.city?.toLowerCase(),
            phone: profileDetails?.phone,
            isParent: false,
          },
          parentId: null,
        };

        const res = await dispatch(doctorRegistration(childDocRegisterBody));
        const resJson = JSON.parse(JSON.stringify(res));
        initialChildLinkId = resJson?.payload?.userRole?.linkable_id;
      }

      const docRegisterBody = {
        body: {
          name: profileDetails?.name,
          specialization: profileDetails?.specialization,
          languages: selectedLanguages.join(", "),
          gender: profileDetails?.gender,
          city: profileDetails?.city?.toLowerCase(),
          phone: profileDetails?.phone,
          isParent: !catalogue,
        },
        parentId: initialChildLinkId || null,
      };

      const res = await dispatch(doctorRegistration(docRegisterBody));
      const resJson = JSON.parse(JSON.stringify(res));

      if (resJson?.error) {
        setFormError(resJson?.error?.message);
        toast.error(resJson?.error?.message || "Something went wrong");
        return;
      }
      if (resJson?.payload?.data) {
        if (!initialChildLinkId) {
          if (resJson?.payload?.data?.roles.length > 0) {
            setDocLinkableId(resJson?.payload?.data?.roles[0]?.linkable_id);
          }
        }
        const searchParams = new URLSearchParams(location.search);
        const isCatalogue = searchParams.get("catalogue") === "true";
        if (!isCatalogue) {
          const user = localStorage.getItem("user") as any;
          let fullName = profileDetails?.name;
          let nameParts = fullName.trim().split(" ");
          let firstName = nameParts[0];
          let lastName = nameParts.slice(1).join(" ");
          let localUser = JSON.parse(user);
          localUser.first_name = firstName;
          localUser.last_name = lastName;
          const accessToken = resJson?.payload?.data?.accessToken;
          if (accessToken) {
            localUser.accessToken = accessToken;
          }
          dispatch(updateUserDetails(localUser));
        }

        toast.success("Registration Successful");
        if (!isCatalogue) {
          history.push("/register/medicalregistration");
        } else {
          setSecondDocLinkableId(resJson?.payload?.userRole[0]?.linkable_id);
          history.push(
            `/register/medicalregistration?catalogue=${catalogue}&doctorId=${resJson?.payload?.userRole[0]?.linkable_id}`
          );
        }
      }
    }
  };

  return (
    <LoginStyled>
      <div>
        <div className="profile-page">
          <div className="page-left">
            <h2>Hello Doctor! Let's create your dedicated profile.</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label className="form-label">Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please enter your Name"
                  name="name"
                  value={profileDetails?.name}
                  onChange={handleInputChange}
                  isInvalid={!!errorData?.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData?.name}
                </Form.Control.Feedback>
              </Form.Group>

              <AntdForm.Item
                label="Language"
                name="languages"
                className="align-items-start"
                rules={[
                  {
                    required: true,
                    message: "Please select at least one language!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Please select your language(s)"
                  onChange={handleChange} // Updated handler
                  value={selectedLanguages} // Controlled value
                  style={{ width: "100%" }}
                  className="df"
                >
                  {languages.map((lang) => (
                    <Option key={lang} value={lang}>
                      {lang}
                    </Option>
                  ))}
                </Select>
              </AntdForm.Item>

              {/* Specialization Field */}
              <Form.Group controlId="specialization">
                <Form.Label>Specialization</Form.Label>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Specialization(s)"
                  value={profileDetails?.specialization?.split(", ") || []}
                  onChange={handleSpecializationChange}
                  className={errorData?.specialization ? "is-invalid" : ""}
                  style={{ width: "100%" }}
                >
                  {specializations?.map((spec) => (
                    <Option key={spec} value={spec}>
                      {spec}
                    </Option>
                  ))}
                </Select>
                {errorData?.specialization && (
                  <div className="invalid-feedback d-block">
                    {errorData?.specialization}
                  </div>
                )}
              </Form.Group>

              <Form.Group>
                <Form.Label className="form-label">Gender</Form.Label>
                <div className="gender-options">
                  <Form.Check
                    inline
                    type="radio"
                    label="Male"
                    name="gender"
                    value="male"
                    id="gender-male"
                    checked={profileDetails?.gender === "male"}
                    onChange={handleInputChange}
                    isInvalid={!!errorData?.gender}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Female"
                    name="gender"
                    value="female"
                    id="gender-female"
                    checked={profileDetails?.gender === "female"}
                    onChange={handleInputChange}
                    isInvalid={!!errorData?.gender}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Other"
                    name="gender"
                    value="other"
                    id="gender-other"
                    checked={profileDetails?.gender === "other"}
                    onChange={handleInputChange}
                    isInvalid={!!errorData?.gender}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorData?.gender}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label className="form-label">Phone</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Please enter your Phone number"
                  name="phone"
                  value={profileDetails?.phone}
                  onChange={handlePhoneInputChange}
                  isInvalid={!!errorData?.phone}
                  maxLength={10}
                  disabled={!docLinkableId}
                />
                <Form.Control.Feedback type="invalid">
                  {errorData?.phone}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label>City</Form.Label>
                <Select
                  showSearch
                  placeholder="Select City"
                  value={profileDetails?.city}
                  onChange={handleCityChange}
                  className={errorData?.city ? "is-invalid" : ""}
                >
                  {citiesList?.map((city: any) => (
                    <Option key={city?.value} value={city?.value}>
                      {city?.label}
                    </Option>
                  ))}
                </Select>
                {errorData?.city && (
                  <div className="invalid-feedback d-block">
                    {errorData?.city}
                  </div>
                )}
              </Form.Group>

              {formError && <div className="error-message">{formError}</div>}
              <Button className="continue-button mt-4" type="submit">
                Continue
              </Button>
            </Form>
          </div>
          <div className="pageright">
            <img src={Rightimg} alt="Profile Illustration" />
          </div>
        </div>
      </div>
    </LoginStyled>
  );
};

export default ProfileDetails;
