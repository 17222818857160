export const getTotalAmount = (cartItems) => {
  var totalAmount = 0;
  cartItems.map((itemCard, indexItem) => {
    var itemPrice = itemCard?.quantity * itemCard?.priceList[0]?.price;
    totalAmount = totalAmount + itemPrice;
  });
  return totalAmount;
};

export const getProductPrice = (product) => {
  console.log("product2", product);
  var totalAmount = "---";
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  if (userData) {
    console.log("userData", userData);
    product?.priceList.map((itemp) => {
      if (
        itemp?.city == userData?.data?.city &&
        itemp?.client == userData?.data?.companyName
      ) {
        totalAmount =
          itemp?.price + "(" + itemp?.discount + "%) / " + product?.priceType;
      }
    });
  }

  return totalAmount;
};
export const handleAddtoCard = (categoryName, item) => {
  // const raphaCartL = localStorage.getItem("raphaCart");
  // const prevItems = raphaCartL ? JSON.parse(raphaCartL) : [];
  // prevItems.push({ ...item, categoryName });
  // localStorage.setItem("raphaCart", prevItems);
};

export const parseAddress = (place, addressStr) => {
  const selectedAddress = addressStr?.split(",") ?? [];
  return {
    address1: selectedAddress[0],
    address2: "",
    city:
      place?.address_components?.filter((a) =>
        a?.types?.includes("locality")
      )[0]?.long_name ||
      selectedAddress[1] ||
      "",
    state:
      place?.address_components?.filter((a) =>
        a?.types?.includes("administrative_area_level_1")
      )[0]?.long_name ||
      selectedAddress[2] ||
      "",
    country:
      place?.address_components?.filter((a) => a?.types?.includes("country"))[0]
        ?.long_name ||
      selectedAddress[3] ||
      "",
    zipcode:
      place?.address_components?.filter((a) =>
        a?.types?.includes("postal_code")
      )[0]?.long_name || "",
  };
};
export const checkIsMobile = () => {
  return window.innerWidth <= 675;
};

export function transformText(input) {
  return input
    ?.toLowerCase() // Ensure all letters are lowercase initially
    ?.split("_") // Split the string by underscores
    ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)) // Capitalize the first letter of each word
    ?.join(" "); // Join the words with a space
}

const calculateAge = (dob) => {
  if (!dob) {
    // If dob is null or undefined, return null
    return null;
  }

  const birthDate = new Date(dob);

  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export function formatStatus(status) {
  return status
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const htmlTemplate = async (data) => {
  console.log("data : ", data);

  const logoUrl = "https://raphacure-public-images.s3.ap-south-1.amazonaws.com/76907-1733836287302.png";

  const attachment = data?.attachments?.[0]; // Only consider the first attachment
  const medicines = attachment?.prescriptions_medicines || [];
  const tests = attachment?.prescriptions_tests || [];

  return `<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Consultation Report</title>
  <style>
      body {
          font-family: Arial, sans-serif;
          margin: 0;
          padding: 0;
          background-color: #f4f4f4;
      }

      h3 {
          margin-top: 1rem;
      }

      .table-div {
          border-radius: 2px;
          width: 100%;
          height: 100%;
          background-color: #fff;
          padding: 1rem;
      }

      .container {
          width: 800px;
          margin: 20px auto;
          background-color: #fff;
          border: 1px solid #ddd;
          padding: 20px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }

      @media screen and (max-width: 700px) {
          .container {
              width: 100%;
          }
      }

      .header,
      .footer {
          text-align: center;
          padding: 10px 0;
      }

      .header img {
          width: 50px;
          height: 50px;
      }

      .content {
          margin: 20px 0;
      }

      .summary-section, .cons-summary-section {
          display: flex;
          justify-content: space-between;
          border: 1px solid #ddd;
          padding: 10px;
          margin-bottom: 20px;
          background-color: #f9f9f9;
          font-size: 14px;
      }

      .summary-section{
        flex-direction: row;
      }

      .cons-summary-section{
      flex-direction:column;
      }

      .summary-item {
          text-align: left;
          font-size: 1rem;
      }

      .summary-item p {
          margin: 5px 0;
      }

      .table {
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
      }

      .table th,
      .table td {
          border: 1px solid #9747FF;
          padding: 8px;
          text-align: center;
      }

      .table th {
          background-color: #f2f2f2;
      }

      .notes {
          background-color: white;
          box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.1) inset;
          padding: 7px;
          border: 1px solid #ddd;
          margin: inherit;
      }

      .signature {
          text-align: left;
          margin-top: 2rem;
      }

      .footer {
          display: flex;
          justify-content: space-between;
      }
  </style>
</head>

<body>

  <div class="container">
      <div class="header">
          <img src=${logoUrl} alt="RaphaCure" style="height: 8vh; width: auto; float: right;">
          <div style="display: flex; flex-direction: column; align-items: flex-start; margin-left: 1rem; margin-top: 1rem;">
              <h2 id="dr">Dr. ${data?.doctor?.name || "N/A"}</h2>
              <p style="margin-left: 1rem;">${data?.doctor?.specialization || "N/A"
    }</p>
              <p style="margin-left: 1rem;">${formatStatus(data?.type) || "N/A"
    }</p>
          </div>
      </div>

      <!-- New Summary Section -->
      <div class="summary-section">
          <div class="summary-item">
              <p><strong>Booking No:</strong> ${data?.id || "N/A"}</p>
          </div>
          <div class="summary-item">
              <p><strong>Prescription Date:</strong> ${data?.collection_1_date || "N/A"}</p>
          </div>
          <div class="summary-item">
              <p><strong>Next Visit Date:</strong> ${attachment?.next_visit
      ? new Date(attachment?.next_visit).toLocaleDateString()
      : "N/A"}</p>
          </div>
      </div>

      <!-- Consultation Summary Section -->
      <div class="cons-summary-section">
        <div class="summary-item">
          <p><strong>Symptoms:</strong> ${attachment?.symptoms || "N/A"}</p>
        </div>
        <div class="summary-item">
          <p><strong>Advice:</strong> ${attachment?.note || "N/A"}</p>
        </div>
      </div>

      <!-- Medicine Table -->
      <div class="table-div">
          <h3>Medicine Prescribed</h3>
          <table class="table">
              <thead>
                  <tr>
                      <th>SlNo</th>
                      <th>Brand</th>
                      <th>Frequency</th>
                      <th>No of Days</th>
                      <th>Intake</th>
                  </tr>
              </thead>
              <tbody>
                  ${medicines
      .map(
        (medicine, index) => `
                      <tr>
                          <td>${index + 1}</td>
                          <td>${medicine?.medicine?.service_name || "N/A"
          }</td>
                          <td>${medicine?.frequency || "N/A"}</td>
                          <td>${medicine?.no_of_days || "N/A"}</td>
                          <td>${medicine?.intake || "N/A"}</td>
                      </tr>
                  `
      )
      .join("")}
              </tbody>
          </table>
      </div>

      <!-- Tests Table -->
      <div class="table-div">
          <h3>Tests Recommended</h3>
          <table class="table">
              <thead>
                  <tr>
                      <th>SlNo</th>
                      <th>Test Name</th>
                  </tr>
              </thead>
              <tbody>
                  ${tests
      .map(
        (test, index) => `
                      <tr>
                          <td>${index + 1}</td>
                          <td>${test?.test?.service_name || "N/A"}</td>
                      </tr>
                  `
      )
      .join("")}
              </tbody>
          </table>

          <!-- Doctor's Signature Section -->
          <div class="signature">
              <p><strong>Doctor's Signature:</strong></p>
          </div>

          <!-- Footer Section -->
          <div class="footer" style="border-top: 1px dashed #000; margin-top: 20px; padding-top: 10px;">
              <div>
                  <p style="margin: 0;">
                      <img src="https://cdn-icons-png.flaticon.com/512/561/561127.png" alt="email" style="width: 16px; height: 16px; vertical-align: middle;"> 
                      wellness@raphacure.com
                  </p>
                  <p style="margin: 0; text-align:left;">
                      <img src="https://cdn-icons-png.flaticon.com/512/483/483947.png" alt="phone" style="width: 16px; height: 16px; vertical-align: middle;"> 
                      +91 95551 66000
                  </p>
              </div>
              <div>
                  <p style="margin: 0;">
                      <img src="https://cdn-icons-png.flaticon.com/512/684/684908.png" alt="location" style="width: 16px; height: 16px; vertical-align: middle;"> 
                      Cure & Care Primary Care Pvt. Ltd.<br>
                      208, MJ Aldilla, Begur Road, Bengaluru- 560068
                  </p>
              </div>
          </div>

      </div>
  </div>
</body>
</html>`;
};
