import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import { CreateHyperSiteStyled } from "./CreateHyperSite.styled";
import BannerCard from "../../components/BannerCards";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllHyperSites,
  updateHyperSite,
} from "../../redux/slices/hypersite/hypersiteService";

const BannersComponent = ({ selectedHypersite }: any) => {
  const [banners, setBanners] = useState<string[]>([]); // Store only image URLs
  const dispatch = useDispatch();

  const handleAddBanner = () => {
    setBanners([...banners, ""]); // Add an empty string for a new image
  };

  const updateBanner = (index: number, imageUrl: string) => {
    setBanners((prev) =>
      prev.map((banner, idx) => (idx === index ? imageUrl : banner))
    );
  };

  const handleDeleteBanner = (index: number) => {
    setBanners((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleSubmit = async () => {
    console.log("Submitted Banners:", banners);
    const body = {
      id: selectedHypersite?.id,
      payload: {
        user_id: selectedHypersite?.user_id,
        site_name: selectedHypersite?.site_name,
        banner_images: banners,
      },
    };
    console.log("body : ", body);

    const res = (await dispatch(updateHyperSite(body))) as any;
    console.log("res : ", res);

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    } else {
      toast.success("HyperSite Updated Successfully");
      dispatch(getAllHyperSites());
    }
  };

  useEffect(() => {
    const initialBanners = selectedHypersite?.banner_images || [];
    setBanners(initialBanners);
  }, [selectedHypersite]);

  return (
    <CreateHyperSiteStyled>
      {selectedHypersite ? (
        <div className="offers-container">
          <div className="main_home_banner">
            <p className="banner_header">Banners</p>
            <Button
              className="pre_view_btn"
              onClick={() =>
                window.open(
                  "https://www.raphaplus.in/hypersite/testsite",
                  "_blank"
                )
              }
            >
              Preview in New Tab
            </Button>
          </div>

          {banners.length > 0 && (
  <div className="banner-list-container">
    {banners.map((image, idx) => (
      <div key={idx} className="offer-card">
        <BannerCard
          image={image}
          updateBanner={updateBanner}
          index={idx}
          from={"Banner"}
        />
        <button
          className="delete-button"
          onClick={() => handleDeleteBanner(idx)}
        >
          DELETE
        </button>
      </div>
    ))}
  </div>
)}

          <div className="controls">
            <button className="add-button" onClick={handleAddBanner}>
              + ADD NEW BANNER
            </button>
            <button className="submit-button" onClick={handleSubmit}>
              Update {selectedHypersite?.site_name || ""}
            </button>
          </div>
        </div>
      ) : (
        <div className="offers-container">
          <p>Please Choose a Hypersite first</p>
        </div>
      )}
    </CreateHyperSiteStyled>
  );
};

export default BannersComponent;
