import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  AutoComplete,
  Select,
  Checkbox,
  Dropdown,
  Menu,
  Upload,
} from "antd";
import { FiMoreVertical } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import {
  getAllClinicsAPI,
  getAllDoctorClinicsAPI,
} from "../../redux/slices/doctor/doctorService";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";

const { Search, TextArea } = Input;
const { Option } = Select;

const Radiology = () => {
  const specializationOptions = [
    { label: "General Physician", value: "General Physician" },
    { label: "Pediatrics", value: "Pediatrics" },
    { label: "Opthalmology", value: "Opthalmology" },
    { label: "Urology", value: "Urology" },
    { label: "Neurology", value: "Neurology" },
    { label: "Cardiology", value: "Cardiology" },
    { label: "Dental Surgery", value: "Dental Surgery" },
    { label: "General Surgery", value: "General Surgery" },
    { label: "Psychiatry", value: "Psychiatry" },
    { label: "Orthopedics", value: "Orthopedics" },
    { label: "Anesthesiology", value: "Anesthesiology" },
  ];

  const data = [
    {
      key: "1",
      name: "Dr. Name",
      id: "245dghs6452",
      specialization: "Cardiology",
      consultFee: "₹150",
    },
    {
      key: "2",
      name: "Dr. Name",
      id: "SKU001",
      specialization: "Neurology",
      consultFee: "₹150",
    },
    {
      key: "3",
      name: "Dr. Name",
      id: "SKU001",
      specialization: "General Physician",
      consultFee: "₹150",
    },
  ];

  const history = useHistory();
  const [clinicOptions, setClinicOptions] = useState([]);
  const [clinicData, setClinicData] = useState([]); // To store full clinic details
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]); // For checkbox selection
  const [filteredSpecializationOptions, setFilteredSpecializationOptions] =
    useState(specializationOptions);
  const [selectedClinic, setSelectedClinic] = useState<any>();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [docId, setDocId] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.roles?.length > 0 && user.roles[0].linkable_id) {
      setDocId(user.roles[0].linkable_id); // Set doctor ID from user data
    }
  }, [user]);

  const getClinics = async () => {
    if (!docId) return; // Ensure docId exists before making the API call

    const res = await dispatch(getAllDoctorClinicsAPI(docId));
    const resJson = JSON.parse(JSON.stringify(res));

    if (resJson.payload) {
      const filteredClinics = resJson.payload.doctorClinics.filter(
        (clinic: any) => clinic.status !== "deleted"
      );
      setClinicData(filteredClinics); // Store clinic data

      const clinicOptions = filteredClinics.map((clinic: any) => ({
        label: clinic.name, // Display clinic name
        value: clinic.id, // Use clinic id for selection
      }));
      setClinicOptions(clinicOptions); // Set clinic options for dropdown

      if (filteredClinics.length > 0) {
        const defaultClinic = filteredClinics[0];
        setSelectedClinic(defaultClinic); // Set default selected clinic
        console.log("Default Selected Clinic:", defaultClinic);
      }
    }
  };

  useEffect(() => {
    getClinics();
  }, [dispatch, docId]);

  const handleSearch = (value: any) => {
    setFilteredSpecializationOptions(
      specializationOptions.filter((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const handleMenuClick = (e: any) => {
    if (e.key === "edit") {
      console.log("Edit clicked");
      // Handle edit action
    } else if (e.key === "delete") {
      console.log("Delete clicked");
      // Handle delete action
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <div className="doctor-name-cell">
          <img
            src="/path/to/your/image.png"
            alt="Doctor Avatar"
            className="doctor-avatar"
          />
          <div>
            <div className="doctor-name">{record.name}</div>
            <div className="doctor-id">{record.id}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Specialization",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Consult Fee",
      dataIndex: "consultFee",
      key: "consultFee",
    },
    {
      title: "Actions",
      key: "actions",
      render: () => (
        // @ts-ignore comment
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button icon={<FiMoreVertical />} />
        </Dropdown>
      ),
    },
  ];

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    // Save Radiology logic here
    console.log("Radiology saved");
    handleCloseModal(); // Close modal after saving
  };

  return (
    <div>
      <div className="topBanner">
        <h1>Radiology</h1>
        <button onClick={handleOpenModal}>Add Radiology</button>
      </div>
      <div className="catalogue-container">
        <div className="catalogue-header">
          <Search
            placeholder="Search by Name"
            className="search-bar"
            onSearch={handleSearch}
          />
          <AutoComplete
            options={filteredSpecializationOptions}
            placeholder="Specialization"
            className="specialization-dropdown"
            filterOption={(inputValue: any, option: any) =>
              option.value.toLowerCase().includes(inputValue.toLowerCase())
            }
          />
          <Select
            placeholder="Sort By"
            className="sort-dropdown"
            style={{ width: 150 }}
          >
            <Option value="name">Name</Option>
            <Option value="specialization">Specialization</Option>
            <Option value="fee">Consult Fee</Option>
          </Select>

          <Select
            showSearch
            placeholder="Select Clinic"
            className="clinic-dropdown"
            style={{ width: 200 }}
            options={clinicOptions}
            value={selectedClinic?.id} // Set selected clinic value
            onSelect={(value: any) => {
              const selectedClinic = clinicData.find(
                (clinic: any) => clinic.id === value
              ); // Find selected clinic
              setSelectedClinic(selectedClinic); // Update selected clinic
              console.log("Selected Clinic Details: ", selectedClinic); // Log clinic details
            }}
            filterOption={(input: any, option: any) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
          />

          <span className="clear-all">Clear All</span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          pagination={false}
        />
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Radiology</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Test Name</label>
              <Input placeholder="Enter test name" />
            </div>

            <div className="form-group">
              <label>Scan Price</label>
              <Input prefix="₹" placeholder="Enter scan price" />
            </div>

            <div className="form-group">
              <label>Select Category</label>
              <Select
                placeholder="Select Category"
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <Option value="Sexual Health">Sexual Health</Option>
                <Option value="Life Style">Life Style</Option>
                <Option value="Diabetes">Diabetes</Option>
                <Option value="Senior">Senior</Option>
                <Option value="Full Body">Full Body</Option>
                <Option value="Allergy">Allergy</Option>
                <Option value="Women Health">Women Health</Option>
              </Select>
            </div>

            <div className="form-group">
              <label>Upload Image</label>
              <Upload>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </div>

            <div className="form-group">
              <label>Preparation</label>
              <TextArea rows={2} placeholder="Test preparation" />
            </div>

            <div className="form-group">
              <label>Description</label>
              <TextArea rows={2} placeholder="Description" />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseModal} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Radiology;
