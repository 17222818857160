import React, { useEffect, useState } from "react";
import { PrescriptionStyled } from "./Prescription.styled";
import { FaSearch, FaTimes, FaRegUserCircle } from "react-icons/fa";
import {
  Input,
  Select,
  Button,
  Checkbox,
  Row,
  Col,
  Collapse as AntdCollapse,
  Radio,
} from "antd";
import { Form, InputGroup, Modal, Button as BSTButton } from "react-bootstrap";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMedicinesAPI,
  getAllTestsAPI,
  getPrescriptionDataAPI,
  uploadPdfPrescription,
} from "../../redux/slices/prescription/prescriptionService";
import { toast } from "react-toastify";
import {
  addDocPrescriptionAPI,
  getPrescriptionData,
  getProfileDetails,
} from "../../redux/slices/doctor/doctorService";
import {
  getAllBookingListAPI,
  getBookingDetailsAPI,
  updateBookingStatusAPI,
} from "../../redux/slices/dashboard/dashboardService";
import { htmlTemplate, transformText } from "../../Scenes/common";
import SearchBar from "./SearchBar";
import { getUniversalSearchData } from "../../redux/slices/generic/genericService";
import PrescriptionPreview from "./PrescriptionPreview";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";
import { pdf } from "@react-pdf/renderer";
import PrescriptionPdfDocument from "./PrescriptionPdfDocument";
import { useHistory } from "react-router-dom";
import NutritionDiets from "../../components/NutritionDiet/NutritionDiets";

const { Option } = Select;
const Collapse: any = AntdCollapse;
const { Panel }: { Panel: any } = Collapse;

const Prescription = (props: any) => {
  const { id: prescriptionId } = props.match?.params;
  const [issue, setIssue] = useState<any>("");
  const [medicine, setMedicine] = useState<any>("");
  const [test, setTest] = useState("");
  const [issues, setIssues] = useState<any>([]);
  const [dose, setDose] = useState("dosage");
  const [noOfDays, setNoOfDays] = useState(1);
  const [startImmediately, setStartImmediately] = useState<boolean>(true);
  const [frequency, setFrequency] = useState({
    morning: 0,
    afternoon: 0,
    night: 0,
  });
  const [intake, setIntake] = useState("after food");
  const [startDate, setStartDate] = useState<any>(new Date());
  const [note, setNote] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [nextVisit, setNextVisit] = useState<any>(null);
  const dispatch = useDispatch();
  const [allMedicines, setAllMedicines] = useState<any>();
  const [alltests, setAllTests] = useState<any>();
  const [selectedMedicines, setSelectedMedicines] = useState<any[]>([]);
  const [selectedTests, setSelectedTests] = useState<any[]>([]);
  const [currentBooking, setCurrentBooking] = useState<any>();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const { universalSearchResults } = useSelector(({ generic }: any) => generic);
  const [medicineType, setMedicineType] = useState("branded"); // Default value is 'branded'
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newMedicine, setNewMedicine] = useState({ name: "", price: "" });
  const [newMedAdded, setNewMedAdded] = useState(false);
  const [editMedModal, setEditMedModal] = useState(false);
  const [medToBeEdited, setMedToBeEdited] = useState();
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [docObj, setDocObj] = useState<any>({});
  const [isDietician, setIsDietician] = useState(false);
  const [showDietModal, setShowDietModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const getDoctorProfileInfo = async () => {
      try {
        const response = await dispatch(getProfileDetails());
        const resJson = JSON.parse(JSON.stringify(response));
        setDocObj(resJson?.payload?.data?.doctor);
      } catch (error: any) {
        console?.error(error);
        toast.error(error);
      }
    };
    getDoctorProfileInfo();
  }, []);

  useEffect(() => {
    console.log("docObj : ", docObj);
    if (docObj?.specialization?.includes("Dietician")) {
      setIsDietician(true);
    }
  }, [docObj]);

  const handleTypeChange = (e: any) => {
    setMedicineType(e.target.value);
  };
  useEffect(() => {
    const getBkUserDetails = async () => {
      const res = (await dispatch(getBookingDetailsAPI(prescriptionId))) as any;
      setCurrentBooking(res?.payload.data.bookingById);
      console.log("setCurrentBooking : ", res?.payload.data.bookingById);

      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
        return;
      }
    };
    getBkUserDetails();
  }, [prescriptionId]);

  useEffect(() => {
    const getAllMedicines = async () => {
      const res = (await dispatch(getAllMedicinesAPI())) as any;

      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
      }
      if (res?.payload?.data?.medicines) {
        console.log("getAllMedicines : ", res?.payload?.data?.medicines);
        setAllMedicines(res?.payload?.data?.medicines);
      }
    };
    getAllMedicines();
  }, []);

  const handleStartImmediatelyChange = (e: any) => {
    const isChecked = e.target.checked;
    setStartImmediately(isChecked);
    if (isChecked) {
      setStartDate(new Date()); // Set start date to today if checked
    } else {
      setStartDate(null); // Clear start date if unchecked
    }
  };

  useEffect(() => {
    const getPrescription = async () => {
      const res = (await dispatch(getPrescriptionData(prescriptionId))) as any;
      if (res?.error) {
        toast.error("Failed to fetch prescription data.");
        return;
      }

      const data = res?.payload?.data;

      if (data) {
        // Set symptoms as issues list
        console.log("data : ", data);

        setIssues(data.symptoms ? data.symptoms.split(", ") : []);
        setNote(data?.note || "");
        setNextVisit(data?.next_visit);

        // Set selected medicines
        setSelectedMedicines(
          data.prescriptions_medicines.map((med: any) => ({
            service_name: med?.medicine_id, // Assuming medicine_id is the name or adjust accordingly
            dose: med?.dosage,
            noOfDays: med?.no_of_days,
            medicine_id: med?.medicine_id,
            frequency: {
              morning: parseInt(med?.frequency.split("-")[0], 10),
              afternoon: parseInt(med?.frequency.split("-")[1], 10),
              night: parseInt(med?.frequency.split("-")[2], 10),
            },
            intake: med?.intake,
            startDate: dayjs(med?.start_date).toDate(),
            note: med?.note,
            price: {
              discounted_price: med?.price?.discounted_price, // Adjust if price data is available in response
            },
          }))
        );

        setSelectedTests(
          [...data?.diagnostic_tests, ...data?.radiology_tests].map(
            (test: any) => ({
              service_name: test?.test_id, // Assuming test_id is the name or adjust accordingly
              diagnosis: test?.diagnosis,
              symptoms: test?.symptoms,
              test_id: test?.test_id,
              nextVisit: test?.next_visit
                ? dayjs(test?.next_visit).toDate()
                : null,
              testNote: test?.note,
              type: test?.type,
              price: {
                discounted_price: test?.price?.discounted_price, // Adjust if price data is available in response
              },
            })
          )
        );
      }
    };

    getPrescription();
  }, [prescriptionId, dispatch]);

  useEffect(() => {
    const getAllTests = async () => {
      const res = (await dispatch(getAllTestsAPI())) as any;
      if (res?.error) {
        toast.error(res?.error?.message || "Unknown Error Occured");
      }
      if (res?.payload?.data?.tests) {
        setAllTests(res?.payload?.data?.tests);
      }
    };
    getAllTests();
  }, []);

  const EditMedicineModal = ({
    show,
    handleClose,
    medicineToEdit,
    onSave,
  }: {
    show: boolean;
    handleClose: () => void;
    medicineToEdit: any;
    onSave: (updatedMedicine: any) => void;
  }) => {
    const [editedMedicine, setEditedMedicine] = useState(medicineToEdit);

    const handleChange = (field: string, value: any) => {
      setEditedMedicine({ ...editedMedicine, [field]: value });
    };

    const handleFrequencyChange = (timeOfDay: string, checked: boolean) => {
      // setFrequency({ ...frequency, [timeOfDay]: checked ? 1 : 0 });
      setEditedMedicine({
        ...editedMedicine,
        frequency: {
          ...editedMedicine.frequency,
          [timeOfDay]: checked ? 1 : 0,
        },
      });
    };

    const handleSave = () => {
      onSave(editedMedicine);
      handleClose();
    };

    React.useEffect(() => {
      setEditedMedicine(medicineToEdit);
    }, [medicineToEdit]);

    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Medicine</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDose" className="mb-3">
              <Form.Label>Dose</Form.Label>
              <Form.Control
                type="text"
                value={editedMedicine.dose}
                onChange={(e) => handleChange("dose", e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formDays" className="mb-3">
              <Form.Label>Days</Form.Label>
              <Form.Control
                type="number"
                value={editedMedicine.noOfDays}
                onChange={(e) =>
                  handleChange("noOfDays", parseInt(e.target.value))
                }
              />
            </Form.Group>

            <Form.Group controlId="formFrequency" className="mb-3">
              <Form.Label>Frequency</Form.Label>
              <Row>
                <Col span={8}>
                  <Checkbox
                    checked={!!editedMedicine.frequency.morning}
                    onChange={(e) =>
                      handleFrequencyChange("morning", e.target.checked)
                    }
                  >
                    Morning
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    checked={!!editedMedicine.frequency.afternoon}
                    onChange={(e) =>
                      handleFrequencyChange("afternoon", e.target.checked)
                    }
                  >
                    Afternoon
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox
                    checked={!!editedMedicine.frequency.night}
                    onChange={(e) =>
                      handleFrequencyChange("night", e.target.checked)
                    }
                  >
                    Night
                  </Checkbox>
                </Col>
              </Row>
            </Form.Group>

            <Form.Group controlId="formIntake" className="mb-3">
              <Form.Label>Intake</Form.Label>
              <Form.Select
                value={editedMedicine.intake}
                onChange={(e) => handleChange("intake", e.target.value)}
              >
                <option value="before food">Before Food</option>
                <option value="after food">After Food</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleAddMedicine = () => {
    let selectedMedicine;
    if (newMedAdded) {
      selectedMedicine = {
        linkable_id: null,
        service_name: newMedicine.name,
        category: medicineType,
        actual_cost: parseInt(newMedicine.price),
      };
    } else {
      selectedMedicine = universalSearchResults.find(
        (med: any) => med?.linkable_id === medicine
      );
    }
    if (selectedMedicine) {
      const newMedicine = {
        ...selectedMedicine,
        dose,
        noOfDays,
        frequency,
        intake,
        startDate,
        // note,
      };

      if (dose === "" || intake === "") {
        console.log("dose : ", dose);
        console.log("intake : ", intake);
        return;
      }
      console.log("newMedicine : ", newMedicine);

      setSelectedMedicines([...selectedMedicines, newMedicine]);
      setMedicine("");
      setDose("dosage");
      setNoOfDays(1);
      setFrequency({ morning: 0, afternoon: 0, night: 0 });
      setIntake("after food");
      setStartDate(new Date());
      setNewMedAdded(false);
    }
  };

  const [selectedTestNames, setSelectedTestNames] = useState<string[]>([]);

  const handleAddTests = () => {
    const newTests = alltests.filter((testItem: any) =>
      selectedTestNames.includes(testItem.service_name)
    );
    setSelectedTests((prevTests) => [...prevTests, ...newTests]);
    setSelectedTestNames([]); // Clear the selection
  };

  // const handleAddTest = () => {
  //   const selectedTest = alltests.find(
  //     (testItem: any) => testItem.service_name === test
  //   );
  //   if (selectedTest) {
  //     const newTest = {
  //       ...selectedTest,
  //     };
  //     setSelectedTests([...selectedTests, newTest]);
  //     setTest("");
  //   }
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
    setNewMedicine({ name: "", price: "" });
  };

  const handleEditMedicine = (index: number) => {
    console.log("selectedMedicines : ", selectedMedicines);

    const medicineToEdit = selectedMedicines[index];
    console.log("medicineToEdit : ", medicineToEdit);
    setMedToBeEdited(medicineToEdit);
    setEditMedModal(true);
  };

  const handleSaveEditedMedicine = (updatedMedicine: any) => {
    const updatedMedicines = selectedMedicines.map((med) =>
      med.medicine_id === updatedMedicine.medicine_id ? updatedMedicine : med
    );
    setSelectedMedicines(updatedMedicines);
  };

  const handleDeleteMedicine = (index: number) => {
    setSelectedMedicines(selectedMedicines.filter((_, i) => i !== index));
  };

  const renderMedicineRows = () => {
    return selectedMedicines.map((medicine, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{medicine?.service_name || medicine?.name}</td>
        {/* <td>{medicine?.dose}</td> */}
        <td>{medicine?.noOfDays}</td>
        <td>{`${medicine?.frequency?.morning}-${medicine?.frequency?.afternoon}-${medicine?.frequency?.night}`}</td>
        <td>{medicine?.intake}</td>
        <td>{medicine?.price?.discounted_price || "N/A"}</td>
        <td>
          <Button type="link" onClick={() => handleEditMedicine(index)}>
            Edit
          </Button>
          <Button
            type="link"
            danger
            onClick={() => handleDeleteMedicine(index)}
          >
            Delete
          </Button>
        </td>
      </tr>
    ));
  };

  const handleDeleteTest = (index: number) => {
    setSelectedTests(selectedTests.filter((_, i) => i !== index));
  };

  const renderTestRows = (type: string) => {
    return selectedTests
      .filter((test) => test?.type === type)
      .map((test, index) => (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{test?.service_name}</td>
          {/* <td>{test?.diagnosis}</td>
          <td>{test?.symptoms}</td> */}
          <td>{test?.price?.discounted_price}</td>
          <td>
            <Button type="link" danger onClick={() => handleDeleteTest(index)}>
              Delete
            </Button>
          </td>
        </tr>
      ));
  };

  const handleSelectSymptom = (symptom: any) => {
    console.log("symptom : ", symptom);

    if (symptom) {
      setIssues([...issues, symptom.trim()]);
      setIssue("");
    }
  };

  const handleDeleteIssue = (index: any) => {
    setIssues(issues.filter((_: any, i: any) => i !== index));
  };

  const handleFrequencyChange = (timeOfDay: string, checked: boolean) => {
    setFrequency({ ...frequency, [timeOfDay]: checked ? 1 : 0 });
  };

  const handlePrescriptionBooking = async () => {
    console.log("selectedMedicines : ", selectedMedicines);
    if (!note || note === "") {
      return;
    }
    // if (!nextVisit) {
    //   return;
    // }

    const prescriptionBody = {
      booking_id: prescriptionId,
      patient_id: parseInt(currentBooking?.user?.id) || 0,
      symptoms: issues?.join(", "), // Convert issues array to a comma-separated string
      note,
      ...(nextVisit
        ? { next_visit: new Date(nextVisit)?.toISOString().split("T")[0] }
        : {}),
      prescriptions_medicines: selectedMedicines?.map((med) => ({
        medicine_id:
          med?.service_code || med?.linkable_id || med?.medicine_id || null,
        count: med?.noOfDays, // Assuming count refers to the number of days
        dosage: med?.dose,
        no_of_days: med?.noOfDays,
        frequency: `${med?.frequency.morning}-${med?.frequency.afternoon}-${med?.frequency.night}`,
        intake: med?.intake,
        start_date: new Date(med?.startDate).toISOString().split("T")[0], // Format date to YYYY-MM-DD
        ...(med?.service_name && med?.category && med?.actual_cost
          ? {
              service_name: med?.service_name,
              category: med?.category,
              actual_cost: med?.actual_cost,
            }
          : {}),
      })),
      prescriptions_tests: selectedTests?.map((test) => ({
        test_id: test?.service_code || test?.test_id,
        type: test?.type,
      })),
    };
    console.log("prescriptionBody : ", prescriptionBody);
    console.log("currentBooking : ", currentBooking);

    const res = (await dispatch(
      addDocPrescriptionAPI(prescriptionBody)
    )) as any;
    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return false;
    } else {
      toast.success("Prescription Added Succesfully");
      handleBkApprove(prescriptionId);
      const prescDataRes = (await dispatch(
        getPrescriptionDataAPI(prescriptionId)
      )) as any;
      const data = prescDataRes?.payload;
      if (data) {
        generateAndUploadPDF(data);
      }
      // handleStorePdfToDb();
      return true;
    }
  };

  const handleBkApprove = async (bkId: any) => {
    const updateObj = {
      id: bkId,
      bookingObj: {
        status: "completed",
      },
    };
    const res = await dispatch(updateBookingStatusAPI(updateObj));
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson?.error) {
      toast.error(resJson?.error?.message);
      return;
    } else {
      toast.success("Status updated successfully");
      dispatch(getAllBookingListAPI({ count: 100 }));
    }
  };

  const handlePrescriptionPreview = async () => {
    console.log("id : ", prescriptionId);
    if (!prescriptionId) {
      toast.error("Prescription ID Not found");
      return;
    }
    const resPresc = (await handlePrescriptionBooking()) as any;
    if (!resPresc) {
      return;
    }

    const res = (await dispatch(getPrescriptionDataAPI(prescriptionId))) as any;
    const data = res?.payload;
    setPreviewData(data);
    setShowPreviewModal(true);
  };

  const getHTMLContent = async (data: any) => {
    console.log("data : ", data);
    const html = await htmlTemplate(data);
    return html;
  };

  const handleMedSearch = async (searchQuery: any) => {
    if (!searchQuery) {
      return;
    }
    const searchObj = {
      searchText: searchQuery,
      type: medicineType,
    };
    dispatch(getUniversalSearchData(searchObj));
  };

  useEffect(() => {
    console.log("universalSearchResults : ", universalSearchResults);
  }, [universalSearchResults]);

  const handleAddNewMedicine = () => {
    setNewMedAdded(true);
    console.log("newMedicine : ", newMedicine);
    setMedicine(newMedicine.name);
    setIsModalVisible(false);
  };
  useEffect(() => {
    console.log("medicine : ", medicine);
  }, [medicine]);

  const handleIntakeChange = (e: any) => {
    const value = e.target.value;
    setIntake(value); // Set intake based on the selected value ("after food" or "before food")
  };

  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>("en");

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "gu", label: "Gujarati" },
    { value: "hi", label: "Hindi" },
    { value: "kn", label: "Kannada" },
    { value: "mr", label: "Marathi" },
    { value: "or", label: "Odia" },
    { value: "pa", label: "Punjabi" },
    { value: "ta", label: "Tamil" },
    { value: "te", label: "Telugu" },
    { value: "ml", label: "Malayalam" },
    { value: "bn", label: "Bangla" },
  ];

  const handleSubmitClick = () => {
    setShowLanguageModal(true);
  };

  const handleLanguageSelect = (value: any) => {
    setSelectedLanguage(value);
  };

  const handleModalConfirm = () => {
    setShowLanguageModal(false);
    console.log("selectedLanguage : ", selectedLanguage);
    handlePrescriptionBooking();
  };

  const generateAndUploadPDF = async (translatedData: any) => {
    try {
      // Generate PDF blob
      const pdfDoc = <PrescriptionPdfDocument data={translatedData} />;
      const blob = await pdf(pdfDoc).toBlob();

      // Get presigned URL from S3
      const presignBody = {
        id: `${user?.id}`,
        ext: ".pdf",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));

      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicPdfUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;

      // Upload PDF to S3
      await axios.put(presignedUrl, blob, {
        headers: {
          "Content-Type": "application/pdf",
        },
      });

      console.log("Uploaded PDF URL:", publicPdfUrl);
      if (publicPdfUrl && addPdfUrlToDb) {
        addPdfUrlToDb(publicPdfUrl);
      }
    } catch (error) {
      console.error("Error generating and uploading PDF:", error);
      toast.error("Failed to generate and upload PDF");
    }
  };

  const addPdfUrlToDb = async (pdfUrl: any) => {
    const body = {
      bookingId: prescriptionId,
      url: pdfUrl,
      extension: "pdf",
    };
    const res = await dispatch(uploadPdfPrescription(body));
    console.log("res : ", res);
  };

  const uploadImageToS3 = async (image: any) => {
    try {
      const presignBody = {
        id: `${Date.now()}`,
        ext: ".png",
      };
      const presignedRes = (await dispatch(
        getS3PresignedUrl(presignBody)
      )) as any;
      const presignedUrlResp = presignedRes.payload?.signedUrL;

      await axios.put(presignedUrlResp.signedUrL, image, {
        headers: {
          "Content-Type": "image/png",
        },
      });

      return presignedUrlResp.publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleImgFileChange = async (event: any) => {
    const files = event.target.files;
    if (files.length > 0) {
      try {
        const uploadPromises = Array.from(files).map((file) =>
          uploadImageToS3(file)
        );
        const uploadedUrls = await Promise.all(uploadPromises);
        setImageUrls((prevUrls: any) => [...prevUrls, ...uploadedUrls]);
      } catch (error) {
        toast.error("Error uploading images. Please try again.");
      }
    }
  };

  return (
    <PrescriptionStyled>
      <div className="presc-wrapper">
        <div className="prescription-container">
          <div className="profile-section">
            {currentBooking?.user?.image ? (
              <img
                src={currentBooking?.user?.image}
                alt=""
                className="userImg"
              />
            ) : (
              <FaRegUserCircle size={32} />
            )}
            <div className="user-info">
              <h2 className="user-name">
                {currentBooking?.user?.first_name}
                {currentBooking?.user?.last_name}
              </h2>
              <p className="user-details">
                Age: <span>{currentBooking?.user?.age}</span> Gender:
                <span>{currentBooking?.user?.gender}</span> City:
                <span>{currentBooking?.address?.city}</span>
              </p>
              <p className="purpose">
                Purpose: <span>{transformText(currentBooking?.type)}</span>
              </p>
            </div>
          </div>
          <div className="appointment-info">
            <p className="doctor-name">
              DR. {user?.first_name} {user?.last_name}
            </p>
            <p className="date">
              Date: <span>{new Date().toLocaleDateString("en-GB")}</span>
            </p>
            <p className="time">
              Time:
              <span>
                {new Date().toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            </p>
            <p className="mode">
              Mode: <span>🏠</span>
            </p>
          </div>
        </div>

        {/* Search and Issue Tags */}
        <div className="issue-container mt-4">
          <p className="note-label">Search Symptoms </p>
          <SearchBar
            handleSelectSymptom={handleSelectSymptom}
            issues={issues}
          />
          {issues?.length < 1 && (
            <p className="error-text">Please Add Your Symptoms</p>
          )}
          <div className="issues-list">
            {issues.map((item: any, index: any) => (
              <div className="issue-tag" key={index}>
                {item}
                <FaTimes
                  className="icon delete-icon"
                  onClick={() => handleDeleteIssue(index)}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="medicine-container">
          {/* <p className="note-label">Search Medicines</p> */}
          {/* {!medicine && <p className="error-text">Please Add Medicines</p>} */}
          <div className="d-flex justify-content-between medType">
            <p className="note-label">Choose Medicine Type</p>
            <Radio.Group
              onChange={handleTypeChange}
              value={medicineType} // Controlled value
              style={{ display: "flex", gap: "10px" }}
            >
              <Radio value="branded">Branded</Radio>
              <Radio value="generic">Generic</Radio>
            </Radio.Group>
          </div>
          <div className="search-bar">
            <FaSearch className="icon search-icon" />
            {!newMedAdded && (
              <Select
                showSearch
                placeholder="Search for medicines"
                onChange={(value) => {
                  console.log("Selected Medicine ID:", value);
                  setMedicine(value);
                }}
                onSearch={handleMedSearch}
                filterOption={false}
                style={{ width: "100%" }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "#333",
                        padding: "10px",
                        borderTop: "1px solid #f0f0f0",
                        cursor: "pointer",
                      }}
                      onClick={() => setIsModalVisible(true)} // Ensure this sets modal visible
                    >
                      <span>+ Add Medicine</span>
                    </div>
                  </>
                )}
              >
                {universalSearchResults.map((item: any) => (
                  <Option key={item.id} value={item.linkable_id}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                        }}
                      />
                      <span>{item.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            )}

            {/* Modal for adding new medicine */}
            <Modal show={isModalVisible} onHide={handleCancel}>
              <Modal.Header closeButton>
                <Modal.Title>Add New Medicine</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Medicine Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter medicine name"
                      value={newMedicine.name}
                      onChange={(e) =>
                        setNewMedicine({
                          ...newMedicine,
                          name: e.target.value,
                          price: "1",
                        })
                      }
                    />
                  </Form.Group>
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Medicine Price</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="number"
                        placeholder="Enter price"
                        value={newMedicine.price}
                        onChange={(e) =>
                          setNewMedicine({
                            ...newMedicine,
                            price: e.target.value,
                          })
                        }
                      />
                    </InputGroup>
                  </Form.Group> */}
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleAddNewMedicine}>Add Medicine</Button>
              </Modal.Footer>
            </Modal>

            {/* <FaMicrophone className="icon mic-icon" /> */}
          </div>
          {medicine && (
            <>
              <div className="prescription-inputs">
                <Row gutter={16} className="gap-4">
                  <div className="h-fit noOfDays">
                    <p className="text-label">No Of Days</p>
                    <Select
                      placeholder="No of days"
                      value={noOfDays}
                      showSearch
                      onChange={(value) => setNoOfDays(value)}
                      style={{ width: "100%" }}
                      options={[
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 3, label: "3" },
                        { value: 4, label: "4" },
                        { value: 5, label: "5" },
                        { value: 6, label: "6" },
                        { value: 7, label: "7" },
                        { value: 8, label: "8" },
                        { value: 9, label: "9" },
                        { value: 10, label: "10" },
                        { value: 15, label: "15" },
                        { value: 20, label: "20" },
                        { value: 30, label: "30" },
                        { value: 45, label: "45" },
                        { value: 60, label: "60" },
                        { value: 90, label: "90" },
                      ]}
                    />
                  </div>

                  <div className="h-fit">
                    <p className="text-label">Frequency</p>
                    <div className="checkboxes">
                      <Checkbox
                        checked={!!frequency.morning}
                        onChange={(e) =>
                          handleFrequencyChange("morning", e.target.checked)
                        }
                      >
                        Morning
                      </Checkbox>
                      <Checkbox
                        checked={!!frequency.afternoon}
                        onChange={(e) =>
                          handleFrequencyChange("afternoon", e.target.checked)
                        }
                      >
                        Afternoon
                      </Checkbox>
                      <Checkbox
                        checked={!!frequency.night}
                        onChange={(e) =>
                          handleFrequencyChange("night", e.target.checked)
                        }
                      >
                        Night
                      </Checkbox>
                    </div>
                  </div>
                </Row>
                <Row gutter={16} className="">
                  <Col className="h-fit">
                    <Checkbox
                      checked={startImmediately} // Reflect the state of `startImmediately`
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setStartImmediately(isChecked);
                        setStartDate(new Date()); // Set start date to today if checked
                      }}
                    >
                      Start Immediately
                    </Checkbox>
                  </Col>
                  <Col className="h-fit">
                    <p className="text-label">Intake After Food?</p>
                    <Radio.Group onChange={handleIntakeChange} value={intake}>
                      <Radio value="after food">Yes</Radio>
                      <Radio value="before food">No</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row gutter={16} className="align-items-center">
                  {!startImmediately && (
                    <Col className="h-fit startDate">
                      <p className="text-label">Start Date</p>
                      <DatePicker
                        selected={startDate}
                        onChange={(date: any) => setStartDate(date)}
                        minDate={new Date()} // Disable dates before today
                        placeholderText="Start Date"
                        dateFormat="dd-MM-yyyy"
                        className="custom-datepicker"
                      />
                    </Col>
                  )}
                </Row>
              </div>
              <div className="note-section">
                {medicine && !dose && (
                  <p className="error-text">Please Add Dosage</p>
                )}
                {medicine && !intake && (
                  <p className="error-text">Please Select Intake</p>
                )}
                {medicine && !startDate && (
                  <p className="error-text">Please Select Start Date</p>
                )}
              </div>
              <Button
                type="primary"
                className="add-button"
                style={{ marginTop: "8px" }}
                onClick={handleAddMedicine}
              >
                Add
              </Button>
            </>
          )}
        </div>

        <div className="tests-container">
          <div className="wrapperTest">
            <p className="note-label">Pick a Test / Scan</p>
            <div className="align-items-center">
              <div>
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Pick Tests / Scans"
                  value={selectedTestNames}
                  onChange={(values) => {
                    setSelectedTestNames(values);
                  }}
                  style={{ width: "fit-content", minWidth: "200px" }}
                >
                  {alltests?.map((testItem: any) => (
                    <Option
                      key={testItem.service_code}
                      value={testItem.service_name}
                    >
                      {testItem.service_name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          {selectedTestNames.length > 0 && (
            <Button
              type="primary"
              className="add-button"
              style={{ marginTop: "8px" }}
              onClick={handleAddTests}
            >
              Add
            </Button>
          )}
        </div>

        <p className="note-label">Advice Notes</p>
        <Input.TextArea
          placeholder="Add any additional notes here..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={3}
        />

        {note === "" && <p className="error-text">Please Add Notes</p>}
        <Col span={6}>
          <p className="note-label mt-3">Next Visit</p>
          <DatePicker
            selected={nextVisit ? new Date(nextVisit) : null} // Convert ISO string to Date
            onChange={(date: Date | null) =>
              setNextVisit(date?.toISOString() || null)
            } // Convert Date back to ISO string
            minDate={new Date()} // Disable dates before today
            placeholderText="Next Visit"
            dateFormat="dd-MM-yyyy"
            className="custom-datepicker"
          />
        </Col>
        <Row className="mb-3">
          <div className="d-flex flex-col imagesPreview">
            <Form.Group controlId="media">
              <Form.Label className="note-label mt-3">Upload Images</Form.Label>
              <Form.Control
                type="file"
                accept="image/png"
                onChange={handleImgFileChange}
                multiple
              />
            </Form.Group>
            {/* Display all uploaded images */}
            <div className="d-flex flex-col flex-wrap gap-2 mt-3">
              {imageUrls.map((url: any, index: any) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={url}
                    alt={`Uploaded ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <BSTButton
                    variant="danger"
                    size="sm"
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      padding: "0px 6px",
                      minWidth: "20px",
                      minHeight: "20px",
                      borderRadius: "50%",
                      zIndex: 2,
                    }}
                    onClick={() =>
                      setImageUrls((prevUrls: any) =>
                        prevUrls.filter((_: any, i: any) => i !== index)
                      )
                    }
                  >
                    ×
                  </BSTButton>
                </div>
              ))}
            </div>
          </div>
        </Row>
        <Row className="mb-3">
          {/* {isDietician && (
            <Button onClick={() => setShowDietModal(true)} type="primary">
              Add Diet Data
            </Button>
          )} */}
        </Row>

        {/* {!nextVisit && (
          <p className="error-text">Please Select the next visit date</p>
        )} */}

        <>
          <div className="button-container">
            <button
              className="preview-button"
              onClick={handlePrescriptionPreview}
            >
              Preview
            </button>
            <button className="book-button" onClick={handleSubmitClick}>
              Submit
            </button>
          </div>

          <Modal
            show={showLanguageModal}
            onHide={() => setShowLanguageModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Select Language</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select a language"
                optionFilterProp="children"
                onChange={handleLanguageSelect}
                defaultValue="en"
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                options={languageOptions}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => setShowLanguageModal(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={handleModalConfirm}>
                OK
              </button>
            </Modal.Footer>
          </Modal>
        </>
      </div>

      <div className="accordion-container">
        <Collapse defaultActiveKey={["1", "2", "3"]}>
          {selectedMedicines.length > 0 && (
            <Panel header={`Pharmacy (${selectedMedicines.length})`} key="1">
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    {/* <th>Dose</th> */}
                    <th>Days</th>
                    <th>Frequency</th>
                    <th>Intake</th>
                    <th>Price</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{renderMedicineRows()}</tbody>
              </table>
            </Panel>
          )}
          {selectedTests.filter((test) => test?.type === "diagnostic").length >
            0 && (
            <Panel
              header={`Lab Test (${
                selectedTests.filter((test) => test?.type === "diagnostic")
                  .length
              })`}
              key="2"
            >
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    {/* <th>Diagnosis</th>
                    <th>Symptoms</th> */}
                    <th>Price</th>
                    {/* <th>Next Visit</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{renderTestRows("diagnostic")}</tbody>
              </table>
            </Panel>
          )}
          {selectedTests.filter((test) => test?.type === "ctmri").length >
            0 && (
            <Panel
              header={`Radiology (${
                selectedTests.filter((test) => test?.type === "ctmri").length
              })`}
              key="3"
            >
              <table>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Name</th>
                    {/* <th>Diagnosis</th>
                    <th>Symptoms</th> */}
                    <th>Price</th>
                    {/* <th>Next Visit</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{renderTestRows("ctmri")}</tbody>
              </table>
            </Panel>
          )}
        </Collapse>
        {note && (
          <div className="mt-4">
            <p>Prescription Notes</p>
            <Input.TextArea
              value={note}
              readOnly
              rows={3}
              style={{ width: "100%", border: "none" }}
            />
          </div>
        )}
        {issues && (
          <div className="mt-4">
            <p>Prescription Issues</p>
            <Input.TextArea
              value={issues}
              readOnly
              rows={3}
              style={{ width: "100%", border: "none" }}
            />
          </div>
        )}
      </div>

      {medToBeEdited && (
        <EditMedicineModal
          show={editMedModal}
          handleClose={() => setEditMedModal(false)}
          medicineToEdit={medToBeEdited}
          onSave={handleSaveEditedMedicine}
        />
      )}
      <PrescriptionPreview
        data={previewData}
        showPreviewModal={showPreviewModal}
        setShowPreviewModal={setShowPreviewModal}
      />

      <NutritionDiets
        showModal={showDietModal}
        setShowModal={setShowDietModal}
      />
    </PrescriptionStyled>
  );
};

export default Prescription;
