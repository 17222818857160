import React, { useEffect, useState } from "react";
// import { Form, Input, Button, Image } from "antd";
// import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "antd/dist/antd.css";
import { CreateHyperSiteStyled } from "./CreateHyperSite.styled";
import { useDispatch, useSelector } from "react-redux";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";
import { createBlogAPI } from "../../redux/slices/doctor/doctorService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import BannerImages from "./BannerImages";
import ClinicDetails from "./ClinicDetails";
import Gallery from "./Gallery";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Testimonial from "./Testimonial";
import Specialization from "./Specialization"
import { Select } from "antd";
import { getAllHyperSites } from "../../redux/slices/hypersite/hypersiteService";

const { Option } = Select;

const CreateHyperSite = (props: any) => {
  const { sectionName } = props?.match?.params;
  const pathName = props?.location?.pathname;
  const dispatch = useDispatch();
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  console.log("props", props);
  const [content, setContent] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [doctorId, setDoctorId] = useState<any>();
  const history = useHistory();
  const [hypersites, setHypersites] = useState<any[]>([]);
  const [selectedHypersite, setSelectedHypersite] = useState<any | null>(null);
  const options = hypersites.map((site) => ({
    value: site?.id,
    label: site?.site_name,
  }));
  const { sites } = useSelector((ReduxState: any) => ReduxState.hypersite);

  useEffect(() => {
    dispatch(getAllHyperSites());
  }, [dispatch]);

  useEffect(() => {
    let allSites = sites?.filter(
      (site: any) => site.user_id === parseInt(user?.id)
    );
    console.log("getAllHyperSites : ", allSites);
    setHypersites(allSites || []);
  }, [sites, user]);

  const handleSelectChange = (selectedOption: any) => {
    const selectedSite = hypersites.find((site) => site.id === selectedOption);
    setSelectedHypersite(selectedSite); // Update the selected hypersite
  };

  const onFinish = async (values: any) => {
    if (!doctorId) {
      toast.error("Doctor Id Not found, Please register as a doctor first");
      history.push("/register");
      return;
    }
    const res = await dispatch(
      createBlogAPI({
        doctor_id: parseInt(doctorId),
        image: imageUrl,
        title: values.title,
        summary: content,
      })
    );
    const resJson = JSON.parse(JSON.stringify(res));
    if (resJson.payload.success) {
      toast.success("Blog Added");
    }
  };

  useEffect(() => {
    if (user?.roles?.length > 0) {
      if (user.roles[0].linkable_id) {
        setDoctorId(user.roles[0].linkable_id);
      }
    }
  }, []);

  const uploadImageToS3 = async (image: any) => {
    try {
      const presignBody = {
        id: `${user.id}`,
        ext: ".png",
      };
      const presignedRes = await dispatch(getS3PresignedUrl(presignBody));
      const presignedUrlResp = JSON.parse(JSON.stringify(presignedRes));
      console.log("presignedUrlResp", presignedUrlResp);
      const presignedUrl = presignedUrlResp?.payload?.signedUrL?.signedUrL;
      const publicUrl = presignedUrlResp?.payload?.signedUrL?.publicUrl;
      await axios.put(presignedUrl, image, {
        headers: {
          "Content-Type": "image/png",
        },
      });
      console.log("publicUrl", publicUrl);
      return publicUrl;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      throw error;
    }
  };

  const handleImageChange = async (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;
    setImageFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImageUrl(reader.result as string);

        try {
          const imgUrl = await uploadImageToS3(file);
          setImageUrl(imgUrl);
        } catch (error) {
          console.error("Error uploading image. Please try again.", error);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSectionClick = (item: any) => {
    history.push(item?.link);
  };

  const leftMenu = [
    {
      title: "Clinic Details",
      link: "/CreateHyperSite/ClinicDetails",
    },
    {
      title: "Banner Images",
      link: "/CreateHyperSite/BannerImages",
    },
    {
      title: "Services",
      link: "/CreateHyperSite/Services",
    },
    {
      title: "Gallery",
      link: "/CreateHyperSite/Gallery",
    },
    {
      title: "Specialization",
      link: "/CreateHyperSite/Specialization",
    },
    {
      title: "Testimonial",
      link: "/CreateHyperSite/Testimonial",
    },
    {
      title: "About Us",
      link: "/CreateHyperSite/AboutUs",
    },
  ];

  const renderContent = () => {
    switch (sectionName) {
      default:
        return <ClinicDetails selectedHypersite={selectedHypersite} />;
      case "BannerImages":
        return <BannerImages selectedHypersite={selectedHypersite} />;
      case "Services":
        return <Services selectedHypersite={selectedHypersite} />;
      case "Gallery":
        return <Gallery selectedHypersite={selectedHypersite} />;
      case "Specialization":
        return <Specialization selectedHypersite={selectedHypersite} />;
      case "Testimonial":
        return <Testimonial selectedHypersite={selectedHypersite} />;
      case "AboutUs":
        return <AboutUs selectedHypersite={selectedHypersite} />;
    }
  };

  useEffect(() => {
    console.log("selectedHypersite : ", selectedHypersite);
  }, [selectedHypersite]);

  return (
    <CreateHyperSiteStyled>
      <div className="createHyperSite-sec">
        <div className="left-side-mesu-sec">
          <div>
            {leftMenu?.map((itemm: any, idx: any) => {
              return (
                <div
                  className={
                    pathName === itemm?.link ||
                    (sectionName === undefined && idx === 0)
                      ? "per-row-sec-list active"
                      : "per-row-sec-list"
                  }
                  onClick={() => {
                    handleSectionClick(itemm);
                  }}
                >
                  <p>{itemm?.title}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="right-side-mesu-sec">
          <div className="d-flex w-100 justify-content-end">
            <Select
              showSearch
              style={{ width: 200 }}
              onChange={handleSelectChange}
              placeholder="Select an Hypersite"
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="add-child-content-sec">{renderContent()}</div>
        </div>
      </div>
    </CreateHyperSiteStyled>
  );
};

export default CreateHyperSite;
