import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CreateHyperSiteStyled } from "./CreateHyperSite.styled";
import { Button, Image, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getS3PresignedUrl } from "../../redux/slices/Profile/ProfileService";
import axios from "axios";
import {
  getAllHyperSites,
  updateHyperSite,
} from "../../redux/slices/hypersite/hypersiteService";

const CreateArticle = ({ selectedHypersite }: any) => {
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const { user } = useSelector((ReduxState: any) => ReduxState.auth);
  const dispatch = useDispatch();

  const handleDescriptionChange = (value: string) => {
    setDescription(value);
  };

  useEffect(() => {
    if (selectedHypersite) {
      setDescription(selectedHypersite.about_us_text || "");
      setImageUrl(selectedHypersite.about_us_image || "");
    }
  }, [selectedHypersite]);

  const handleImgFileChange = async (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      try {
        const uploadedUrl = await uploadImageToS3(file); // Upload to S3 and get the public URL
        setImageUrl(uploadedUrl); // Update local state
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Failed to upload image. Please try again.");
      }
    }
  };

  const uploadImageToS3 = async (image: File) => {
    try {
      const presignBody = {
        id: `${user?.id}`,
        ext: ".png",
      };
      const presignedRes = (await dispatch(
        getS3PresignedUrl(presignBody)
      )) as any;
      const presignedUrlResp = presignedRes?.payload?.signedUrL;

      if (!presignedUrlResp) {
        throw new Error("Failed to get presigned URL");
      }

      const { signedUrL: uploadUrl, publicUrl } = presignedUrlResp;

      await axios.put(uploadUrl, image, {
        headers: {
          "Content-Type": "image/png", // Set correct content type
        },
      });

      return publicUrl; // Return the public URL of the uploaded image
    } catch (error) {
      console.error("Error uploading to S3:", error);
      throw error;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log({ imageUrl, description });

    const body = {
      id: selectedHypersite?.id,
      payload: {
        user_id: selectedHypersite?.user_id,
        site_name: selectedHypersite?.site_name,
        about_us_text: description,
        about_us_image: imageUrl,
      },
    };
    console.log("body : ", body);

    const res = (await dispatch(updateHyperSite(body))) as any;
    console.log("res : ", res);

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    } else {
      toast.success("HyperSite Updated Successfully");
      dispatch(getAllHyperSites());
    }
  };

  return (
    <CreateHyperSiteStyled>
      {selectedHypersite ? (
        <div className="create-article-container">
          <h2 className="create-article-title">Create About Us</h2>
          <form onSubmit={handleSubmit}>
            {/* Image Upload */}
            <div className="form-group">
              <label>Image</label>
              <div className="upload-box">
                {imageUrl && <Image src={imageUrl} className="image-preview" />}
                <div className="upload-button">
                  <Upload
                    accept="image/png"
                    showUploadList={false}
                    onChange={handleImgFileChange}
                  >
                    <Button icon={<UploadOutlined />}>Change Image</Button>
                  </Upload>
                </div>
              </div>
            </div>

            {/* Description */}
            <div className="form-group">
              <label>Description</label>
              <ReactQuill
                value={description}
                onChange={handleDescriptionChange}
                placeholder="Write your About Us here..."
                className="quill-editor"
              />
            </div>

            {/* Submit Button */}
            <button type="submit" className="create-article-button">
              CREATE
            </button>
          </form>
        </div>
      ) : (
        <div className="create-article-container">
          <p>Please Choose a Hypersite first</p>
        </div>
      )}
    </CreateHyperSiteStyled>
  );
};

export default CreateArticle;
