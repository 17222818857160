import React, { useEffect, useState } from "react";
import { CreateHyperSiteStyled } from "./CreateHyperSite.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFrontendServices,
  getAllHyperSites,
  updateHyperSite,
} from "../../redux/slices/hypersite/hypersiteService";
import { toast } from "react-toastify";

interface Brand {
  id: number;
  name: string;
  logo: string;
}

const BrandsList = ({ selectedHypersite }: any) => {
  const [selectedBrands, setSelectedBrands] = useState<Brand[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [allBrands, setAllBrands] = useState([]);
  const { services } = useSelector((ReduxState: any) => ReduxState.hypersite);

  useEffect(() => {
    if (services?.services?.length > 0) {
      console.log("services : ", services?.services);

      // Map services data into the structure of allBrands
      const updatedBrands = services.services.map(
        (service: any, index: number) => ({
          id: index + 1,
          name: service.name, // Use 'name' from services
          logo: "", // Set logo to empty string (or modify if you have logo URLs)
        })
      );

      // Update the state
      setAllBrands(updatedBrands);
    }
  }, [services]);

  const dispatch = useDispatch();
  useEffect(() => {
    const getAllServices = async () => {
      const res = (await dispatch(getAllFrontendServices())) as any;
      console.log("services : ", res);
    };
    getAllServices();
  }, []);

  useEffect(() => {
    if (selectedHypersite && allBrands.length > 0) {
      const matchedItems = selectedHypersite?.services?.flatMap((item: any) =>
        allBrands.filter((brand: any) => brand.name === item)
      );
      console.log("matchedItems : ", matchedItems);
      console.log("selectedHypersite : ", selectedHypersite);

      if (matchedItems?.length > 0) {
        console.log("matchedItems : ", matchedItems);
        
        setSelectedBrands(matchedItems);
      } else {
        setSelectedBrands([]); // Reset if no matches are found
      }
    }
  }, [selectedHypersite, allBrands]);

  // Handle selecting individual brand
  const handleSelectBrand = (brand: Brand) => {
    setSelectedBrands((prevSelected) => {
      const isAlreadySelected = prevSelected.some((b) => b.id === brand.id);
      if (isAlreadySelected) {
        return prevSelected.filter((b) => b.id !== brand.id);
      } else {
        return [...prevSelected, brand];
      }
    });
  };

  // Handle removing selected brand
  const handleRemoveBrand = (brandId: number) => {
    setSelectedBrands((prevSelected) =>
      prevSelected.filter((b) => b.id !== brandId)
    );
  };

  // Handle Select All
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedBrands([]);
    } else {
      setSelectedBrands(allBrands);
    }
    setSelectAll(!selectAll);
  };

  const handleSubmit = async () => {
    const brandNames = selectedBrands.map((brand) => brand.name);
    console.log("selectedBrands Names: ", brandNames);
    const body = {
      id: selectedHypersite?.id,
      payload: {
        user_id: selectedHypersite?.user_id,
        site_name: selectedHypersite?.site_name,
        services: brandNames,
      },
    };
    console.log("body : ", body);

    const res = (await dispatch(updateHyperSite(body))) as any;
    console.log("res : ", res);

    if (res?.error) {
      toast.error(res?.error?.message || "Unknown Error Occured");
      return;
    } else {
      toast.success("HyperSite Updated Successfully");
      dispatch(getAllHyperSites());
    }
  };

  return (
    <CreateHyperSiteStyled>
      <div className="brands-container">
        {selectedHypersite ? (
          <>
            <h2>Services</h2>
            <div className="brands-list-container">
              {/* All Brands Section */}
              <div className="all-brands">
                <h3>All Services</h3>
                <label className="brand-item">
                  Select All
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </label>
                <ul>
                  {allBrands.map((brand: any) => (
                    <li key={brand?.id} className="brand-item">
                      {/* <img src={brand.logo} alt={brand.name} className="brand-logo" /> */}
                      <span>{brand?.name}</span>
                      <input
                        type="checkbox"
                        checked={selectedBrands.some((b) => b.id === brand.id)}
                        onChange={() => handleSelectBrand(brand)}
                      />
                    </li>
                  ))}
                </ul>
              </div>

              {/* Selected Brands Section */}
              <div className="selected-brands">
                <h3>Selected Services</h3>
                <ul>
                  {selectedBrands?.length > 0 &&
                    selectedBrands.map((brand) => (
                      <li key={brand.id} className="selected-brand-item">
                        {/* <img src={brand.logo} alt={brand.name} className="brand-logo" /> */}
                        <span>{brand.name}</span>
                        <button
                          className="right_side"
                          onClick={() => handleRemoveBrand(brand.id)}
                        >
                          🗑
                        </button>
                      </li>
                    ))}
                  {selectedBrands?.length === 0 && (
                    <div className="no_selected_brands">
                      <div className="inner">
                        <p className="top">No Services Selected</p>
                        <p className="bottom">
                          Please select Services from the left side list
                        </p>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </div>

            {/* Update Button */}
            <button className="update-button" onClick={handleSubmit}>
              UPDATE
            </button>
          </>
        ) : (
          <p>Please Select a HyperSite First</p>
        )}
      </div>
    </CreateHyperSiteStyled>
  );
};

export default BrandsList;
