import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get } from "../../../library/Requests/helpers";

import {
  constructAllAppointmentsQuery,
  constructAllBookingQuery,
  constructBookingQuery,
  getBookingIdDetails,
  getMyPackageDetailsQuery,
} from "../../../Scenes/graphqlConfig";

export const getDashboardInfo = createAsyncThunk(
  "auth/getDashboardInfo",
  async () => await get(`${SERVER_IP}/api/v1/dashboard/doctor`)
);

// export const getMyBookingsAPI = createAsyncThunk(
//   "auth/getMyBookingsAPI",
//   async (count: any) =>
//     await post(
//       `${SERVER_IP}/graphql`,
//       JSON.stringify(constructBookingQuery(count))
//     )
// );

export const getAllBookingListAPI = createAsyncThunk(
  "auth/getAllBookingListAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(constructAllBookingQuery(body.count, body.status))
    )
);

export const getAllAppointmentsListAPI = createAsyncThunk(
  "auth/getAllAppointmentsListAPI",
  async (body: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(constructAllAppointmentsQuery(body))
    )
);

export const getBookingDetailsAPI = createAsyncThunk(
  "auth/getBookingDetailsAPI",
  async (bookingId: any) =>
    await post(
      `${SERVER_IP}/graphql`,
      JSON.stringify(getBookingIdDetails(bookingId))
    )
);

// export const signUpUser = createAsyncThunk(
//   "auth/signUpUser",
//   async (body: any) => await post(`${SERVER_IP}/auth/register`, body)
// );
// export const updateUserDetails = createAsyncThunk(
//   "auth/updateUserDetails",
//   async (body: any) => await put(`${SERVER_IP}/user/update`, body)
// );
// export const getAllSubscriptions = createAsyncThunk(
//   "auth/getAllSubscriptions",
//   async () => await get(`${SERVER_IP}/api/v1/wallet`)
// );

// export const getMyPackageDetailsAPI = createAsyncThunk(
//   "auth/getMyPackageDetailsAPI",
//   async () => await post(`${SERVER_IP}/graphql`, JSON.stringify(getMyPackageDetailsQuery()))
// );

export const startRecordingAPI = createAsyncThunk(
  "auth/startRecordingAPI",
  async (body: any) =>
    await post(`${SERVER_IP}/api/v1/zegocloud/startRecording`, body)
);

export const getBookingStatusAPI = createAsyncThunk(
  "auth/getBookingStatusAPI",
  async (type: any) =>
    await get(`${SERVER_IP}/api/v1/config/bookingstatuses?type=${type}`)
);

export const updateBookingStatusAPI = createAsyncThunk(
  "auth/updateBookingStatusAPI",
  async (body: any) =>
    await put(
      `${SERVER_IP}/api/v1/doctor/bookingStatus/${body?.id}`,
      body?.bookingObj
    )
);
